import {
  searchReportUserTransactionsLogs
} from "../../../serviceBroker/transationReportApiServiceBroker";
import React, { FC } from "react";
import {
  PageEnum,
  ReportUserAcTransactionLogsParamsModel,
} from "../../../models";
import { ReportPage } from "../../../components";
import { getPageNameByPageId } from "../../../utils";
import { ReportUserAcTransactionsLogsSearchPanel } from "../../../components/reports/transactions/reportUserAcTransactionsLogsSearchPanel";

export const ReportUserAcTransactionsLogs: FC = () => {
  const initialState: ReportUserAcTransactionLogsParamsModel = {
    lang: 1,
    fromDate: new Date().toISOString(),
    toDate: new Date().toISOString(),
    fromDateFormatted: new Date(),
        toDateFormatted: new Date()
  };
  return (
    <ReportPage
      InitialState={initialState}
      //SearchApi={searchReportUserAcTransactionsLogs}
      SearchApi={searchReportUserTransactionsLogs}
      searchHeader={getPageNameByPageId(PageEnum.frmReportACTransactionLog)}
      SearchPanel={ReportUserAcTransactionsLogsSearchPanel}
      formId={PageEnum.frmReportACTransactionLog}
    />
  );
};
