import React, { FC, useEffect } from "react";
import { useStoreTransferState } from "./helper/state/storeTransferState";
import {
  ConfirmModelDialogBox,
  DeleteTransaction,
  ErrorValidationBox,
  GenericModelPopupPdfReportViewer,
  LoadingBox,
  StoreTransferRegisterTransaction,
  StoreTransferRegisterTransactionList,
  ToastBox,
} from "../..";
import {
  ActionTypeEnum,
  ComponentActionTypeEnum,
  DailyTransactionTypeEnum,
  PrintTypeEnum,
  RequestActionModel,
  RowStateEnum,
  ValidateTransactionModeEnum,
} from "../../../models";
import { Accordion } from "react-bootstrap";
import {
  generateGuid,
  getLabelName,
  scrollToDocumentTop,
} from "../../../utils";
import { useStoreTransferLookUp } from "../../../hooks";
import { deleteTransaction } from "../../../serviceBroker/transactionApiServiceBroker";
import {
  handleUpdateExistingTransactionValues,
  validateTransactionObject,
} from "../common/businessLogic/transactionBl";
import {
  handleStoreTransferTransactionTotalValues,
  transactionResponseModel,
} from "../common/businessLogic/storeTransferTransactionBl";
import { getStoreTransferTransactionDetail } from "../../../serviceBroker/moveItemsTransactionApiServiceBroker";

export const StoreTransferMainComponent: FC<{
  formId: number;
  transactionType: DailyTransactionTypeEnum;
}> = ({ formId, transactionType }) => {
  //#region state
  const {
    loading,
    setLoading,
    validationErrors,
    setValidationErrors,
    toastModel,
    setToastModel,
    data,
    setData,
    isRefresh,
    setIsRefresh,
    componentActionType,
    setComponentActionType,
    printTransactionId,
    setPrintTransactionId,
    showPrintoutPdfModel,
    setShowPrintoutPdfModel,
    showDeleteModel,
    setShowDeleteModel,
    transactionResponseObject,
    setTransactionResponseObject,
  } = useStoreTransferState();
  const { lookupList, setLookupList, settings } = useStoreTransferLookUp(
    setLoading,
    setValidationErrors
  );
  //#endregion
  //#region useEffect
  useEffect(() => {
    //checkUserMultipleBranchesEnabled();
    const fillData = async () => {};
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
      case ActionTypeEnum.DeleteSuccess:
      case ActionTypeEnum.SaveSuccess:
      case ActionTypeEnum.Success:
        scrollToDocumentTop();
        setToastModel({ ...toastModel, show: true });
        setIsRefresh(true);
        setComponentActionType(ComponentActionTypeEnum.Reset);
        setValidationErrors([]);
        break;
      case ActionTypeEnum.Update:
        setLoading(true);
        setValidationErrors([]);
        scrollToDocumentTop();
        const record = await getStoreTransferTransactionDetail(request.id!);
        if (record !== null) {
          record.rowState = RowStateEnum.Update;
          record.TransactionDetaillist.forEach((row) => {
            row.rowState = RowStateEnum.Update;
            row.rowKey = generateGuid();
          });
          if (record?.TransactionDetaillist.length !== 0) {
            record.TransactionDetaillist.forEach((row) => {
              row = handleUpdateExistingTransactionValues(row);
              return { ...row };
            });
          }
          const dataObject = handleStoreTransferTransactionTotalValues(record);
          setData({
            ...dataObject,
            TransactionDetaillist: [...dataObject.TransactionDetaillist],
          });
          setComponentActionType(ComponentActionTypeEnum.Edit);
        }
        setLoading(false);
        break;
      case ActionTypeEnum.Delete:
        setLoading(true);
        const deleteTransactionsResponse = await deleteTransaction(
          request.request
        );
        if (
          deleteTransactionsResponse.Errors !== null &&
          deleteTransactionsResponse.Errors !== undefined &&
          deleteTransactionsResponse.Errors.length !== 0
        ) {
          setValidationErrors(deleteTransactionsResponse.Errors);
          //scrollToTop(validationMessageRef);
          scrollToDocumentTop();
        } else {
          setShowDeleteModel(false);
          setIsRefresh(true);
        }
        setLoading(false);
        break;
      case ActionTypeEnum.DeleteStart:
        if (
          await validateTransactionObject(
            { ...transactionResponseModel, id: request.id || 0 },
            ValidateTransactionModeEnum.Delete,
            handleAction
          )
        ) {
          setTransactionResponseObject({
            ...transactionResponseModel,
            id: request.id || 0,
          });
          setShowDeleteModel(true);
        }
        break;
      case ActionTypeEnum.DeleteFailed:
        setValidationErrors(request.request);
        setShowDeleteModel(false);
        scrollToDocumentTop();
        break;
      case ActionTypeEnum.DeleteReset:
        setValidationErrors([]);
        setTransactionResponseObject(null);
        setShowDeleteModel(false);
        break;
      case ActionTypeEnum.ClearError:
        setValidationErrors([]);
        break;
      case ActionTypeEnum.Clear:
        scrollToDocumentTop();
        setComponentActionType(ComponentActionTypeEnum.Reset);
        setValidationErrors([]);
        break;
      case ActionTypeEnum.RaiseError:
        //scrollToTop(validationMessageRef);
        scrollToDocumentTop();
        setValidationErrors(request.request);
        break;
      case ActionTypeEnum.EnableLoader:
        setLoading(true);
        break;
      case ActionTypeEnum.DisableLoader:
        setLoading(false);
        break;
      case ActionTypeEnum.Refresh:
        setIsRefresh(request.request);
        break;
      case ActionTypeEnum.Print:
      case ActionTypeEnum.PrintPdf:
        setPrintTransactionId(request.id || 0);
        setShowPrintoutPdfModel(true);
        break;
      default:
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      {showPrintoutPdfModel && (
        <GenericModelPopupPdfReportViewer
          keys={[{ key: "transactionId", value: printTransactionId }]}
          type={PrintTypeEnum.MoveItemsBetweenStores}
          onCloseEvent={() => {
            setShowPrintoutPdfModel(false);
          }}
        />
      )}
      <ConfirmModelDialogBox
        isModelVisible={showDeleteModel}
        onCloseEvent={() => {
          setShowDeleteModel(false);
        }}
      >
        <DeleteTransaction
          onActionEvent={handleAction}
          //@ts-ignore
          request={transactionResponseObject}
        />
      </ConfirmModelDialogBox>
      <StoreTransferRegisterTransaction
        formId={formId}
        lookups={lookupList}
        setLookups={setLookupList}
        settings={settings}
        request={data}
        transactionType={transactionType}
        actionType={componentActionType}
        setActionType={setComponentActionType}
        onActionEvent={(o: RequestActionModel) => {
          handleAction(o).then(() => {});
        }}
      />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body className="TUEFO-header">
            <StoreTransferRegisterTransactionList
              onActionEvent={(o: RequestActionModel) => {
                handleAction(o).then(() => {});
              }}
              isRefresh={isRefresh}
              formId={formId}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
