import React from "react";
import { TableColumn } from "react-data-table-component";
import { GridColumnsResponseModel } from "../../../../../models";
import { getLabelName } from "../../../../../utils";

export const generateGridSettingsColumns = (
  isArabic: boolean,
  handleFieldChange: (
    fieldName: string,
    value: string | boolean,
    ID: number
  ) => void
) => {
  const columns: TableColumn<GridColumnsResponseModel>[] = [
    {
      name: getLabelName("Field"),
      selector: (row) => row.Field,
      sortable: true,
      wrap: false,
      width: "20%",
    },
    {
      name: getLabelName("ArabicName"),
      selector: (row) => row.ArabicName,
      cell: (row) => (
        <input
          name={"ArabicName"}
          placeholder={getLabelName("ArabicName")}
          type="text"
          value={row.ArabicName}
          onChange={(e: any) => {
            handleFieldChange("ArabicName", e.target.value, row.ID);
          }}
          className="form-control"
        />
      ),
      sortable: true,
      width: "20%",
      wrap: false,
    },
    {
      name: getLabelName("EnglishName"),
      cell: (row) => (
        <input
          name={"EnglishName"}
          type="text"
          value={row.EnglishName}
          onChange={(e: any) => {
            handleFieldChange("EnglishName", e.target.value, row.ID);
          }}
          placeholder={getLabelName("EnglishName")}
          className="form-control"
        />
      ),
      selector: (row) => row.EnglishName,
      sortable: true,
      width: "20%",
      wrap: false,
    },
    {
      name: getLabelName("show"),
      sortable: true,
      wrap: false,
      allowOverflow: true,
      grow: 0,
      selector: (row) => row.Visable,
      width: "10%",
      cell: (row) => (
        <input
          onChange={(e) => {
            handleFieldChange("Visable", e.target.checked, row.ID);
          }}
          name="Visable"
          type={"checkbox"}
          defaultChecked={row.Visable}
          checked={row.Visable}
          style={{ padding: 0 }}
        />
      ),
    },
    {
      name: getLabelName("DisplayIndex"),
      cell: (row) => (
        <input
          name={"DisplayIndex"}
          placeholder={getLabelName("DisplayIndex")}
          type="number"
          value={row.DisplayIndex || 1}
          onChange={(e: any) => {
            handleFieldChange("DisplayIndex", e.target.value, row.ID);
          }}
          step={1}
          pattern="\d+"
          min="0"
          className="form-control"
        />
      ),
      selector: (row) => row.DisplayIndex || 1,
      sortable: true,
      wrap: false,
      width: "15%",
    },
    {
      name: getLabelName("Column Width"),
      cell: (row) => (
        <input
          min={"0"}
          max={"100"}
          name="Width"
          type="number"
          onChange={(e: any) => {
            const value = e.target.value;
            if (value > 100 || value < 0) return;
            handleFieldChange("Width", value || null, row.ID);
          }}
          value={row.Width || ""}
          className="form-control"
          placeholder="%"
        />
      ),
      sortable: true,
      wrap: false,
      width: "15%",
    },
  ];
  return columns;
};
