import {PrivilegeActions} from "../../../privileges/privilegesChecker";
import { IconType } from 'react-icons';
import {ActionTypeEnum} from "../../../../../models";
import {  FaTrash, FaEdit, FaPrint, FaUsers, FaCog, FaCashRegister, FaRedo, FaPercentage, FaTimes } from 'react-icons/fa';
export const ColumnActions: {
    [key in ActionTypeEnum]?: {
        btnText: string;
        privilege?: PrivilegeActions;
        className?: string;
        variant?: string;
        icon?: IconType|null
    };
} = {
    [ActionTypeEnum.Update]: { btnText: "Update", privilege: "EnableUpdate", icon: FaEdit,  },
    [ActionTypeEnum.Modify]: { btnText: "Update", privilege: "EnableUpdate", icon: FaEdit,  },
    [ActionTypeEnum.ModifyStart]: { btnText: "Update", privilege: "EnableUpdate",icon: FaEdit , },


    [ActionTypeEnum.Delete]: {btnText: "Delete",privilege: "EnableDelete",variant: "danger", icon: FaTrash,},
    [ActionTypeEnum.DeleteStart]: {btnText: "Delete",privilege: "EnableDelete",variant: "danger",icon: FaTrash,},


    [ActionTypeEnum.PrintPdf]: { btnText: "Print" , icon: FaPrint, },
    [ActionTypeEnum.Print]: { btnText: "Print", icon: FaPrint,  },

    [ActionTypeEnum.GrantPermissions]: {btnText: "Users Permission",className: "btn-gradient-primary", icon: FaUsers,},
    [ActionTypeEnum.UserSetting]: {btnText: "User Settings",className: "btn-gradient-primary", icon: FaCog},
    [ActionTypeEnum.TransactionSetting]: {btnText: "transaction settings",className: "btn-gradient-primary", icon: FaCog,},
    [ActionTypeEnum.POSSetting]: {btnText: "Pos Settings",className: "btn-gradient-primary",icon: FaCashRegister,},

    [ActionTypeEnum.TransactionRefundIInitialization]: {btnText: "Return",className: "btn-gradient-primary",icon: FaRedo,},
    [ActionTypeEnum.ShowCommission]: {btnText: "Commission",className: "btn-gradient-primary",icon: FaPercentage},
    [ActionTypeEnum.Close]: {btnText: "Close",className: "btn-gradient-primary",icon: FaTimes,},
    [ActionTypeEnum.CloseDay]: {btnText: "Close",className: "btn-gradient-primary",icon: FaTimes,},
};

