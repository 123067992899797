import { FC, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  ErrorValidationBox,
  LoadingBox,
  ModelDialogBox,
  POSSettings,
  RegisterUser,
  ToastBox,
  TransactionSettings,
  UserPermissions,
  UserSettings,
  UsersList,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  HasFormIdModel,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  UserRegistrationResponseModel,
  UserSettingModel,
  UserTransactionSettingModel,
  ValidationErrorModel,
} from "../../models";
import {
  deleteUser,
  getUserInformation,
  getUserPrivileges,
  getUsers,
  getUserSetting,
} from "../../serviceBroker/userApiServiceBroker";
import { getLabelName, getUserId, SecureLocalStorageSet } from "../../utils";
import { SystemConfiguration } from "../../configuration";

export const UsersPage: FC<HasFormIdModel> = ({ formId }) => {
  //#region variables
  const userSettingInitialValues: UserSettingModel = {
    AddItemInNewLineInBill: false,
    AlertOfCustomerDidintPaySince: 0,
    AllowItemPriceSaleLessThanCost: false,
    AllowSaleLessThanCost: false,
    AlowRepeateFactoItemUnit: false,
    CheckMaxDebitOfCustomer: false,
    CreatedBy: 0,
    CreationDate: new Date(),
    DaysAlertBeforeExpire: 0,
    EnableManualInvoiceCode: true,
    ID: 0,
    Lang: 1,
    MaxDiscountPercentage: 0,
    ModificationDate: new Date(),
    ModifiedBy: 0,
    Name: "",
    PeriodAllowToEdit: 0,
    ShowScaleBarcode: false,
    PreventStoreOutOfItemLessZero: false,
    PrintBarcodeAfterPurches: false,
    PrintItemPrescriptionAfterSale: false,
    RequestDueDateOnDelayPayment: false,
    RigesterBillOnPayment: false,
    SelectionEmployeeIsRequierd: false,
    ShowAlert: false,
    ShowBarcode: false,
    ShowItemModifiers: false,
    ShowMinimumPriceItem: false,
    ShowProfitOfBill: false,
    User_ID: getUserId(),
    VerifyOnUpdate: false,
    rowState: 1,
    isArabic: false,
    isEnglish: false,
  };
  const deleteUserActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeleteUser();
      },
    },
    {
      text: getLabelName("no"),
      variant: "danger",
      onClick: () => {
        setUser(null);
        setShowDeleteUserModel(false);
      },
    },
  ];
  //#endregion
  //#region state
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserRegistrationResponseModel[]>([]);
  const [user, setUser] = useState<UserRegistrationResponseModel | null>(null);
  const [userId,setUserId]=useState(0);
  const [userSettingModel, setUserSettingModel] = useState<UserSettingModel>(
    userSettingInitialValues
  );

  const [showTransactionSettingModel, setShowTransactionSettingModel] =
    useState(false);
  const [showUserPOSSettingModel, setShowUserPOSSettingModel] = useState(false);
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  const [showDeleteUserModel, setShowDeleteUserModel] = useState(false);
  const [showUserPermissionModel, setShowUserPermissionModel] = useState(false);
  const [showUserSettingModel, setShowUserSettingModel] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllUsers();
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const getDefaultUserId: () => number = () => {return 0;};
  const handleUserAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.CloseModelPopup:
        setShowUserPOSSettingModel(false);
        setShowDeleteUserModel(false);
        setShowUserPermissionModel(false);
        setShowUserSettingModel(false);
        setShowTransactionSettingModel(false);
        setUserId(getDefaultUserId());
        break;
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        setUser(null);
        setValidationErrors([]);
        setUserId(getDefaultUserId());
        await getAllUsers();
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        switch (request.id) {
          case ActionTypeEnum.UserSetting:
            setShowUserSettingModel(false);
            break;
          case ActionTypeEnum.TransactionSetting:
            setShowTransactionSettingModel(false);
            break;
          case ActionTypeEnum.POSSetting:
            setShowUserPOSSettingModel(false);
            break;
        }
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        setValidationErrors([]);
        window.scrollTo(0, 0);
        const userObjectUpdate = await getUserInformation(request.id!);
        // @ts-ignore
        userObjectUpdate.rowState = Number(RowStateEnum.Update);
        setUser(userObjectUpdate);
        break;
      case ActionTypeEnum.Delete:
        setValidationErrors([]);
        const userObjectDelete = await getUserInformation(request.id!);
        setUser(userObjectDelete);
        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.TransactionSetting:
        setUserId(request.id!);
        setShowTransactionSettingModel(true);
        break;
      case ActionTypeEnum.POSSetting:
        setUserId(request.id!);
        setValidationErrors([]);
        setShowUserPOSSettingModel(true);
        break;
      case ActionTypeEnum.GrantPermissions:
        setValidationErrors([]);
        // const userObject = await getUserInformation(request.id!);
        // setUser(userObject);
          setUserId(request.id!);
        setShowUserPermissionModel(true);
        break;
      case ActionTypeEnum.UserSetting:
        setValidationErrors([]);
        const settings = await getUserSetting(request.id!);
        if (settings) {
          setUserSettingModel(settings);
        }
        setShowUserSettingModel(true);
        break;
      case ActionTypeEnum.Failed:
        setValidationErrors(request.request);
        switch (request.id) {
          case ActionTypeEnum.UserSetting:
            setLoading(false);
            break;
          case ActionTypeEnum.TransactionSetting:
            break;
          case ActionTypeEnum.POSSetting:
            break;
        }
        break;
      case ActionTypeEnum.Clear:
        setUser(null);
        setValidationErrors([]);
        break;
      case ActionTypeEnum.UpdateUserPermissions:
        await handleUpdateUserPermissions(request.request.ID!);
        setShowUserPermissionModel(false);
        setToastModel({ ...toastModel, show: true });
        break;
      case ActionTypeEnum.CancelUpdateUserPermissions:
        setShowUserPermissionModel(false);
        setToastModel({ ...toastModel, show: true });
        break;
    }
  };
  const getAllUsers = async () => {
    setLoading(true);
    const userList = await getUsers();
    setUsers(userList);
    setLoading(false);
  };

  const handleDeleteUser = async () => {
    setShowDeleteUserModel(false);
    const deleteUserResponse = await deleteUser(user !== null ? user.ID : 0);
    const isSuccess: boolean = !(
      deleteUserResponse.Errors != null &&
      deleteUserResponse.Errors.length !== 0
    );
    if (isSuccess) {
      await getAllUsers();
    } else {
      setValidationErrors(deleteUserResponse.Errors);
    }
    setUser(null);
    isSuccess && setToastModel({ ...toastModel, show: true, variant: "success" });
  };
  const handleUpdateUserPermissions = async (userId: number) => {
    if (userId == getUserId()) {
      const privileges = await getUserPrivileges(userId);
      SecureLocalStorageSet(
        SystemConfiguration.keys.privileges,
        JSON.stringify(privileges)
      );
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName("user")}
          </Accordion.Header>
          <Accordion.Body>
            <RegisterUser
              request={user}
              onActionEvent={async (o: RequestActionModel) => {
                await handleUserAction(o);
              }}
              formId={formId}
            />
            {/* transaction  setting*/}
            <ModelDialogBox
              isModelVisible={showTransactionSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("transaction settings")}
              size="xl"
            >
              <TransactionSettings
                userId={userId}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            {/* Point Of Sale  setting*/}
            <ModelDialogBox
              isModelVisible={showUserPOSSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("pos setting")}
              size="xl"
            >
              <POSSettings
                userId={userId}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            <ModelDialogBox
              isModelVisible={showUserPermissionModel}
              isCloseButtonVisible={false}
              size="xl"
              title={getLabelName("user permissions")}
            >
              <UserPermissions
                  userId={userId}
                onActionEvent={async (o) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            {/* user  setting*/}
            <ModelDialogBox
              isModelVisible={showUserSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("user settings")}
              size="xl"
            >
              <UserSettings
                userSettingModel={userSettingModel}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("users")}</Accordion.Header>
          <Accordion.Body>
            <ConfirmModelDialogBox
              isModelVisible={showDeleteUserModel}
              onCloseEvent={() => {
                setShowDeleteUserModel(false);
                setUser(null);
              }}
              actions={deleteUserActions}
            >
              <div className="alert alert-warning">
                {`${getLabelName(
                  "are you sure you want delete"
                )} ${getLabelName("user")} ${user?.User_Name}`}
              </div>
            </ConfirmModelDialogBox>
            {users && users.length !== 0 && (
              <UsersList
                request={users}
                isModifyAble={true}
                isDeleteAble={true}
                isPermissionAble={true}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
                onCompleteEvent={getAllUsers}
                formId={formId}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
