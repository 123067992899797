import {
    BeneficiaryResponseModel,
    BeneficiaryTypeEnum,
    CustomerLightResponseModel,
    EmployeeResponseModel,
    LookupKeyValueModel,
    ResponseBaseModel
} from "../models";
import {handleError, SecureLocalStorageGet, SecureLocalStorageSet} from "../utils";
import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region properties
const cacheKeyPrefix: string = "bene_";
//#endregion
//#region get
export const getBeneficiaryByType = async (
    type: BeneficiaryTypeEnum,
    isCached: boolean = false,
    searchValue: LookupKeyValueModel[] = []
): Promise<BeneficiaryResponseModel[]> => {
    let result: BeneficiaryResponseModel[] = [];
    try {
        switch (type) {
            case BeneficiaryTypeEnum.Customer:
                result = await getCustomers(searchValue);
                break;
            case BeneficiaryTypeEnum.Supplier:
                break;
            case BeneficiaryTypeEnum.Employee:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getEmployees();
                break;
        }
    } catch (err) {
        alert(handleError(err));
    }
    return result;
}
//#endregion
//#region private
const getLocalStorageCachedData = async (
    type: BeneficiaryTypeEnum
): Promise<BeneficiaryResponseModel[]> => {
    let response: BeneficiaryResponseModel[];
    const cacheKey: string =
        cacheKeyPrefix + Object.keys(BeneficiaryTypeEnum)[Object.values(BeneficiaryTypeEnum).indexOf(type)];
    const data = SecureLocalStorageGet(cacheKey);
    if (data != null) {
        response = JSON.parse(data as string) as BeneficiaryResponseModel[];
    } else {
        response = await getBeneficiaryByType(type, false);
        if (response != null && response.length != 0) {
            SecureLocalStorageSet(cacheKey, JSON.stringify(response));
        }
    }
    return response;
};
const getCustomers = async (searchValues: LookupKeyValueModel[]): Promise<BeneficiaryResponseModel[]> => {
    let response: BeneficiaryResponseModel[] = [];
    let url: string = `${AlYuserApiUrls.customerController.searchCustomLight}?serachParmeter.pageNumber=1&serachParmeter.pageSize=50&serachParmeter.isSearchNameLike=true`;
    if (searchValues !== null && searchValues.length !== 0) {
        searchValues.map((row) => {
            url = `${url}&${row.key}=${row.value}`
        });
    }
    const customers: ResponseBaseModel<CustomerLightResponseModel[]> =
        await AlYusrAxiosApiInstance.get(url);
    if (
        customers !== null &&
        customers !== undefined &&
        customers.Result !== null &&
        customers.Result !== undefined &&
        customers.Result.length !== 0
    ) {
        customers.Result.forEach((row) => {
            response.push({
                value: row.ID.toString(),
                name: row.Name_En,
                nameAr: row.Name,
                mobile: row.Mobile,
                address: row.Address
            });
        });
    }
    return response;
};
const getEmployees = async (): Promise<BeneficiaryResponseModel[]> => {
    let response: BeneficiaryResponseModel[] = [];
    const employees: ResponseBaseModel<EmployeeResponseModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.employeeController.getAllEmployees);
    if (
        employees !== null &&
        employees !== undefined &&
        employees.Result !== null &&
        employees.Result !== undefined &&
        employees.Result.length !== 0
    ) {
        employees.Result.forEach((row) => {
            response.push({
                value: row.ID.toString(),
                name: row.NameEn,
                nameAr: row.Name,
                mobile: row.Mobile,
            });
        });
    }
    return response;
};
//#endregion