import React from "react";
import { DataTablePagerModel } from "../../models";
import { useTranslation } from "react-i18next";
import {getLabelName} from "../../utils";

export const DataTablePager: React.FC<DataTablePagerModel> = ({
                                                                  currentPage,
                                                                  rowsPerPage,
                                                                  totalRows,
                                                                  rowsPerPageOptions,
                                                                  onPageChange,
                                                                  onRowsPerPageChange,
                                                              }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage); // Total number of pages
    const {  i18n } = useTranslation(); // Use translation hook

    // Detect if the current language is Arabic (or other RTL languages)
    const isRTL = i18n.language === "ar" || i18n.language === "he" || i18n.language === "ar-AE";

    // CSS-in-JS styles
    const styles = {
        paginationContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
        },
        paginationButton: {
            background: "none",
            border: "none",
            color: "#333",
            fontSize: "16px",
            cursor: "pointer",
            padding: "5px",
        },
        paginationButtonDisabled: {
            color: "#d3d3d3",
            cursor: "not-allowed",
        },
        paginationInfo: {
            margin: "0 10px",
            fontSize: "14px",
        },
        paginationSelect: {
            marginLeft: "10px",
            padding: "4px",
            border: "1px solid #ccc",
            borderRadius: "4px",
        },
    };

    return (
        <div style={styles.paginationContainer}>
            {/* First page button */}
            <button
                style={currentPage === 1 ? { ...styles.paginationButton, ...styles.paginationButtonDisabled } : styles.paginationButton}
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
            >
                {/*{"|<<"}*/}
                {getLabelName("first Page")}
            </button>

            {/* Previous page button */}
            <button
                style={currentPage === 1 ? { ...styles.paginationButton, ...styles.paginationButtonDisabled } : styles.paginationButton}
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                {/*{"<"}*/}
                {getLabelName("previous Page")}
            </button>

            {/* Display current page number / total pages */}
            <span style={styles.paginationInfo}>
        {isRTL
            ? `${totalPages} / ${currentPage}`
            : `${currentPage} / ${totalPages}`}
      </span>

            {/* Next page button */}
            <button
                style={currentPage === totalPages ? { ...styles.paginationButton, ...styles.paginationButtonDisabled } : styles.paginationButton}
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                {/*{">"}*/}
                {getLabelName("next Page")}
            </button>

            {/* Last page button */}
            <button
                style={currentPage === totalPages ? { ...styles.paginationButton, ...styles.paginationButtonDisabled } : styles.paginationButton}
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
            >
                {/*{">>|"}*/}
                {getLabelName("last Page")}
            </button>

            {/* Rows per page select */}
            <select
                style={styles.paginationSelect}
                value={rowsPerPage}
                onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
            >
                {rowsPerPageOptions.map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </select>
        </div>
    );
};
