import { FC, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  DeleteTransaction,
  ErrorValidationBox,
  GenericModelPopupPdfReportViewer,
  LoadingBox,
  RegisterTransaction,
  ToastBox,
  TransactionList,
} from "../..";
import {
  ActionTypeEnum,
  ComponentActionTypeEnum,
  DailyTransactionTypeEnum,
  HasFormIdModel,
  PrintTypeEnum,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  TransactionDetailResponseModel,
  ValidationErrorModel,
} from "../../../models";
import {
  deleteTransaction,
  getTransactionDetail,
  getTransactionDetailForRefund,
} from "../../../serviceBroker/transactionApiServiceBroker";
import {
  generateGuid,
  getLabelName,
  scrollToDocumentTop,
} from "../../../utils";
import {
  clearTransactionDataForRefund,
  transactionDetailInitialValue,
} from "../common/businessLogic/transactionBl";
import { useNavigate, useSearchParams } from "react-router-dom";

interface TransactionMainComponentProps extends HasFormIdModel {
  transactionType: DailyTransactionTypeEnum;
}

export const TransactionMainComponent: FC<TransactionMainComponentProps> = ({
  transactionType,
  formId,
}) => {
  //#region state
  const [key, setKey] = useState(0);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [printTransactionId, setPrintTransactionId] = useState(0);
  const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [componentActionType, setComponentActionType] =
    useState<ComponentActionTypeEnum>(ComponentActionTypeEnum.None);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [object, setObject] = useState<TransactionDetailResponseModel | null>(
    null
  );
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
    variant: "success",
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      const paramValue = searchParams.get("bImDq");
      if (paramValue !== null && paramValue !== "") {
        // const decryptedParamValue: string = decryptCryptoJSString(paramValue, SystemConfiguration.security.encryptCryptoJSKey);
        // if (decryptedParamValue != "") {
        //   console.log('res_v_2',decryptedParamValue)
        //   setComponentActionType(ComponentActionTypeEnum.None)
        //   await handleAction({id: Number(decryptedParamValue), action: ActionTypeEnum.TransactionRefund})
        // }
        await handleAction({
          id: Number(paramValue),
          action: ActionTypeEnum.TransactionRefund,
        });
      }
    };
    fillData().then(() => {});
  }, [searchParams]);
  //#endregion
  //#region function
  const handleAction = async (request: RequestActionModel) => {
    // setObject(null);
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
      case ActionTypeEnum.DeleteSuccess:
      case ActionTypeEnum.SaveSuccess:
      case ActionTypeEnum.Success:
        scrollToDocumentTop();
        setObject({ ...transactionDetailInitialValue });
        setToastModel({ ...toastModel, show: true });
        setIsRefresh(true);
        setComponentActionType(ComponentActionTypeEnum.Reset);
        setValidationErrors([]);
        break;
      case ActionTypeEnum.Delete:
        setLoading(true);
        const deleteTransactionsResponse = await deleteTransaction(
          request.request
        );
        if (
          deleteTransactionsResponse.Errors !== null &&
          deleteTransactionsResponse.Errors !== undefined &&
          deleteTransactionsResponse.Errors.length !== 0
        ) {
          setValidationErrors(deleteTransactionsResponse.Errors);
          //scrollToTop(validationMessageRef);
          scrollToDocumentTop();
        } else {
          setShowDeleteModel(false);
          setIsRefresh(true);
        }
        setLoading(false);
        break;
      case ActionTypeEnum.Add:
        setObject(null);
        break;
      case ActionTypeEnum.Update:
      case ActionTypeEnum.ModifyStart:
        setLoading(true);
        setValidationErrors([]);
        scrollToDocumentTop();
        const record = await getTransactionDetail(request.request.id!);
        if (record !== null) {
          record.rowState = RowStateEnum.Update;
          record.TransactionDetaillist.forEach((row) => {
            row.rowState = RowStateEnum.Update;
            row.rowKey = generateGuid();
          });
        }
        setObject(record);
        setComponentActionType(ComponentActionTypeEnum.Edit);
        setLoading(false);
        break;
      case ActionTypeEnum.TransactionRefundIInitialization:
        const paramUrl: string = `bImDq=${request.request
          .id!}&ojXNQ=${generateGuid()}`;
        switch (transactionType) {
          case DailyTransactionTypeEnum.SalesInvoice:
               DailyTransactionTypeEnum.POSSales,
               DailyTransactionTypeEnum.POSSalesRefund,
               DailyTransactionTypeEnum.POSRestaurant,
               DailyTransactionTypeEnum.Reserve_Restaurant_Bill,
               DailyTransactionTypeEnum.ReturnPOSRestaurant,
            navigate(`/transactions/sales/return?${paramUrl}`);
            setKey((prevKey) => prevKey + 1);
            break;
          case DailyTransactionTypeEnum.PurchasesInvoice:
            navigate(`/transactions/purchases/return?${paramUrl}`);
            setKey((prevKey) => prevKey + 1);
            break;
        }
        break;
      case ActionTypeEnum.TransactionRefund:
        setLoading(true);
        setValidationErrors([]);
        scrollToDocumentTop();
        let recordForReturn = await getTransactionDetailForRefund(request.id!);
        if (
          recordForReturn === null ||
          recordForReturn === undefined ||
          recordForReturn.TransactionDetaillist === null ||
          recordForReturn.TransactionDetaillist === undefined ||
          recordForReturn.TransactionDetaillist.length === 0
        ) {
          setValidationErrors([
            {
              MessageAr: getLabelName("refund.noItems.ForRefund"),
              MessageEn: getLabelName("refund.noItems.ForRefund"),
            },
          ]);
        } else {
          setObject(
            clearTransactionDataForRefund(
              recordForReturn || transactionDetailInitialValue
            )
          );
          setComponentActionType(ComponentActionTypeEnum.Refund);
        }
        setLoading(false);
        break;
      case ActionTypeEnum.DeleteStart:
        setObject(request.request);
        setShowDeleteModel(true);
        break;
      case ActionTypeEnum.DeleteFailed:
        setValidationErrors(request.request);
        setShowDeleteModel(false);
        scrollToDocumentTop();
        break;
      case ActionTypeEnum.DeleteReset:
        setValidationErrors([]);
        setObject(null);
        setShowDeleteModel(false);
        break;
      case ActionTypeEnum.ClearError:
        setValidationErrors([]);
        break;
      case ActionTypeEnum.Clear:
        //scrollToTop(validationMessageRef);
        scrollToDocumentTop();
        setObject({ ...transactionDetailInitialValue });
        setComponentActionType(ComponentActionTypeEnum.Reset);
        setValidationErrors([]);
        break;
      case ActionTypeEnum.RaiseError:
        //scrollToTop(validationMessageRef);
        scrollToDocumentTop();
        setValidationErrors(request.request);
        break;
      case ActionTypeEnum.AddPartialCustomerStart:
        break;
      case ActionTypeEnum.PrintPdf:
        setPrintTransactionId(request?.request?.id || 0);
        setShowPrintoutPdfModel(true);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <div key={key}>
      {/*<div ref={validationMessageRef} />*/}
      {loading && <LoadingBox />}
      {showPrintoutPdfModel && (
        <GenericModelPopupPdfReportViewer
          keys={[{ key: "transactionId", value: printTransactionId }]}
          type={PrintTypeEnum.Invoice}
          onCloseEvent={() => {
            setShowPrintoutPdfModel(false);
          }}
        />
      )}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <RegisterTransaction
        transactionType={transactionType}
        request={object}
        setActionType={setComponentActionType}
        actionType={componentActionType}
        onActionEvent={(o: RequestActionModel) => {
          handleAction(o).then(() => {});
        }}
        formId={formId}
      />
      <ConfirmModelDialogBox
        isModelVisible={showDeleteModel}
        onCloseEvent={() => {
          setShowDeleteModel(false);
        }}
      >
        <DeleteTransaction
          onActionEvent={handleAction}
          //@ts-ignore
          request={object}
        />
      </ConfirmModelDialogBox>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body className="TUEFO-header">
            <TransactionList
              transactionType={transactionType}
              onActionEvent={(o: RequestActionModel) => {
                handleAction(o).then(() => {});
              }}
              setIsRefresh={setIsRefresh}
              isRefresh={isRefresh}
              formId={formId}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
  //#endregion
};
