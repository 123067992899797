import React, {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { useFormik } from "formik";
import {
  ActionTypeEnum,
  ItemModel,
  RequestActionModel,
  ValidationErrorModel,
  ItemUnitModel,
  LookupItemModel,
  LookupTypeEnum,
  ItemModifierModel,
  RowStateEnum,
  InputDatePickerTimeDisplayModeEnum,
  GatherItemModel, PageEnum,
} from "../../models";
import {
  ErrorValidationBox,
  LoadingBox,
  AntdTableComponent,
  TextBox,
  SelectBox,
  InputDatePicker,
  ButtonBox,
  CheckBox,
  ItemImageForm,
  GatherItemForm,
  PrivilegesChecker,
} from "..";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { Accordion } from "react-bootstrap";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import {
  handleGenerateAntdItemUnitColumns,
  handleGenerateAntdItemModifierColumns,
} from "./uiHelper/dataTableColumns";
import {
  handleAddItemUnitRow,
  handleAddModifierUnitRow,
  setItemInitialValues,
  handleSubmit,
  handleItemComponentLoad,
  handleRefreshItemComponent,
} from "./businessLogic/itemBl";
import {
  useCompanySettings,
  useSupplierAccounts,
  useItemConfigs,
} from "../../hooks";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { getItemImageById } from "../../serviceBroker/itemApiServiceBroker";

export const RegisterItem: FC<{
  request?: ItemModel | null;
  isRefresh: boolean;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  onActionEvent: (o: RequestActionModel) => void;
  isFormLoading: boolean;
  setFormLoading: Dispatch<SetStateAction<boolean>>;
}> = ({
  request,
  isRefresh = false,
  setIsRefresh,
  onActionEvent = () => {},
  isFormLoading,
  setFormLoading,
}) => {
  //#region state
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { suppliersLoading, storedSuppliers } = useSupplierAccounts();
  const { itemConfigs } = useItemConfigs();
  const [_, setItem] = useState<ItemModel>(request ?? setItemInitialValues);
  const {
    companySettings: { DefaultTaxPercentage },
  } = useCompanySettings();
  const [isImageAccordionExpanded, setIsImageAccordionExpanded] =
    useState(false);
  const isItemImageLoadedBefore = useRef(false);

  const [itemUnitList, setItemUnitList] = useState<ItemUnitModel[]>(
    request !== null &&
      request !== undefined &&
      request.ItemsInstores !== null &&
      request.ItemsInstores !== undefined &&
      request.ItemsInstores.length !== 0
      ? request.ItemsInstores[0].Item_unit
      : setItemInitialValues.ItemsInstores[0].Item_unit
  );

  const [gatherItems, setGatherItems] = useState<GatherItemModel[]>(
    request !== null &&
      request !== undefined &&
      request.ItemsInstores[0].Item_unit[0].ItemGathers !== null &&
      request.ItemsInstores[0].Item_unit[0].ItemGathers !== undefined &&
      request.ItemsInstores[0].Item_unit[0].ItemGathers.length !== 0
      ? request.ItemsInstores[0].Item_unit[0].ItemGathers
      : []
  );
  const [itemModifierList, setItemModifierList] = useState<ItemModifierModel[]>(
    request !== null && request !== undefined && request.Item_Modifiers !== null
      ? request.Item_Modifiers
      : []
  );
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [__, setSelectedCategory] = useState<number>(request?.Cat_ID || 0);
  const [unitList, setUnitList] = useState<LookupItemModel[]>([]);
  const [defaultUnitID, setDefaultUnitID] = useState(0);
  const [categoryList, setCategoryList] = useState<LookupItemModel[]>([]);
  //#endregion
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const itemModifiersAntdColumns = [
    ...handleGenerateAntdItemModifierColumns(
      isArabic,
      setItemModifierList,
      itemModifierList
    ),
  ];
  const resetForm = () => {
    onActionEvent({
      id: 0,
      action: ActionTypeEnum.Clear,
    });
    formik.setValues({
      ...setItemInitialValues,
      TaxValue: DefaultTaxPercentage,
    });
    resetItemUnitStoreList();
    setValidationErrors([]);
  };
  const resetItemUnitStoreList = (request?: ItemModel | null) => {
    const itemsInStore =
      request !== null && request !== undefined
        ? request.ItemsInstores[0].Item_unit
        : [
            {
              ...setItemInitialValues.ItemsInstores[0].Item_unit[0],
              Unit_ID: defaultUnitID || 0,
            },
          ];
    setItemUnitList(itemsInStore);
  };

  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: { ...setItemInitialValues, TaxValue: DefaultTaxPercentage },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    //validationSchema: validationSchema,
    // validate,
    onSubmit: async (values) => {
      setFormLoading(true);
      const isSubmitted = await handleSubmit(
        values,
        itemUnitList,
        gatherItems,
        itemModifierList,
        request?.Cat_ID || formik.values.Cat_ID,
        onActionEvent,
        setItem,
        setItemUnitList,
        setItemModifierList,
        setSelectedCategory,
        setValidationErrors,
        setGatherItems
      );
      setFormLoading(false);
      if (isSubmitted) {
        resetForm();
      } else {
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.RaiseError,
        });
      }
    },
  });
  //#endregion
  //#region variables
  const itemUnitsAntdColumns = [
    ...handleGenerateAntdItemUnitColumns(
      isArabic,
      unitList,
      setItemUnitList,
      itemUnitList,
      formik.values.TaxValue??0
    ),
  ];
  //#endregion
  //#region useEffect
  useEffect(() => {
    handleItemComponentLoad(
      setFormLoading,
      setUnitList,
      setCategoryList,
      setDefaultUnitID
    ).then(() => {});
    const getCategories = async () => {
      const categoryList: LookupItemModel[] = await getLookupByType(
        LookupTypeEnum.AllCategories
      );
      setCategoryList(categoryList);
    };
    getCategories().then(() => {});
  }, []);
  useEffect(() => {
    if (isRefresh) {
      handleRefreshItemComponent(
        formik,
        setItem,
        setSelectedCategory,
        setIsRefresh,
        setValidationErrors,
        setGatherItems,
        request,
        resetItemUnitStoreList,
        setItemModifierList
      ).then();
      setIsImageAccordionExpanded(false);
      isItemImageLoadedBefore.current = false;
      const getCategories = async () => {
        const categoryList: LookupItemModel[] = await getLookupByType(
          LookupTypeEnum.AllCategories
        );
        setCategoryList(categoryList);
      };
      getCategories().then(() => {});
    }
  }, [isRefresh]);
  // useEffect(() => {
  //   setCatId(null);
  // }, [categoryList]);

  useEffect(() => {
    if (
      isItemImageLoadedBefore.current ||
      !isImageAccordionExpanded ||
      !request?.ID
    )
      return;
    handleGetImage().then(() => {});
    isItemImageLoadedBefore.current = true;
  }, [isImageAccordionExpanded]);

  useEffect(() => {
    resetItemUnitStoreList();
  }, [defaultUnitID]);
  //#endregion
  //#region function
  const handleGetImage = async () => {
    setIsImageLoading(true);
    const imageData = await getItemImageById(request?.ID!);
    setIsImageLoading(false);
    if (!imageData.Result?.FileBinary) return;
    await formik.setFieldValue("ItemImages", {
      File64Bit: imageData.Result.FileBinary,
      ID: imageData.Result.ID,
      ImageDescription: imageData.Result.FileDescription,
      ImageName: imageData.Result.Name,
      Name: imageData.Result.Name,
      rowState: RowStateEnum.Update,
    });
  };
  const calculateBeginPriceQyt = (gatherItems: GatherItemModel[]) => {
    if (itemUnitList.length === 0) return;
    setItemUnitList((prev) =>
      prev.map((item, index) => {
        if (index === 0) {
          const sum = gatherItems
            .filter(({ rowState }) => rowState !== RowStateEnum.Delete)
            .reduce((acc, cur) => cur.PriceCost * cur.Quantity + acc, 0);
          return {
            ...item,
            PriceQutyBegBal: sum,
            PriceQutyBegBalWithTax: +(
              sum +
              (sum * (formik.values.TaxValue??0)) / 100
            ).toFixed(),
          };
        }
        return item;
      })
    );
  };
  //#endregion
  //#region html
  return (
    <>
      {<ErrorValidationBox errors={validationErrors} />}
      <LoadingBox isLoading={isFormLoading}>
        <form onSubmit={formik.handleSubmit}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{getLabelName("Items")}</Accordion.Header>
              <Accordion.Body>
                <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
                  <SelectBox
                    labelName={getLabelName("Categorys")}
                    source={categoryList}
                    isSingleSelect={true}
                    onStatusChange={(e: any) => {
                      if (e === null) {
                        formik.values.Cat_ID = 0;
                      } else {
                        formik.values.Cat_ID = e.value;
                        request == null ? null : (request.Cat_ID = e.value);
                      }
                    }}
                    selectedValues={
                      request == null ? null : [request.Cat_ID.toString()]
                    }
                    multiselectRef={undefined}
                  />
                  <TextBox
                    key="Name"
                    labelName={getLabelName("Item Name")} //{t("lookup.nameAr")}
                    inputName="Name"
                    errorText={formik.errors.Name}
                    //  inputValue={item.Name}
                    //   onChange={(e: any) => {
                    //     formik.setFieldValue("Name", e.target.value)
                    //     updateStateDynamically(setItem, item, "Name", e.target.value)
                    //   }}
                    inputValue={formik.values.Name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.Cat_ID ? <>{formik.errors.Cat_ID}</> : null}
                  <TextBox
                    labelName={getLabelName("English Name")} //{t("lookup.nameAr")}
                    inputName="Name_En"
                    errorText={formik.errors.Name_En}
                    inputValue={formik.values.Name_En}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <TextBox
                    labelName={getLabelName("ItemCode")} //{t("lookup.nameAr")}
                    inputName="Code"
                    errorText={formik.errors.Code}
                    inputValue={formik.values.Code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <TextBox
                    labelName={getLabelName("txtTaxPercentage")} //{t("lookup.nameAr")}
                    inputName="TaxValue"
                    type="number"
                    errorText={formik.errors.TaxValue}
                    inputValue={formik.values.TaxValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <TextBox
                    labelName={getLabelName("Limit Order")}
                    inputName="OrderLimit"
                    type="number"
                    inputValue={formik.values.ItemsInstores[0].LimitOrder}
                    onChange={(e: any) => {
                      formik.values.ItemsInstores[0].LimitOrder =
                        e.target.value;
                      formik.setFieldValue("OrderLimit", e.target.value);
                    }}
                  />
                  <TextBox
                    labelName={getLabelName("Stagnant Period")} //{t("lookup.nameAr")}
                    inputName="StagnantPeriod"
                    type="number"
                    errorText={formik.errors.StagnantPeriod}
                    inputValue={formik.values.StagnantPeriod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextBox
                    labelName={getLabelName("Display Index")} //{t("lookup.nameAr")}
                    inputName="DisplySequence"
                    type="number"
                    errorText={formik.errors.DisplySequence}
                    inputValue={formik.values.DisplySequence}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextBox
                    labelName={getLabelName("Total Tobaco Value")} //{t("lookup.nameAr")}
                    inputName="TobaccoTaxPercentage"
                    type="number"
                    errorText={formik.errors.TobaccoTaxPercentage}
                    inputValue={formik.values.TobaccoTaxPercentage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isReadOnly={!formik.values.IsIncludeTobaccoTax}
                  />
                  {itemConfigs.ShowExpireDate && (
                    <CheckBox
                      labelName={getLabelName("Use Expiry Date")}
                      checked={formik.values.ItemsInstores[0].UseExpiryDate}
                      inputName="UseExpiryDate"
                      onChange={(e: any) => {
                        formik.values.ItemsInstores[0].UseExpiryDate =
                          e.target.checked;
                        formik.setFieldValue("UseExpiryDate", e.target.checked);
                      }}
                      id="UseExpiryDate"
                    />
                  )}
                  {formik.values.ItemsInstores[0].UseExpiryDate && (
                    <InputDatePicker
                      id="datePicker"
                      timeMode={InputDatePickerTimeDisplayModeEnum.None}
                      selectedDate={formik.values.DateExpiry}
                      className="form-control"
                      InputLabel={getLabelName("Expiry Date")}
                      name={getLabelName("Expiry Date")}
                      onChange={(date: Date) => {
                        formik.values.DateExpiry = date;
                        formik.setFieldValue("DateExpiry", date);
                      }}
                    />
                  )}
                  <TextBox
                    labelName={getLabelName("Expire Day")} //{t("lookup.nameAr")}
                    inputName="ExpireDay"
                    type="number"
                    errorText={formik.errors.ExpireDay}
                    inputValue={
                      formik.values.ItemsInstores[0].DefaultExpiryDays
                    }
                    onChange={(e: any) => {
                      formik.values.ItemsInstores[0].DefaultExpiryDays =
                        e.target.value;
                      formik.setFieldValue("ExpireDay", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    isReadOnly={!formik.values.ItemsInstores[0].UseExpiryDate}
                  />
                </div>

                <div className="row mt-4">
                  <TextBox
                    labelName={getLabelName("Notes")} //{t("lookup.nameAr")}
                    inputName="Notes"
                    type="textarea"
                    errorText={formik.errors.Notes}
                    inputValue={formik.values.Notes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="mt-1 row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
                  <CheckBox
                    labelName={getLabelName("Tobacco Tax")}
                    checked={formik.values.IsIncludeTobaccoTax}
                    inputName="IsIncludeTobaccoTax"
                    onChange={() => {
                      formik.setFieldValue(
                        "IsIncludeTobaccoTax",
                        !formik.values.IsIncludeTobaccoTax
                      );
                    }}
                    id="IsIncludeTobaccoTax"
                  />
                  <CheckBox
                    labelName={getLabelName("Open Price")}
                    checked={formik.values.OpenItem}
                    inputName="OpenItem"
                    onChange={() => {
                      formik.setFieldValue("OpenItem", !formik.values.OpenItem);
                    }}
                    id="OpenItem"
                  />
                  <CheckBox
                    labelName={getLabelName("ShowInPOS")}
                    checked={formik.values.ShowInPOS}
                    inputName="ShowInPOS"
                    onChange={() => {
                      formik.setFieldValue(
                        "ShowInPOS",
                        !formik.values.ShowInPOS
                      );
                    }}
                    id="ShowInPOS"
                  />

                  <CheckBox
                    labelName={getLabelName("Is Active")}
                    checked={formik.values.ItemsInstores[0].Status}
                    inputName="Status"
                    onChange={() => {
                      formik.setFieldValue(
                        "Status",
                        !formik.values.ItemsInstores[0].Status
                      );
                    }}
                    id="Status"
                  />

                  <CheckBox
                    labelName={getLabelName("chkWithoutBalance")}
                    checked={formik.values.WithoutBalance}
                    inputName="WithoutBalance"
                    onChange={() => {
                      formik.setFieldValue(
                        "WithoutBalance",
                        !formik.values.WithoutBalance
                      );
                    }}
                    id="WithoutBalance"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{getLabelName("Units")}</Accordion.Header>
              <Accordion.Body className="overflow-visible">
                <div className="item-unit-list mt-3">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                    <ButtonBox
                      iconType="plus"
                      variant="primary"
                      size="sm"
                      className="mb-3"
                      onClick={() => {
                        handleAddItemUnitRow(
                          setItemUnitList,
                          itemUnitList,
                          unitList[0].value === null ? 0 : +unitList[0].value
                        );
                      }}
                    >
                      {getLabelName("Add")}
                    </ButtonBox>
                  </div>
                  <AntdTableComponent
                    data={itemUnitList.filter(
                      (row) => row.rowState !== RowStateEnum.Delete
                    )}
                    columns={itemUnitsAntdColumns}
                    rowKey="rowKey"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {itemConfigs.ShowModifiers && (
            <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>{getLabelName("Modifiers")}</Accordion.Header>
                <Accordion.Body>
                  <div className="item-unit-list">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                      <ButtonBox
                        iconType="plus"
                        variant="primary"
                        size="sm"
                        className="mb-3"
                        onClick={() => {
                          handleAddModifierUnitRow(
                            setItemModifierList,
                            itemModifierList
                          );
                        }}
                      >
                        {getLabelName("Add")}
                      </ButtonBox>
                    </div>
                    <AntdTableComponent
                      data={itemModifierList.filter(
                        (row) => row.rowState !== RowStateEnum.Delete
                      )}
                      columns={itemModifiersAntdColumns}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          <Accordion>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{getLabelName("Item Option")}</Accordion.Header>
              <Accordion.Body className="overflow-visible">
                <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
                  <CheckBox
                    labelName={getLabelName("Has Modifiers")}
                    checked={formik.values.HasModifiers}
                    inputName="HasModifiers"
                    onChange={() => {
                      formik.setFieldValue(
                        "HasModifiers",
                        !formik.values.HasModifiers
                      );
                    }}
                    id="HasModifiers"
                  />

                  <CheckBox
                    labelName={getLabelName("Is Production Item")}
                    checked={formik.values.IsProduction}
                    inputName="IsProduction"
                    onChange={() => {
                      formik.setFieldValue(
                        "IsProduction",
                        !formik.values.IsProduction
                      );
                    }}
                    id="IsProduction"
                  />
                  <CheckBox
                    labelName={getLabelName("Gather Item")}
                    checked={formik.values.GatherItem}
                    inputName="GatherItem"
                    onChange={() => {
                      formik.setFieldValue(
                        "GatherItem",
                        !formik.values.GatherItem
                      );
                    }}
                    id="GatherItem"
                  />
                  <CheckBox
                    labelName={getLabelName("Is Item Vanish Consign")}
                    checked={formik.values.IsItemVanishConsign}
                    inputName="IsItemVanishConsign"
                    onChange={() => {
                      formik.setFieldValue(
                        "IsItemVanishConsign",
                        !formik.values.IsItemVanishConsign
                      );
                    }}
                    id="IsItemVanishConsign"
                  />
                  <CheckBox
                    labelName={getLabelName("Is Unis Separate In Balance")}
                    checked={formik.values.IsUnisSeparateInBalance}
                    inputName="IsUnisSeparateInBalance"
                    onChange={() => {
                      formik.setFieldValue(
                        "IsUnisSeparateInBalance",
                        !formik.values.IsUnisSeparateInBalance
                      );
                    }}
                    id="IsUnisSeparateInBalance"
                  />
                  <CheckBox
                    labelName={getLabelName("Item Is Different Factor")}
                    checked={formik.values.IsDifferentFactor}
                    inputName="IsDifferentFactor"
                    onChange={() => {
                      formik.setFieldValue(
                        "IsDifferentFactor",
                        !formik.values.IsDifferentFactor
                      );
                    }}
                    id="IsDifferentFactor"
                  />
                  {itemConfigs.ShowFilterOilOption && (
                    <CheckBox
                      labelName={getLabelName("Is Oil Service Item")}
                      checked={formik.values.Is_Oil_Service}
                      inputName="Is_Oil_Service"
                      onChange={() => {
                        formik.setFieldValue(
                          "Is_Oil_Service",
                          !formik.values.Is_Oil_Service
                        );
                      }}
                      id="Is_Oil_Service"
                    />
                  )}
                  <CheckBox
                    labelName={getLabelName("Is Filter Service Item")}
                    checked={formik.values.Is_Filter_Service}
                    inputName="Is_Filter_Service"
                    onChange={() => {
                      formik.setFieldValue(
                        "Is_Filter_Service",
                        !formik.values.Is_Filter_Service
                      );
                    }}
                    id="Is_Filter_Service"
                  />
                  {itemConfigs.ShowItemSuppleer && (
                    <SelectBox
                      labelName={getLabelName("Supplier")}
                      source={storedSuppliers}
                      isSingleSelect={true}
                      onStatusChange={(e: any) => {
                        formik.values.Item_Supplier_ID = e.value;
                        request == null
                          ? (setItemInitialValues.Item_Supplier_ID = e.value)
                          : (request.Item_Supplier_ID = e.value);
                      }}
                      selectedValues={[
                        request == null
                          ? setItemInitialValues.Item_Supplier_ID.toString()
                          : request.Item_Supplier_ID.toString(),
                      ]}
                      multiselectRef={undefined}
                      isDataLoading={suppliersLoading}
                    />
                  )}
                  {itemConfigs.CanAddAddationlCost && (
                    <CheckBox
                      labelName={getLabelName("Can Add Aadational Cost")}
                      checked={formik.values.CanAddAddationalCost}
                      inputName="CanAddAddationalCost"
                      onChange={() => {
                        formik.setFieldValue(
                          "CanAddAddationalCost",
                          !formik.values.CanAddAddationalCost
                        );
                      }}
                      id="CanAddAddationalCost"
                    />
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {(itemConfigs.ShowGatherItem || itemConfigs.ShowproductionItem) && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {getLabelName("Gather Item")}
                </Accordion.Header>
                <Accordion.Body className="overflow-visible">
                  <GatherItemForm
                    categoryList={categoryList}
                    gatherItemsList={gatherItems}
                    setGatherItems={setGatherItems}
                    calculateBeginPriceQyt={calculateBeginPriceQyt}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {
            <Accordion
              onSelect={(activeKey: AccordionEventKey) =>
                setIsImageAccordionExpanded(activeKey !== null)
              }
              activeKey={isImageAccordionExpanded ? "3" : null}
            >
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {getLabelName("Image Item")}
                </Accordion.Header>
                <Accordion.Body
                  className="overflow-visible position-relative"
                  style={{ minHeight: 200 }}
                >
                  <ItemImageForm
                    isImageLoading={isImageLoading}
                    image={formik.values.ItemImages}
                    deleteImage={() => {
                      if (!request?.ID)
                        return formik.setFieldValue("ItemImages", null);
                      formik.setFieldValue("ItemImages", {
                        ...formik.values.ItemImages,
                        rowState: RowStateEnum.Delete,
                      });
                    }}
                    setFileBase64={(base64: string | null) => {
                      if (base64 === null)
                        return formik.setFieldValue("ItemImages", null);
                      formik.setFieldValue("ItemImages", {
                        File64Bit: base64,
                        ID: request?.ItemImages?.ID
                          ? request?.ItemImages?.ID
                          : 0,
                        ImageDescription: `${formik.values.Name} item`,
                        ImageName: `${formik.values.Name} item`,
                        Name: formik.values.Name,
                        rowState: request?.ID
                          ? RowStateEnum.Update
                          : RowStateEnum.Add,
                      });
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          }
          <div className="my-4 d-flex justify-content-end">
            <PrivilegesChecker
              formId={PageEnum.Items}
              action={request?.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox
                iconType="content-save"
                type="submit"
                className="btn  btn-sm mx-2"
                variant="primary"
              >
                {getLabelName(request?.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="receipt"
              variant="danger"
              type="button"
              className="btn btn-sm"
              onClick={resetForm}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </form>
      </LoadingBox>
    </>
  );
  //#endregion
};
