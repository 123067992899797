import {
  ItemDiscountValuesModel,
  LookupItemModel,
  LookupTypeEnum,
  POSUserSettingModel,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
  ValidationErrorModel,RowStateEnum
} from "../../../../models";
import {Dispatch, SetStateAction} from "react";
import {getLookupByType} from "../../../../serviceBroker/lookupApiServiceBroker";
import {getPosItemsBySearch} from "../../../../serviceBroker/itemApiServiceBroker";
import {generateGuid, getLabelName, getUserId, getValueInCurrency,} from "../../../../utils";
import {handleTransactionTotalValues, transactionDetailInitialValue,} from "./transactionBl";
import {
  calculateItemDiscount,
  calculateItemPrice,
  calculateItemUnitPriceOrPriceWithTax,
  handleCalculateExistItemValuesChange,
} from "./commonBl";
import {getPointOfSaleSetting, getUserTransactionsSettings,} from "../../../../serviceBroker/userApiServiceBroker";
import {SystemConfiguration} from "../../../../configuration";
import {getCustomerById} from "../../../../serviceBroker/customerApiServiceBroker";
import {getEmployeeInformation} from "../../../../serviceBroker/employeesApiServiceBroker";
//#region variables
export const beneficiaryInitialValues: LookupItemModel = {
  children: undefined,
  isAdded: undefined,
  name: "",
  nameAr: "",
  otherValue: undefined,
  value: "0",
};
export const employeeInitialValues: LookupItemModel = {
  children: undefined,
  isAdded: undefined,
  name: "",
  nameAr: "",
  otherValue: undefined,
  value: "0",
};
//#endregion
//#region lookup functions
export const getPosCategories = async () => {
  return await getLookupByType(LookupTypeEnum.CategoryType, true, false);
};
//#endregion
//#region item functions
export const getPosItemList = async (
  categoryId: number | null,
  barCode: string | null,
  nameAndCode: string | null,
  storeId: number | null
): Promise<TransactionItemResponseModel[]> => {
  const { Result } = await getPosItemsBySearch(
    categoryId,
    nameAndCode,
    barCode,
    storeId
  );
  return Result || [];
};
export const handleSortAlphabetically = (items: LookupItemModel[]) => {
  items.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};
export const handleAddItemPos = async (
  //e: React.MouseEvent,
  request: TransactionItemResponseModel,
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel
) => {
  try {
    let updateSelectItemArr = [...stateValue.TransactionDetaillist];
    const index = stateValue.TransactionDetaillist.findIndex((item) => item.ItemUnit_ID === request.ItemUnit_ID && item.rowState === RowStateEnum.Add);
    if (index !== -1) {
      const newItem = request;
      request = stateValue.TransactionDetaillist[index];
      request.Quantity = Number(request.Quantity) + 1;
      request.Unit_Price = newItem.Unit_Price;
      request.Unit_PriceInCurrencyCalculated =
        newItem.Unit_PriceInCurrencyCalculated;
      request.ItemDiscount =
        newItem.ItemDiscount !== 0
          ? newItem.ItemDiscount
          : request.ItemDiscount;
      request.ItemDiscountInCurrency =
        newItem.ItemDiscountInCurrency !== 0
          ? newItem.ItemDiscountInCurrency
          : request.ItemDiscountInCurrency;
    }

    request.ItemInstore_ID =
      request.ItemInstore_ID === null || request.ItemInstore_ID === undefined
        ? 0
        : request.ItemInstore_ID;

    request.CreatedBy = request.ID === 0 ? getUserId() : request.CreatedBy;
    request.ModifiedBy = request.ID !== 0 ? getUserId() : request.ModifiedBy;
    request.rowState = RowStateEnum.Add
    request.ItemDiscountInCurrency = request.ItemDiscountInCurrency || 0;
    request.ItemDiscount = request.ItemDiscount || 0;
    request.ItemDiscountRatio = request.ItemDiscountRatio || 0; //(data.discountRatio || 0);
    const calculatedDiscount: ItemDiscountValuesModel =
      calculateItemDiscount(request);
    request.ItemDiscount = calculatedDiscount.ItemDiscount; // calculateItemDiscount(request);
    request.ItemDiscountInCurrency = calculatedDiscount.ItemDiscountInCurrency;

    request.Unit_PriceInCurrencyCalculated = getValueInCurrency(
      request.Unit_Price,
      stateValue.ValueCurrency
    );
    request.UnitPriceWithTaxCalculated = calculateItemUnitPriceOrPriceWithTax(
      request.Unit_Price,
      request.TaxPercentge,
      true
    );
    request.UnitPriceWithTaxInCurrency = getValueInCurrency(
      request.UnitPriceWithTaxCalculated,
      stateValue.ValueCurrency
    );
    //request.Tax = calculateItemTax(request);
    request.TaxInCurrency = getValueInCurrency(
      request.Tax,
      stateValue.ValueCurrency
    );
    request.TotalTax = request.Tax * request.Quantity;
    request.TotalTaxInCurrency = getValueInCurrency(
      request.TotalTax,
      stateValue.ValueCurrency
    );
    request.Total = calculateItemPrice(request);
    request.TotalInCurrency = getValueInCurrency(
      request.Total,
      stateValue.ValueCurrency
    );
    request.rowKey = generateGuid();
    index !== -1
      ? (updateSelectItemArr[index] = { ...request })
      : (updateSelectItemArr = [...stateValue.TransactionDetaillist, request]);

    request.latestModifiedDate = new Date();
    await handleCalculateExistItemValuesChange(
      setState,
      {
        ...stateValue,
        TransactionDetaillist: updateSelectItemArr,
      },
      request
    );

    //     ? (updateSelectItemArr[indexToUpdate] = {
    //         ...item,
    //         Quantity: updateSelectItemArr[indexToUpdate].Quantity + 1,
    //     })
    //     : (updateSelectItemArr = [...transactionData.TransactionDetaillist, item]);
    // setTransactionData({
    //     ...data,
    //     TransactionDetaillist: updateSelectItemArr,
    // });
  } catch (e) {}
};

export const transformReservedTransactionData = (
  reservedTransaction: TransactionDetailResponseModel
) => {
  const TransactionDetaillist = reservedTransaction.TransactionDetaillist.map(
    (item: TransactionItemResponseModel) => {
      const discount = calculateItemDiscount(item);
      const priceWithTax = calculateItemUnitPriceOrPriceWithTax(
        item.Unit_Price,
        item.TaxPercentge,
        true
      );
      return {
      ...item,
      rowKey: "" + item.ID,
      Unit_PriceInCurrencyCalculated: getValueInCurrency(
        item.Unit_Price,
        reservedTransaction.ValueCurrency || 1
      ),
      UnitPriceWithTaxCalculated: priceWithTax,
      UnitPriceWithTaxInCurrency: getValueInCurrency(
        priceWithTax,
        reservedTransaction.ValueCurrency || 1
      ),
      //Tax : calculateItemTax(request),
      TaxInCurrency: getValueInCurrency(
        item.Tax,
        reservedTransaction.ValueCurrency || 1
      ),
      TotalTax: item.Tax * item.Quantity,
      TotalTaxInCurrency: getValueInCurrency(
        item.TotalTax,
        reservedTransaction.ValueCurrency || 1
      ),
      Total: calculateItemPrice(item),
      TotalInCurrency: getValueInCurrency(
        item.Total,
        reservedTransaction.ValueCurrency || 1
      ),
      ItemDiscount: discount.ItemDiscount,
      ItemDiscountInCurrency: discount.ItemDiscountInCurrency,

    }
  }
  );
  return handleTransactionTotalValues({
    ...reservedTransaction,
    TransactionDetaillist,
  });
};

export const handleResetTransactionPos = async (
  setTransactionDetail: Dispatch<
    SetStateAction<TransactionDetailResponseModel>
  >,
  beneficiary: LookupItemModel,
  setBeneficiary: Dispatch<SetStateAction<LookupItemModel>>,
  employee: LookupItemModel,
  setEmployee: Dispatch<SetStateAction<LookupItemModel>>,
  setValidationErrors: Dispatch<SetStateAction<ValidationErrorModel[]>>,
  setPosSetting: Dispatch<SetStateAction<POSUserSettingModel>>,
  transactionType: number
) => {
  setValidationErrors([]);
  await initializePosTransactionValuesConfiguration(
    setTransactionDetail,
    transactionDetailInitialValue,
    {
      name: "",
      nameAr: "",
      value: "0",
    },
    setBeneficiary,
    {
      name: "",
      nameAr: "",
      value: "0",
    },
    setEmployee,
    setPosSetting,
    transactionType,
    [transactionType]
  );
};
export const getTotalPricePost = (
  setTransactionData: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  transactionData: TransactionDetailResponseModel
) => {
  let total = 0;
  const totalPrice = (item: TransactionItemResponseModel) => {
    total += item.Quantity * item.Unit_Price;
  };
  transactionData.TransactionDetaillist.forEach(totalPrice);
  setTransactionData({ ...transactionData, TotalNet: total });
};
export const handleDeleteItemPos = (
  item: TransactionItemResponseModel,
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel
) => {
  const filteredItems = stateValue.TransactionDetaillist.map(
    (selectItem) => {
      if(selectItem.rowKey === item.rowKey &&  selectItem.rowState === item.rowState && selectItem.rowState === RowStateEnum.Add) return null;
      else if(selectItem.rowKey === item.rowKey && selectItem.rowState === item.rowState){
        return {...selectItem, rowState: RowStateEnum.Delete}
      }
      return selectItem;
    }
  ).filter(item => item !== null) as TransactionItemResponseModel[];
  const stateObject = handleTransactionTotalValues({
    ...stateValue,
    TransactionDetaillist: filteredItems ,
  });
  // @ts-ignore
  setState({
    ...stateObject,
    TransactionDetaillist: filteredItems ,
  });
};
//#endregion
//#region input changes functions
export const handleInputQuantityChangePos = async (
  e: any,
  setTransactionData: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  transactionData: TransactionDetailResponseModel,
  item: TransactionItemResponseModel
) => {
  let updateSelectItemArr = [...transactionData.TransactionDetaillist];
  const indexToUpdate = updateSelectItemArr.findIndex(
    (selectItem) => selectItem.ItemUnit_ID === item.ItemUnit_ID
  );
  indexToUpdate >= 0
    ? (updateSelectItemArr[indexToUpdate] = {
        ...item,
        Quantity:
          updateSelectItemArr[indexToUpdate].Quantity > 0
            ? e.target.value === "increase"
              ? updateSelectItemArr[indexToUpdate].Quantity + 1
              : e.target.value === "decrease" &&
                updateSelectItemArr[indexToUpdate].Quantity > 1
              ? updateSelectItemArr[indexToUpdate].Quantity - 1
              : 1
            : 1,
      })
    : (updateSelectItemArr = [...transactionData.TransactionDetaillist]);
  setTransactionData({
    ...transactionData,
    TransactionDetaillist: updateSelectItemArr,
  });
};
export const handleInputQuantityPriceChangePos = (
  e: any,
  setTransactionData: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  transactionData: TransactionDetailResponseModel,
  item: TransactionItemResponseModel
) => {
  let updateSelectItemArr = [...transactionData.TransactionDetaillist];
  let inputType = e.target.name;
  const indexToUpdate = updateSelectItemArr.findIndex(
    (selectItem) => selectItem.ItemUnit_ID === item.ItemUnit_ID
  );
  indexToUpdate >= 0
    ? inputType === "quantity"
      ? (updateSelectItemArr[indexToUpdate] = {
          ...item,
          Quantity: Number(e.target.value),
        })
      : inputType === "priceSale"
      ? (updateSelectItemArr[indexToUpdate] = {
          ...item,
          Unit_Price: Number(e.target.value),
        })
      : ""
    : (updateSelectItemArr = [...transactionData.TransactionDetaillist]);
  setTransactionData({
    ...transactionData,
    TransactionDetaillist: updateSelectItemArr,
  });
};
//#endregion
//region validate functions
export const handleInitialValidateTransaction = async (
  transactionData: TransactionDetailResponseModel
): Promise<ValidationErrorModel[]> => {
  let errors: ValidationErrorModel[] = [];
  if (
    transactionData === null ||
    transactionData.TransactionDetaillist === null ||
    transactionData.TransactionDetaillist.length === 0
  ) {
    errors.push({
      MessageAr: getLabelName("Items Should Be Add to Transaction"),
      MessageEn: getLabelName("Items Should Be Add to Transaction"),
    });
  }
  return errors;
};
export const handleValidateTransaction = async (
  transactionData: TransactionDetailResponseModel
): Promise<ValidationErrorModel[]> => {
  let errors: ValidationErrorModel[] = await handleInitialValidateTransaction(
    transactionData
  );
  if (errors.length === 0) {
    transactionData.TransactionDetaillist.forEach((row) => {
      if (row.MaxQty !== 0 && row.Quantity > row.MaxQty) {
        errors.push({
          MessageEn: `${row.ItemName_En} ${getLabelName("refund.item qty")} ${
            row.MaxQty
          }`,
          MessageAr: `${row.ItemName} ${getLabelName("refund.item qty")} ${
            row.MaxQty
          }`,
        });
      }
      if (row.ItemDiscount > row.Unit_Price) {
        errors.push({
          MessageEn: `${row.ItemName_En} ${getLabelName(
            "pos.item.discount validation"
          )} ${row.ItemDiscount}`,
          MessageAr: `${row.ItemName} ${getLabelName(
            "pos.item.discount validation"
          )} ${row.ItemDiscount}`,
        });
      }
    });
  }
  return errors;
};
//#endregion
//#region post functions
export const handleSaveAndPay = async (
  transactionData: TransactionDetailResponseModel,
  setShowPaymentDetailsModel: Dispatch<SetStateAction<boolean>>,
  setValidationErrors: Dispatch<SetStateAction<ValidationErrorModel[]>>
) => {
  setValidationErrors([]);
  const errors = await handleValidateTransaction(transactionData);
  if (errors !== null && errors.length !== 0) {
    setValidationErrors(errors);
    return;
  } else {
    setShowPaymentDetailsModel(true);
  }
};
export const handleAddBeneficiary = async (
  transactionData: TransactionDetailResponseModel,
  setShowAddBeneficiaryModel: Dispatch<SetStateAction<boolean>>,
  setValidationErrors: Dispatch<SetStateAction<ValidationErrorModel[]>>,
  t: any
) => {
  setValidationErrors([]);
  const errors = await handleInitialValidateTransaction(transactionData);
  if (errors !== null && errors.length !== 0) {
    setValidationErrors(errors);
    return;
  } else {
    setShowAddBeneficiaryModel(true);
  }
};
export const handleShowPrint = async (
  printTransactionId: number,
  setShowPrintoutPdfModel: Dispatch<SetStateAction<boolean>>,
  setValidationErrors: Dispatch<SetStateAction<ValidationErrorModel[]>>
) => {
  printTransactionId === 0
    ? setValidationErrors([
        {
          MessageAr: getLabelName("No Data Found For Print"),
          MessageEn: getLabelName("No Data Found For Print"),
        },
      ])
    : setShowPrintoutPdfModel(true);
};
//#endregion
//#region button functions
export const handleChangeQuantityButton = async (
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel,
  request: TransactionItemResponseModel
) => {
  let obj: TransactionItemResponseModel[];

  obj = stateValue.TransactionDetaillist.map((row) => {
    if (row.rowKey == request.rowKey && request.rowState === row.rowState) {
      const rowState = row.rowState === RowStateEnum.Add ? row.rowState : RowStateEnum.Update;
      return {...request, rowState};
    } else {
      return row;
    }
  });
  await handleCalculateExistItemValuesChange(
    setState,
    { ...stateValue, TransactionDetaillist: obj },
    request
  );
};
//#endregion
//#region  settings functions
export const initializePosTransactionValuesConfiguration = async (
  setTransactionDetail: Dispatch<
    SetStateAction<TransactionDetailResponseModel>
  >,
  transactionDetail: TransactionDetailResponseModel,
  beneficiary: LookupItemModel,
  setBeneficiary: Dispatch<SetStateAction<LookupItemModel>>,
  employee: LookupItemModel,
  setEmployee: Dispatch<SetStateAction<LookupItemModel>>,
  setPosSetting: Dispatch<SetStateAction<POSUserSettingModel>>,
  transactionType: number,
  transactionTypeId: number[]
) => {
  const settingsList = await getUserTransactionsSettings(
    transactionTypeId,
    false,
    SystemConfiguration.cacheKey.posSettingsList
  );
  const currentTransactionSetting = settingsList.filter(
    (p) => p.TransactionType_ID === transactionType
  )[0];
  const posSetting = await getPointOfSaleSetting(getUserId(), true);
  setPosSetting(posSetting);
  setBeneficiary(
    await getBeneficiary(transactionDetail, beneficiary, posSetting)
  );
  setEmployee(await getEmployee(transactionDetail, employee, posSetting));
  if (posSetting !== null && currentTransactionSetting !== null) {
    setTransactionDetail({
      ...transactionDetail,
      Customer_ID:
        transactionDetail.Customer_ID !== null &&
        transactionDetail.Customer_ID !== 0
          ? transactionDetail.Customer_ID
          : posSetting.Customer_ID,
      Currency_ID:
        transactionDetail.Currency_ID !== null &&
        transactionDetail.Currency_ID !== 0
          ? transactionDetail.Currency_ID
          : posSetting.Currency_ID,
      PaymentType_ID:
        transactionDetail.PaymentType_ID !== null &&
        transactionDetail.PaymentType_ID !== 0
          ? transactionDetail.PaymentType_ID
          : currentTransactionSetting.DefaultPaymentTypeID,
      CalcType_ID:
        transactionDetail.CalcType_ID !== null &&
        transactionDetail.CalcType_ID !== 0
          ? transactionDetail.CalcType_ID
          : currentTransactionSetting.DefaultCalcTypeID,
      Emp_ID:
        transactionDetail.Emp_ID !== null && transactionDetail.Emp_ID !== 0
          ? transactionDetail.Emp_ID
          : posSetting.Emp_ID??0,
    });
  }
};
//#endregion
//#region private
const getBeneficiary = async (
  transactionDetail: TransactionDetailResponseModel,
  beneficiary: LookupItemModel,
  posSetting: POSUserSettingModel | null
): Promise<LookupItemModel> => {
  if (beneficiary === null || beneficiary.value === "0") {
    if (
      transactionDetail.Customer_ID !== null &&
      transactionDetail.Customer_ID !== 0
    ) {
      const customer = await getCustomerById(
        transactionDetail.Customer_ID || 0
      );
      if (customer !== null) {
        beneficiary = {
          name: customer.Name_En,
          nameAr: customer.Name,
          value: customer.ID.toString(),
          isAdded: true,
        };
      }
    } else {
      beneficiary = {
        name: posSetting?.CustomerInfo?.EnglishName || "",
        nameAr: posSetting?.CustomerInfo?.ArabicName || "",
        value: posSetting?.CustomerInfo?.ID.toString() || "0",
        isAdded: true,
      };
    }
  }
  return beneficiary;
};
const getEmployee = async (
  transactionDetail: TransactionDetailResponseModel,
  beneficiary: LookupItemModel,
  posSetting: POSUserSettingModel | null
): Promise<LookupItemModel> => {
  if (beneficiary === null || beneficiary.value === "0") {
    if (transactionDetail.Emp_ID !== null && transactionDetail.Emp_ID !== 0) {
      const employee = await getEmployeeInformation(
        transactionDetail.Emp_ID || 0
      );
      if (employee !== null) {
        beneficiary = {
          name: employee.NameEn,
          nameAr: employee.Name,
          value: employee.ID.toString(),
          isAdded: true,
        };
      }
    } else {
      beneficiary = {
        name: posSetting?.EmployeeInfo?.EnglishName || "",
        nameAr: posSetting?.EmployeeInfo?.ArabicName || "",
        value: posSetting?.EmployeeInfo?.ID.toString() || "0",
        isAdded: true,
      };
    }
  }
  return beneficiary;
};

//#endregion
