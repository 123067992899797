import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {PaymentConfigApiResponseModel, paymentTypesApiResponseModel, ResponseBaseModel, UserDeleteResponseModel,} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {handleError} from "../utils";
//#region get
export const getPaymentConfiguration =
    async (): Promise<PaymentConfigApiResponseModel> => {
        try {
            // @ts-ignore
            return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.paymentTypeController.getPaymentConfiguration);
        } catch (err) {
            alert(handleError(err));
        }
        // @ts-ignore
        return;
    };
export const getAllPaymentTypes = async (): Promise<
    ResponseBaseModel<paymentTypesApiResponseModel[]>
> => {
    let response: ResponseBaseModel<paymentTypesApiResponseModel[]> = {};
    try {
        response = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.paymentTypeController.getAllPaymentType);
    } catch (err) {
        alert(handleError(err));
    }
    return response;
};
export const getPaymentTypeById = async (
    id: number
): Promise<ResponseBaseModel<paymentTypesApiResponseModel>> => {
    let response: ResponseBaseModel<paymentTypesApiResponseModel> = {};
    try {
        response = await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.paymentTypeController.getPaymentTypeById}?paymentTypeId=${id}`);
    } catch (err) {
        alert(handleError(err));
    }
    return response;
};
//#endregion
//#region post
export const savePaymentType = async (
    request: paymentTypesApiResponseModel
): Promise<ResponseBaseModel<paymentTypesApiResponseModel>> => {
    let apiResponse: ResponseBaseModel<paymentTypesApiResponseModel> = {};
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.paymentTypeController.savePaymentType, {...request});
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const deletePaymentTypeById = async (
    id: number
): Promise<UserDeleteResponseModel> => {
    let response: UserDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        response = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.paymentTypeController.deletePaymentType}?id=${id}`);
        return response;
    } catch (err) {
        alert(handleError(err));
    }
    return response;
};
//#endregion