import { Dispatch, SetStateAction } from "react";
import {
  DailyTransactionTypeEnum,
  ItemDiscountValuesModel,
  LookupItemModel,
  PriceTypeEnum,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
  UserTransactionsSettingResponseModel,
  ValidationErrorModel,RowStateEnum
} from "../../../../models";
import _ from "lodash";
import {
  handleCalculateItemRecordValuesUpdate,
  handleTransactionTotalValues,
} from "./transactionBl";
import {getLabelName, handleError, roundNumber} from "../../../../utils";
//#region post
export const UpdateTransactionItemStateOnChange = async (
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel,
  request: TransactionItemResponseModel
) => {
  let obj: TransactionItemResponseModel[] =
    stateValue.TransactionDetaillist.map((row) => {
      if (row.rowKey == request.rowKey && row.rowState === request.rowState) {
        const rowState = row.rowState === RowStateEnum.Add ? row.rowState : RowStateEnum.Update;
        return {...request, rowState};
      } else {
        return row;
      }
    });
  setState({
    ...stateValue,
    TransactionDetaillist: obj,
  });
};
//#endregion
//#region calculations
export const handleCalculateExistItemValuesChange = async (
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel,
  request: TransactionItemResponseModel
) => {
  let obj: TransactionItemResponseModel[] = _.map(
    stateValue.TransactionDetaillist,
    (row) => {
      if (row.rowKey == request.rowKey && row.rowState === request.rowState) {
        request.latestModifiedDate = new Date();
        request = handleCalculateItemRecordValuesUpdate(request);
        return request;
      } else {
        return row;
      }
    }
  );
  const stateObject = handleTransactionTotalValues({
    ...stateValue,
    TransactionDetaillist: obj,
  });
  setState({
    ...stateObject,
    TransactionDetaillist: obj,
  });
};
export const calculateItemDiscount = (
  request: TransactionItemResponseModel
): ItemDiscountValuesModel => {
  let response: ItemDiscountValuesModel = {
    ItemDiscount: 0,
    ItemDiscountInCurrency: 0,
  };
  let discountValue: number = request.ItemDiscount || 0;
  let discountValueInCurrency: number = request.ItemDiscountInCurrency || 0;
  const itemPrice: number = (request.Quantity || 0) * (request.Unit_Price || 0);
  const itemPriceInCurrency: number =
    (request.Quantity || 0) * (request.Unit_PriceInCurrencyCalculated || 0);
  discountValue =
    request.ItemDiscountRatio !== 0
      ? (itemPrice * (request.ItemDiscountRatio || 0)) / 100
      : discountValue;
  discountValueInCurrency =
    request.ItemDiscountRatio !== 0
      ? (itemPriceInCurrency * (request.ItemDiscountRatio || 0)) / 100
      : discountValue;
  response.ItemDiscount = roundNumber(discountValue);
  response.ItemDiscountInCurrency = roundNumber(discountValueInCurrency);
  return response;
  //return RoundNumber(value)
};
export const calculateItemUnitPriceOrPriceWithTax = (
  value: number,
  itemTaxPercentage: number,
  isTaxCalculation: boolean
): number => {
  const fixedValue: number = 100;
  return isTaxCalculation
    ? roundNumber((value * itemTaxPercentage) / fixedValue + Number(value))
    : roundNumber(
        value -
          (itemTaxPercentage * value) /
            (fixedValue + Number(itemTaxPercentage)),
        2
      );
};
export const calculateItemTax = (
  request: TransactionItemResponseModel
): number => {
  let taxValue: number = 0;
  try {
    taxValue =
      request.TaxPercentge > 0
        ? roundNumber(
            Number(
              (request.TaxPercentge *
                (request.Unit_Price -
                  request.ItemDiscount / request.Quantity)) /
                100
            )
          )
        : 0;
  } catch (e: any) {
    alert(handleError(e));
    taxValue = 0;
  }
  return taxValue;
};
export const calculateItemPrice = (
  request: TransactionItemResponseModel
): number => {
  let value: number = request.Quantity * request.Unit_Price;
  value = request.ItemDiscount !== 0 ? value - request.ItemDiscount : value;
  value = value + request.TotalTax;
  // value =
  //   request.ItemDiscountRatio !== 0
  //     ? //@ts-ignore
  //       value - value / request.ItemDiscountRatio
  //     : value;
  return roundNumber(value);
};
//#endregion
//#region validation
export const validateAddItem = async (
  request: TransactionItemResponseModel
): Promise<ValidationErrorModel[]> => {
  let errors: ValidationErrorModel[] = [];
  if (request.Categ_ID === null || request.Categ_ID === undefined) {
    errors.push({
      MessageAr: getLabelName("categoryId missing"),
      MessageEn: getLabelName("categoryId missing"),
    });
  }
  if (
    request.ItemInstore_ID === null ||
    request.ItemInstore_ID === undefined ||
    request.ItemInstore_ID === 0
  ) {
    errors.push({
      MessageAr: getLabelName("itemId missing"),
      MessageEn: getLabelName("itemId missing"),
    });
  }
  if (request.ItemUnit_ID === null || request.ItemUnit_ID === undefined) {
    errors.push({
      MessageAr: getLabelName("unitId missing"),
      MessageEn: getLabelName("unitId missing"),
    });
  }
  if (
    request.Quantity === null ||
    request.Quantity === undefined ||
    request.Quantity <= 0
  ) {
    errors.push({
      MessageAr: getLabelName("quantity missing"),
      MessageEn: getLabelName("quantity missing"),
    });
  }
  if (
    request.Unit_Price === null ||
    request.Unit_Price === undefined
    // ||request.Unit_Price <= 0
  ) {
    errors.push({
      MessageAr: getLabelName("price missing"),
      MessageEn: getLabelName("price missing"),
    });
  }
  return errors;
};
//#endregion
//#region item
export const handleGroupItemsBasedOnId = (
  request: TransactionItemResponseModel[],
  item: TransactionItemResponseModel
): TransactionItemResponseModel[] => {
  if (request === null || request === undefined || request.length === 0) {
    request = [];
  }
  const index = _.findIndex(request, { ItemUnit_ID: item.ItemUnit_ID });
  if (index !== -1) {
    request[index] = item;
    // request[index].Quantity = Number(request[index].Quantity) + Number(item.Quantity);
    // request[index].ItemDiscount =Number(item.ItemDiscount)!==0?Number(item.ItemDiscount):Number(request[index].ItemDiscount);
    // request[index].ItemDiscountRatio =Number(item.ItemDiscountRatio)!==0?Number(item.ItemDiscountRatio):   Number(request[index].ItemDiscountRatio);
    // const calculatedDiscount= calculateItemDiscount( request[index]);
    // request[index].ItemDiscount =calculatedDiscount.ItemDiscount;
    // request[index].ItemDiscountInCurrency =calculatedDiscount.ItemDiscountInCurrency;
    // request[index].Total = calculateItemPrice( request[index]);
    // request[index].TotalInCurrency=getValueInCurrency(request[index].Total,request[index].ValueOfCurrency);
  } else {
    request.push(item);
  }
  return request;
};
export const getItemSalesPrice = (
  id: number,
  transactionType: DailyTransactionTypeEnum,
  calcType: number | null,
  itemList: LookupItemModel[],
  calcTypeList: LookupItemModel[],
  userTransactionSetting: UserTransactionsSettingResponseModel
  //transactionObject:TransactionDetailResponseModel
): number => {
  if (id === 0) {
    return 0;
  }
  // @ts-ignore
  const calcTypeRecord: LookupItemModel =
    calcType !== null && calcType !== undefined && calcType !== 0
      ? calcTypeList.filter((p) => p.value === calcType.toString())[0]
      : calcTypeList.filter(
          (p) => p.value === userTransactionSetting.DefaultCalcTypeID.toString()
        )[0];
  let price: number = 0;
  const item = itemList.filter(
    //@ts-ignore
    (p) => p.value === id.toString()
  )[0];
  try {
    let transactionCalcType: number = 0;
    //transactionCalcType = calcTypeRecord.otherValue.priceType;
    if (
      transactionCalcType === Number(PriceTypeEnum.DefaultPrice) ||
      Number(transactionCalcType === PriceTypeEnum.None)
    ) {
      try {
        transactionCalcType = Number(
          calcTypeList.filter(
            (p) =>
              p.value === userTransactionSetting.DefaultCalcTypeID.toString()
          )[0].otherValue.priceType
        );
      } catch (e) {
        transactionCalcType = Number(PriceTypeEnum.DefaultPrice);
      }
    }
    switch (transactionCalcType) {
      case Number(PriceTypeEnum.PriceSale):
        switch (transactionType) {
          case DailyTransactionTypeEnum.SalesInvoice:
          case DailyTransactionTypeEnum.SalesRefund:
          case DailyTransactionTypeEnum.POSSales:
          case DailyTransactionTypeEnum.POSSalesRefund:
          case DailyTransactionTypeEnum.POSRestaurant:
          case DailyTransactionTypeEnum.Reserve_Restaurant_Bill:
          case DailyTransactionTypeEnum.ReturnPOSRestaurant:
            price = item.otherValue.sellPrice;
            break;
          case DailyTransactionTypeEnum.PurchasesInvoice:
          case DailyTransactionTypeEnum.PurchasesRefund:
            price = item.otherValue.purchasePrice;
            break;
          case DailyTransactionTypeEnum.MoveToStore:
          case DailyTransactionTypeEnum.ReceiveFromStore:
            price = item.otherValue.sellPrice;
            break;
        }
        break;
      case Number(PriceTypeEnum.PriceCost):
        price = item.otherValue.costPrice;
        break;
      case Number(PriceTypeEnum.PricePurchase):
        price = item.otherValue.purchasePrice;
        break;
      case Number(PriceTypeEnum.WholeSalePrice):
        price = item.otherValue.wholeSalePrice;
        break;
      case Number(PriceTypeEnum.AdditionalPrice):
        price = item.otherValue.additionalPrice;
        break;
      case Number(PriceTypeEnum.DefaultPrice):
      default:
        price = item.otherValue.sellPrice;
        break;
    }

    price = price === null || price === undefined ? 0 : price;
    price =
      Number(price) +
      Number(price) * (Number(calcTypeRecord.otherValue.percentage) / 100);
  } catch (e: any) {
    alert(handleError("error occurred while getItemSalesPrice please contact support" + e));
  }
  return price === null || price === undefined ? 0 : price;
};
export const getItemInStoreId = (
  itemUnitId: number | null,
  itemList: LookupItemModel[]
): number | null => {
  let value: number | null = null;
  if (itemUnitId !== null && itemUnitId !== undefined) {
    const item = itemList.filter(
      //@ts-ignore
      (p) => p.value === itemUnitId.toString()
    )[0];
    return item !== null &&
      item !== undefined &&
      item.otherValue != null &&
      item.otherValue.itemInStoreId !== null
      ? Number(item.otherValue.itemInStoreId)
      : null;
  }
  return value;
};
//#endregion
