import { FC, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  DictionaryList,
  ErrorValidationBox,
  LoadingBox,
  ToastBox,
} from "../../components";
import {
  ActionTypeEnum,
  DictionaryResponseModel,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import { getAllDictionaries } from "../../serviceBroker/dictionaryApiServiceBroker";
import { getLabelName } from "../../utils";

export const DictionaryPage: FC = () => {
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [objects, setObjects] = useState<DictionaryResponseModel[]>([]);
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllObjects();
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.Success:
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        window.scrollTo(0, 0);
        setValidationErrors([]);
        setIsRefresh(true);
        break;
      case ActionTypeEnum.Failed:
        setValidationErrors(request.request);
        break;
    }
  };
  const getAllObjects = async () => {
    setLoading(true);
    let response: DictionaryResponseModel[] = [];

    let result = await getAllDictionaries();
    if (result) {
      result.map((row, index) => {
        response.push({
          ArabicName: row.NameAr,
          EnglishName: row.NameEn,
          Name: row.Name,
          ID: row.Id,
          CreatedBy: 0,
          ModifiedBy: 0,
          rowState: RowStateEnum.Update,
          ControlType_ID: undefined,
          CreationDate: undefined,
          ModificationDate: undefined,
          ShowToUser: undefined,
          System_ID: undefined,
          TranslatedName: undefined,
          VerifyOnUpdate: undefined,
          isChanged: false,
          index: index,
        });
      });
      setObjects(response);
    }
    setTotalRows(0);
    setLoading(false);
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("dictionary")}</Accordion.Header>
          <Accordion.Body>
            <Card>
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                <DictionaryList
                  request={objects}
                  onActionEvent={async (o: RequestActionModel) => {
                    await handleAction(o);
                  }}
                  getDictionaries={async () => {
                    await getAllObjects();
                  }}
                  totalRows={totalRows}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
