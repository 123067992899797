import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {ResponseBaseModel, DeleteUnitModel, UnitModel} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {handleError} from "../utils";
//#region get
export const getUnits = async (): Promise<ResponseBaseModel<UnitModel[]> | null> => {
    try {
        // @ts-ignore
        return await AlYusrAxiosApiInstance.get(
            AlYuserApiUrls.unitController.getUnitList
        );
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
//#endregion
//#region post
export const saveUnit = async (
    request: UnitModel
): Promise<ResponseBaseModel<UnitModel> | null> => {
    try {
        // @ts-ignore
        return await AlYusrAxiosApiInstance.post(
            AlYuserApiUrls.unitController.saveUnit,
            request
        );
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const deleteUnit = async (
    id: number
): Promise<ResponseBaseModel<DeleteUnitModel>> => {
    let apiResponse: ResponseBaseModel<DeleteUnitModel> = {};
    try {
        let url: string = `${AlYuserApiUrls.unitController.deleteUnit}?id=${id}`;
        apiResponse = await AlYusrAxiosApiInstance.post(url);
        // @ts-ignore
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
//#endregion
