import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {DictionaryModel, DictionaryResponseModel, OriginalDictionaryModel, ResponseBaseModel,} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {getTransactionsTypesDictionaries} from "./lookupApiServiceBroker";
import {handleError} from "../utils";
//#region get
export const getAllDictionaries = async (): Promise<
    DictionaryModel[] | null
> => {
    try {
        const data: ResponseBaseModel<OriginalDictionaryModel[]> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.dictionaryController.getAllDictionaryList}?lang=1`);
        if (
            data !== null &&
            data !== undefined &&
            data.Result !== null &&
            data.Result !== undefined
        ) {
            let result: DictionaryModel[] = data.Result.map((rawDat) => ({
                Id: rawDat.ID,
                Name: rawDat.Name.toLowerCase(),
                NameAr: rawDat.ArabicName,
                NameEn: rawDat.EnglishName,
                TypeId: rawDat.ControlType_ID,
                WebUrl: rawDat.WebUrl,
            }));
            const transactionTypeDictionaries =
                await getTransactionsTypesDictionaries();
            if (
                transactionTypeDictionaries !== null &&
                transactionTypeDictionaries !== undefined &&
                transactionTypeDictionaries.length !== 0
            ) {
                result.push(...transactionTypeDictionaries);
            }
            return result;
        }

        return [];
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const getButtonDictionaries = async (
    lang: number = 1
): Promise<DictionaryModel[] | null> => {
    try {
        // @ts-ignore
        return await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.dictionaryController.getButtonDictionaryList}?lang=${lang}`);
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
//#endregion
//#region post
export const saveNotExistTranslation = async (
    word: string,
    controlTypeId: number
): Promise<DictionaryModel | null> => {
    try {
        let response: DictionaryModel | null = null;
        const url: string = `${AlYuserApiUrls.dictionaryController.saveNotExistTranslation}?word=${word}&controltypeId=${controlTypeId}`;
        const result: ResponseBaseModel<OriginalDictionaryModel> =
            await AlYusrAxiosApiInstance.post(url);

        if (
            result !== null &&
            result !== undefined &&
            result.Result !== null &&
            result.Result !== undefined
        ) {
            response = {
                Id: result.Result.ID,
                Name: word,
                NameAr: word,
                NameEn: word,
                TypeId: controlTypeId,
            };
        }
        return response;
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const saveDictionaries = async (
    request: DictionaryResponseModel[]
): Promise<ResponseBaseModel<DictionaryModel> | null> => {
    let response: ResponseBaseModel<DictionaryModel> | null = null;
    try {
        response = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.dictionaryController.saveDictionaries, request);
        return response;
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
//#endregion
