import React, { FC, useState } from "react";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  CategoryResponseModel,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  ValidationErrorModel,
  LoadingObjectsEnum,
} from "../../models";
import { deleteCategory } from "../../serviceBroker/categoryApiServiceBroker";
import { getLabelName, scrollToDocumentTop } from "../../utils";
import {
  ErrorValidationBox,
  ToastBox,
  ConfirmModelDialogBox,
  CategoryList,
  AddCategory,
} from "..";
import { Accordion } from "react-bootstrap";
import { useLoading } from "../../hooks";

export const CategoryMainComponent: FC<{ formId: number }> = ({ formId }) => {
  //#region state
  const [categoryObject, setCategoryObject] =
    useState<CategoryResponseModel | null>(null);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const [isRefresh, setIsRefresh] = useState(false);
  const { loadingData, hideLoader, showLoader } = useLoading({
    [LoadingObjectsEnum.FORM]: false,
    [LoadingObjectsEnum.MODAL]: false,
    [LoadingObjectsEnum.LIST]: false,
  });
  //#endregion
  //#region functions
  const handleDeleteRow = async () => {
    showLoader(LoadingObjectsEnum.MODAL);
    categoryObject!.rowState = Number(RowStateEnum.Delete);
    const deleteResponse = await deleteCategory(categoryObject!.ID);
    const isDeleteOperationSuccess: boolean =
      deleteResponse?.Result !== null && deleteResponse?.Result?.Result == true;
    setToastModel({
      ...toastModel,
      variant: isDeleteOperationSuccess ? "success" : "danger",
      body: isDeleteOperationSuccess
        ? getLabelName("process Success")
        : getLabelName("process Failed"),
      show: true,
    });
    if (isDeleteOperationSuccess) {
      setShowDeleteModel(false);
      setCategoryObject(null);
      setIsRefresh(true);
    }
    hideLoader(LoadingObjectsEnum.MODAL);
  };
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeleteRow();
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setCategoryObject(null);
        setShowDeleteModel(false);
      },
    },
  ];
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        scrollToDocumentTop();
        setValidationErrors([]);
        setToastModel({ ...toastModel, show: true });
        setCategoryObject(null);
        setIsRefresh(true);
        break;
      case ActionTypeEnum.Update:
        showLoader(LoadingObjectsEnum.FORM);
        scrollToDocumentTop();
        setValidationErrors([]);
        if (
          request.request !== null &&
          (request.request as CategoryResponseModel)
        ) {
          setCategoryObject(request.request);
        }
        hideLoader(LoadingObjectsEnum.FORM);
        break;
      case ActionTypeEnum.Delete:
        if (request.request && (request.request as CategoryResponseModel)) {
          //request.request.rowState = Number(RowState.Delete);
          setCategoryObject(request.request);
          setShowDeleteModel(true);
          setValidationErrors([]);
        }
        break;
      case ActionTypeEnum.Failed:
        setValidationErrors(request.request);
        setShowDeleteModel(false);
        break;
      case ActionTypeEnum.Clear:
        setValidationErrors([]);
        setCategoryObject(null);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      {showDeleteModel && (
        <ConfirmModelDialogBox
          isModelVisible={showDeleteModel}
          onCloseEvent={() => {
            setShowDeleteModel(false);
          }}
          isLoading={loadingData.MODAL}
          actions={deleteActions}
        ></ConfirmModelDialogBox>
      )}

      <AddCategory
        request={categoryObject}
        onActionEvent={(o: RequestActionModel) => {
          handleAction(o).then();
        }}
        hideLoader={hideLoader}
        showLoader={showLoader}
        loadingData={loadingData}
        formId={formId}
      />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("previous Data")}</Accordion.Header>
          <Accordion.Body>
            <CategoryList
              setIsRefresh={setIsRefresh}
              isRefresh={isRefresh}
              hideLoader={hideLoader}
              showLoader={showLoader}
              loadingData={loadingData}
              onActionEvent={(o: RequestActionModel) => {
                handleAction(o).then();
              }}
              formId={formId}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
