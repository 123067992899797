import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    CategoryResponseModel,
    ResponseBaseModel,
    TreeViewModel,
    CategoryDeleteResponseModel, ItemImageModel,
} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {handleError} from "../utils";
//#region get
export const getCategoryTree = async (): Promise<TreeViewModel[]> => {
    let categoryResult: ResponseBaseModel<CategoryResponseModel[]>;
    let response: TreeViewModel[] = [];
    categoryResult = await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.categoryController.getCategoryList}?lang=1`);
    if (
        categoryResult !== null &&
        categoryResult.Result !== null &&
        categoryResult.Result !== undefined &&
        categoryResult.Result.length !== 0
    ) {
        response = generateCategoryTree(categoryResult.Result, 0);
    }
    return response;
};
export const getCategories = async (): Promise<
    ResponseBaseModel<CategoryResponseModel[]>
> => {
    let result: ResponseBaseModel<CategoryResponseModel[]> = {};
    try {
        result = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.categoryController.getCategoryList);
    } catch (err) {
        alert(handleError(err));
    }
    return result;
};
export const getCategoryById = async (): Promise<
    ResponseBaseModel<CategoryResponseModel>
> => {
    let result: ResponseBaseModel<CategoryResponseModel> = {};
    try {
        result = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.categoryController.GetCategoryById);
    } catch (err) {
        alert(handleError(err));
    }
    return result;
};
export const getCategoryImageById = async (
    id: number
): Promise<ResponseBaseModel<ItemImageModel>> => {
    const url: string = `${AlYuserApiUrls.categoryController.getCategoryImageById}?categoryId=${id}`;
    return await AlYusrAxiosApiInstance.get(url);
};
//#endregion
//#region post
export const addCategory = async (
    request: CategoryResponseModel
): Promise<ResponseBaseModel<CategoryResponseModel>> => {
    let apiResponse: ResponseBaseModel<CategoryResponseModel> = {};
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.categoryController.saveCategory, {...request});
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const deleteCategory = async (
    id: number
): Promise<CategoryDeleteResponseModel> => {
    let apiResponse: CategoryDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.categoryController.deleteCategory}?id=${id}`);
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
//#endregion
//#region private
const generateCategoryTree = (
    request: CategoryResponseModel[],
    parentId: number
): TreeViewModel[] => {
    let response: TreeViewModel[] = [];
    request
        .filter((p) => p.Parent_ID === parentId && p.ID !== 0)
        .forEach((row) => {
            response.push({
                name: row.Name_En,
                nameAr: row.Name,
                key: row.ID.toString(),
                children: generateCategoryTree(request, row.ID),
            });
        });
    return response;
};
//#endregion
