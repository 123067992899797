import React, { useState } from "react";
import {
  DataTablePropsModel,
  GridColumnsHookParamsModel,
  FlattenMenuModel,
  PageEnum,
  TableActionColumnsStyleEnum,
  ActionTypeEnum,
  RequestActionModel,
} from "../../../../models";
import { useGridColumns } from "../../../../hooks";
import { Dropdown } from "react-bootstrap";
import {
  CustomDropdown,
  LoadingBox,
  ModelDialogBox,
  TableComponentV2,
} from "../../..";
import {
  getFormPrivileges,
  getLabelName,
  getMenuItemByFormId,
} from "../../../../utils";
import RenderGridColumnsSettings from "./rendering/renderGridColumnsSettings";
import { TableColumnBase } from "react-data-table-component/dist/src/DataTable/types";
import { getClientActionsColumns } from "./rendering/renderGridActionsColumns";
import { TableColumn } from "react-data-table-component";

//#region interface
interface GridTableProps<T> extends Omit<DataTablePropsModel, "columns"> {
  columnsProps?: GridColumnsHookParamsModel<T> | null;
  columns?: TableColumnBase[] | null;
  style?: TableActionColumnsStyleEnum | null;
  formId?: number | null;
  // isFetchColumnFromServer?: boolean|null;
}
interface ManualColumnsComponentProps {
  columns: TableColumn<any>[];
  data: any[];
  tableProps: Omit<DataTablePropsModel, "columns" | "data">;
  // columnsProps: GridColumnsHookParams<any>;
  style?: TableActionColumnsStyleEnum | null;
  formId?: number | null;
  onActionEvent?: (o: RequestActionModel) => void;
}
interface ServerColumnsComponentProps {
  data: any[];
  tableProps: Omit<DataTablePropsModel, "columns" | "data">;
  columnsProps: GridColumnsHookParamsModel<any>;
  style?: TableActionColumnsStyleEnum | null;
  formId?: number | null;
}
//#endregion
//#region variables
const defaultGridStyle: TableActionColumnsStyleEnum =
  TableActionColumnsStyleEnum.DropDownText;
//#endregion
//#region functions
const getGridStyle = (
  formId: number,
  style: TableActionColumnsStyleEnum
): TableActionColumnsStyleEnum => {
  if (formId !== PageEnum.none) {
    const menuItem: FlattenMenuModel | null = getMenuItemByFormId(formId);
    return menuItem !== null && menuItem.ActionColumnsStyle != null
      ? menuItem.ActionColumnsStyle
      : style;
  }
  return style;
};
//#endregion
//#region component
export const GridTable = <
  T extends { onActionEvent: (o: RequestActionModel) => void }
>({
  columnsProps,
  data,
  columns,
  style,
  formId,
  // isFetchColumnFromServer=true,
  ...tableProps
}: GridTableProps<T>): React.ReactElement => {
  if (data.length === 0)
    return <p className="text-center ">{getLabelName("No previous data to show")}</p>;
  else if (!columns && columnsProps)
    // && isFetchColumnFromServer===true
    return (
      <ServerGridComponent
        columnsProps={columnsProps}
        data={data}
        tableProps={tableProps}
        style={getGridStyle(formId ?? PageEnum.none, style ?? defaultGridStyle)}
        formId={formId}
      />
    );
  else if (columns?.length !== 0)
    // && isFetchColumnFromServer===false
    return (
      <ClientGridComponent
        columns={columns || []}
        data={data}
        tableProps={tableProps}
        style={getGridStyle(formId ?? PageEnum.none, style ?? defaultGridStyle)}
        formId={formId}
        onActionEvent={columnsProps?.onActionEvent}
      />
    );
  else return <p className="text-center ">not able to generate columns</p>;
};
const container = document.getElementById("root");
const ServerGridComponent: React.FC<ServerColumnsComponentProps> = ({
  data,
  tableProps,
  columnsProps,
  style,
  formId,
}) => {
  const {
    gridColumns,
    fetchGridColumns,
    isGridColumnsLoading,
    columnsSettings,
    onActionEvent,
  } = useGridColumns(
    {
      ...columnsProps,
      data,
    },
    style || defaultGridStyle,
    formId || PageEnum.none
  );
  const { ColumnsSettingId: gridId } = getFormPrivileges(
    formId ?? PageEnum.none
  );
  const [isGridColumnsModalOpen, setIsGridColumnsOpen] = useState(false);
  console.log("res_render_server_component");
  if (data.length === 0)
    return <p className="text-center ">{getLabelName("No previous data to show")}</p>;
  return (
    <LoadingBox isLoading={isGridColumnsLoading}>
      <div className="position-relative">
        <CustomDropdown
          style={{
            position: "absolute",
            left: "20px",
            top: "11px",
            zIndex: 100,
          }}
          dropdownIcon={{ color: "black", size: 20 }}
        >
          <Dropdown.Item
            style={{ minWidth: "15rem" }}
            onClick={() => setIsGridColumnsOpen(true)}
          >
            {getLabelName("Show/Hide Columns")}
          </Dropdown.Item>
        </CustomDropdown>
        <TableComponentV2
          {...tableProps}
          data={data}
          columns={gridColumns}
          onRowDoubleClicked={(row: any) => {
            container?.scrollIntoView({ block: "start", behavior: "smooth" });
            onActionEvent({
              action: ActionTypeEnum.Update,
              request: row,
              id: row.ID,
            });
          }}
        />
      </div>
      <ModelDialogBox
        isModelVisible={isGridColumnsModalOpen}
        isXCloseButtonVisible={true}
        isEscapeCloseEnabled={true}
        size="xl"
        onCloseEvent={() => setIsGridColumnsOpen(false)}
        title={getLabelName("columns")}
      >
        <RenderGridColumnsSettings
          gridId={gridId}
          onComplete={() => setIsGridColumnsOpen(false)}
          fetchGridColumns={fetchGridColumns}
          columnsSettings={columnsSettings}
          isColumnsLoading={isGridColumnsLoading}
        />
      </ModelDialogBox>
    </LoadingBox>
  );
};

const ClientGridComponent: React.FC<ManualColumnsComponentProps> = ({
  columns,
  data,
  tableProps,
  style,
  formId,
  onActionEvent,
}) => {
  console.log("res_render_client_component");
  style = getGridStyle(formId ?? PageEnum.none, style ?? defaultGridStyle);

  if (style !== TableActionColumnsStyleEnum.Button) {
    const buttonColumns = columns.filter((p) => p.button === true);
    const gridColumns = columns.filter((p) => p.button !== true);
    if (buttonColumns !== null && buttonColumns.length !== 0) {
      const renderButtonColumns = getClientActionsColumns(
        buttonColumns,
        style || defaultGridStyle
      );
      columns = [...gridColumns, renderButtonColumns];
    }
  }
  return (
    <TableComponentV2
      {...tableProps}
      data={data}
      columns={columns}
      onRowDoubleClicked={(row: any) => {
        container?.scrollIntoView({ block: "start", behavior: "smooth" });
        onActionEvent?.({
          action: ActionTypeEnum.Update,
          request: row,
          id: row.ID,
        });
      }}
    />
  );
};
//#endregion
