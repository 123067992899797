import React, {FC, useRef, useState} from "react";
import {Accordion} from "react-bootstrap";
import {ConfirmModelDialogBox, ErrorValidationBox, ItemList, RegisterItem, ToastBox,} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum, ErrorDisplayTypeEnum,
  ItemModel,
  RequestActionModel,
  ResponseBaseModel,
  RowStateEnum,
  SearchItemApiResponseModel,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {deleteItem, getItemFullDetailsById,} from "../../serviceBroker/itemApiServiceBroker";
import {getLabelName, isArabicCurrentLanguage, scrollToTop,} from "../../utils";

export const ItemsPage: FC = () => {
  //#region state
  const [isFormLoading, setFormLoading] = useState(false);
  const [isTableLoading, setTableLoading] = useState(false);
  const [isModalLoading, setModalLoading] = useState(false);
  const [isRefreshItemList, setIsRefreshItemList] = useState(false);
  const [showDeleteUserModel, setShowDeleteUserModel] = useState(false);
  const [deletedItem, setDeletedItem] =
    useState<null | SearchItemApiResponseModel>(null);
  const [isRefreshItem, setIsRefreshItem] = useState(false);
  const [object, setObject] = useState<ItemModel | null>(null);
  const isArabic = isArabicCurrentLanguage();
  const itemName = isArabic ? deletedItem?.Name : deletedItem?.Name_En;
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const pageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [validationErrors, setValidationErrors] = useState<
      ValidationErrorModel[]
  >([]);
  //#endregion
  //#region function
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddBeneficiary:
        setToastModel((prev) => ({  ...prev, show: true,body:getLabelName("Beneficiary Added Successfully") ,header: getLabelName("Beneficiary Added Successfully")}));
        const errors: ValidationErrorModel[] = [
          {
            MessageEn: "Email is required.",
            MessageAr: "البريد الإلكتروني مطلوب.",
          },
          {
            MessageEn: "Password must be at least 8 characters long.",
            MessageAr: "كلمة المرور يجب أن تكون على الأقل 8 أحرف.",
          },
          {

            MessageEn: "Passwords do not match.",
            MessageAr: "كلمات المرور غير متطابقة.",
          },
        ];
        setValidationErrors(errors); // Update the state with the generated errors
        break;
      case ActionTypeEnum.Add:
        setObject(null);
        break;
      case ActionTypeEnum.Update:
        setFormLoading(true);
        setTableLoading(true);
        let item = (await getItemFullDetailsById(
          request.id!
        )) as ResponseBaseModel<ItemModel>;
        item.Result!.rowState = 2;
        item.Result!.ItemsInstores = item.Result!.ItemsInstores.map(
          (itemInStore) => ({
            ...itemInStore,
            rowState: RowStateEnum.Update,
            Item_unit: itemInStore.Item_unit.map((itemUnit) => ({
              ...itemUnit,
              rowState: RowStateEnum.Update,
              ItemGathers:
                itemUnit.ItemGathers !== null
                  ? itemUnit.ItemGathers.map((itemGathers) => ({
                      ...itemGathers,
                      rowState: RowStateEnum.Update,
                    }))
                  : null,
            })),
          })
        );

        // item.Result!.ItemsInstores[0].Item_unit =
        //   item.Result!.ItemsInstores[0].Item_unit.map((item) => ({
        //     ...item,
        //     rowState: 2,
        //   }));
        if (item.Result!.Item_Modifiers !== null)
          item.Result!.Item_Modifiers = item.Result!.Item_Modifiers.map(
            (item) => ({ ...item, rowState: 2 })
          );
        // if (item.Result!.ItemsInstores[0].Item_unit[0].ItemGathers !== null)
        //   item.Result!.ItemsInstores[0].Item_unit[0].ItemGathers =
        //     item.Result!.ItemsInstores[0].Item_unit[0].ItemGathers.map(
        //       (item) => ({ ...item, rowState: 2 })
        //     );
        item.Result!.rowState = 2;
        setObject(item.Result!);
        setIsRefreshItem(true);
        setTableLoading(false);
        setFormLoading(false);
        scrollToTop(pageRef);

        break;
      case ActionTypeEnum.Delete:
        setDeletedItem(request.request);
        console.log(request.request);

        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.DeleteOperationStart:
        setDeletedItem(request.request);
        console.log(request.request);
        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.AddSuccess:
      case ActionTypeEnum.DeleteSuccess:
      case ActionTypeEnum.Success:
        setToastModel({ ...toastModel, show: true, variant: "success" });
        setIsRefreshItemList(true);
        if (object?.ID === deletedItem?.ID)
          await handleAction({ action: ActionTypeEnum.Clear });
        setDeletedItem(null);
        setShowDeleteUserModel(false);
        break;
      case ActionTypeEnum.RaiseError:
        scrollToTop(pageRef);
        break;
      case ActionTypeEnum.Clear:
        setObject(null);
        console.log("clear to hope");
        setIsRefreshItem(true);
        scrollToTop(pageRef);
        break;
      case ActionTypeEnum.Failed:
        setToastModel({
          ...toastModel,
          variant: "danger",
          show: true,
        });
        setIsRefreshItemList(true);
        setDeletedItem(null);
        setShowDeleteUserModel(false);
        break;
    }
  };
  const deleteItemAction: (id: number) => ActionButtonsModel[] = (
    id: number
  ) => {
    return [
      {
        text: getLabelName("yes"),
        onClick: async () => {
          setModalLoading(true);
          const result = await deleteItem(id);
          setModalLoading(false);
          await handleAction({
            id: 0,
            action: result.Result?.Result
              ? ActionTypeEnum.Success
              : ActionTypeEnum.Failed,
          });
        },
      },
      {
        text: getLabelName("no"),
        onClick: () => {
          setDeletedItem(null);
          setShowDeleteUserModel(false);
        },
      },
    ];
  };
  //#endregion
  //#region html
  return (
    <>
      {<ErrorValidationBox errors={validationErrors} displayType={ErrorDisplayTypeEnum.Toastify} />}
      <div ref={pageRef} />
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <ConfirmModelDialogBox
        isModelVisible={showDeleteUserModel}
        onCloseEvent={() => {
          setShowDeleteUserModel(false);
          setDeletedItem(null);
        }}
        isLoading={isModalLoading}
        actions={deleteItemAction(deletedItem?.ID as number)}
      >
        <div className="alert alert-warning">
          {getLabelName("Are you sure you want delete " + itemName)}
        </div>
      </ConfirmModelDialogBox>
      <RegisterItem
        request={object}
        setIsRefresh={setIsRefreshItem}
        isRefresh={isRefreshItem}
        onActionEvent={async (o: RequestActionModel) => {
          await handleAction(o);
        }}
        isFormLoading={isFormLoading}
        setFormLoading={setFormLoading}
      />
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body className="TUEFO-header">
            <ItemList
              onActionEvent={async (o: RequestActionModel) => {
                await handleAction(o);
              }}
              setIsRefresh={setIsRefreshItemList}
              isRefresh={isRefreshItemList}
              isTableLoading={isTableLoading}
              setTableLoading={setTableLoading}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
