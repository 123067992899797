import {useFormik} from "formik";
import * as Yup from "yup";
import {FC, useState} from "react";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import {Col, Form, Row} from "react-bootstrap";
import {saveUserSettings} from "../../serviceBroker/userApiServiceBroker";
import {ButtonBox, ErrorValidationBox, TextBox, LoadingBox} from "..";
import {ActionTypeEnum, RowStateEnum, UserSettingModel, ValidationErrorModel} from "../../models";

export const UserSettings: FC<{
    userSettingModel: UserSettingModel;
    onActionEvent?: any | null;
}> = ({
          userSettingModel,
          onActionEvent = () => {},
      }) => {
    //#region variables
    const initialValues: UserSettingModel = userSettingModel;
    const isArabicLanguage = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [isArabic, setIsArabic] = useState(userSettingModel?.Lang == 1);
    const [isEnglish, setIsEnglish] = useState(userSettingModel?.Lang == 2);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [validationSchema] = useState(Yup.object({}));
    //#endregion
    //#region function
    const handleRadioChange = async (e: any) => {
        if (e.target.name == "arabic") {
            setIsArabic(e.target.checked);
            setIsEnglish(!e.target.checked);
        } else {
            setIsEnglish(e.target.checked);
            setIsArabic(!e.target.checked);
        }
    };
    const handleReset = async () => {
        setValidationErrors([]);
        onActionEvent({
            action: ActionTypeEnum.CloseModelPopup,
        });
    };
    const handleSubmit = async (request: UserSettingModel) => {
        try {
            // setLoading(true);
            request.Lang = isArabic ? 1 : 2;
            request.rowState = Number(RowStateEnum.Update);
            const res = await saveUserSettings(request);
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                onActionEvent({
                    id: ActionTypeEnum.UserSetting,
                    action: ActionTypeEnum.Failed,
                    request: isArabicLanguage ? res?.Errors[0].MessageAr : res?.Errors[0].MessageEn,
                    requestBeforeError: request,
                });
                setLoading(false);
            } else {
                setValidationErrors([]);
                onActionEvent({
                    id: ActionTypeEnum.UserSetting,
                    action: ActionTypeEnum.AddSuccess,
                    request: request,
                });
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#en
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        // onReset: (values) => {
        //   onComplete(false);
        // },
        onSubmit: async (values, {resetForm}) => {
            await handleSubmit(values);
            resetForm();
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form onSubmit={formik.handleSubmit}>
                <div
                    className="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
                    <TextBox
                        labelName={getLabelName("Customer Did Not Pay Since")}
                        inputName={"AlertOfCustomerDidintPaySince"}
                        errorText={formik.errors.AlertOfCustomerDidintPaySince}
                        inputValue={formik.values.AlertOfCustomerDidintPaySince}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                    <TextBox
                        labelName={getLabelName("Days Before Expiry Date")}
                        inputName={"DaysAlertBeforeExpire"}
                        errorText={formik.errors.DaysAlertBeforeExpire}
                        inputValue={formik.values.DaysAlertBeforeExpire}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                    <TextBox
                        labelName={getLabelName("Max Discount Percentage")}
                        inputName={"MaxDiscountPercentage"}
                        errorText={formik.errors.MaxDiscountPercentage}
                        inputValue={formik.values.MaxDiscountPercentage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                    <TextBox
                        labelName={getLabelName("Period allow To Edit")}
                        inputName={"PeriodAllowToEdit"}
                        errorText={formik.errors.PeriodAllowToEdit}
                        inputValue={formik.values.PeriodAllowToEdit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                </div>
                <Row>
                    <Col style={{paddingInline: "32px"}}>
                        <Form.Label>{getLabelName("Language")}</Form.Label>
                        <div className="btn-group btn-group-check mx-6" role="group">
                            <Form.Check
                                inline
                                name="arabic"
                                type="radio"
                                onChange={(e) => handleRadioChange(e)}
                                id="Lang"
                                checked={isArabic}
                                label={getLabelName("Arabic")}
                            />
                            <Form.Check
                                inline
                                name="english"
                                type="radio"
                                onChange={(e) => handleRadioChange(e)}
                                id="english"
                                checked={isEnglish}
                                label={getLabelName("English")}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="AddItemInNewLineInBill"
                                    name="AddItemInNewLineInBill"
                                    checked={formik.values.AddItemInNewLineInBill}
                                    label={getLabelName("Add Repeated Item In Bill In New Line")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="AllowItemPriceSaleLessThanCost"
                                    checked={formik.values.AllowItemPriceSaleLessThanCost}
                                    name="AllowItemPriceSaleLessThanCost"
                                    label={getLabelName("AllowTo Set Sales Item Less Than Cost")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="AllowSaleLessThanCost"
                                    name="AllowSaleLessThanCost"
                                    checked={formik.values.AllowSaleLessThanCost}
                                    label={getLabelName("Allow To Sale Less Than Cost")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="AlowRepeateFactoItemUnit"
                                    checked={formik.values.AlowRepeateFactoItemUnit}
                                    name="AlowRepeateFactoItemUnit"
                                    label={getLabelName("Allow Repeat Item Unit Factor")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="CheckMaxDebitOfCustomer"
                                    name="CheckMaxDebitOfCustomer"
                                    checked={formik.values.CheckMaxDebitOfCustomer}
                                    label={getLabelName("Check Max Debit of Customer")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableManualInvoiceCode"
                                    checked={formik.values.EnableManualInvoiceCode}
                                    name="EnableManualInvoiceCode"
                                    label={getLabelName("Enable Manual Code In Bill")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="PreventStoreOutOfItemLessZero"
                                    name="PreventStoreOutOfItemLessZero"
                                    checked={formik.values.PreventStoreOutOfItemLessZero}
                                    label={getLabelName(
                                        "Prevent Store Out Item Balance Less Zero"
                                    )}
                                />
                            </li>
                        </ul>
                    </Col>
                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="PrintBarcodeAfterPurches"
                                    checked={formik.values.PrintBarcodeAfterPurches}
                                    name="PrintBarcodeAfterPurches"
                                    label={getLabelName(
                                        "Print Barcode of item In Purchases Invoice After Save It"
                                    )}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowAlert"
                                    checked={formik.values.ShowAlert}
                                    name="ShowAlert"
                                    label={getLabelName("Show Alert Window")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowItemModifiers"
                                    checked={formik.values.ShowItemModifiers}
                                    name="ShowItemModifiers"
                                    label={getLabelName("Show Modifiers of Items")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="RequestDueDateOnDelayPayment"
                                    name="RequestDueDateOnDelayPayment"
                                    checked={formik.values.RequestDueDateOnDelayPayment}
                                    label={getLabelName("Request Due Date on Payment Delay")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="RigesterBillOnPayment"
                                    checked={formik.values.RigesterBillOnPayment}
                                    name="RigesterBillOnPayment"
                                    label={getLabelName("Register Sales and Purchase in Payment")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="SelectionEmployeeIsRequierd"
                                    name="SelectionEmployeeIsRequierd"
                                    checked={formik.values.SelectionEmployeeIsRequierd}
                                    label={getLabelName("Selection Of Employee Is Required")}
                                />
                            </li>
                        </ul>
                    </Col>
                </Row>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                    <ButtonBox type="submit" size="sm" variant="primary" className="mx-3" iconType="content-save">
                        {getLabelName("save")}
                    </ButtonBox>
                    <ButtonBox
                    iconType="close-circle"
                        variant="danger"
                        size="sm"
                        type="button"
                        onClick={handleReset}
                    >
                        {getLabelName("close")}
                    </ButtonBox>
                </div>
            </form>
        </>
    );
    //#endregion
};
