import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {
    AcBeginBalanceTransactionDetailModel,
    AcBeginBalanceTransactionDetailRegisterModel,
    AcTransactionModel,
    AcTransactionDeleteResponseModel,
    AcTransactionDetailModel,
    AcTransactionDetailsResponseModel,
    AcTransactionRequestDetailModel,
    AcTransactionSearchModel,
    AcTransactionTypeEnum,
    DailyBoxDetailsResponseModel,
    DailyBoxPayloadModel,
    GenericDeleteResponseModel,
    ResponseBaseModel,
    SearchAcTransactionRequestModel,
    SearchRequestPayloadModel,
    VoucherPayloadModel
} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {getUserId, handleError} from "../utils";
//#region get
export const getAcTransactionBeginBalanceList = async (): Promise<AcBeginBalanceTransactionDetailModel[]> => {
    try {
        const result: ResponseBaseModel<AcBeginBalanceTransactionDetailModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionController.getAcTransactionBeginBalanceList);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(handleError(err));
    }
    return [];
};
export const searchAcTransactionTreasury = async (request: SearchAcTransactionRequestModel): Promise<ResponseBaseModel<AcTransactionSearchModel[]>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionController.searchAcTransactionTreasury, {params: AxiosSearchParams(request, 'searchItem')});
};
export const selectAcTransaction = async (id: number): Promise<ResponseBaseModel<AcTransactionModel>> => {
    const url: string = `${AlYuserApiUrls.acTransactionController.selectAcTransaction}?id=${id}`;
    return await AlYusrAxiosApiInstance.get(url);
}
export const searchAccountTransaction = async (
    params: SearchRequestPayloadModel,
    transactionType?: AcTransactionTypeEnum
): Promise<ResponseBaseModel<AcTransactionSearchModel[]> | null> => {
    const response: ResponseBaseModel<AcTransactionSearchModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionController.searchAccountTransaction, {
            params: {...params, AcTransactionType: transactionType},
        });
    if (response.Errors && response.Errors.length !== 0) throw response.Errors;
    return response || null;
};
export const getAcTransactionFullDetailById = async (
    id: string
): Promise<
    AcTransactionDetailsResponseModel<AcTransactionDetailModel> | undefined
> => {
    try {
        const response: ResponseBaseModel<
            AcTransactionDetailsResponseModel<AcTransactionDetailModel>
        > = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionController.getAcTransactionFullDetailById, {
            params: {acTransactionId: id},
        });
        //if (response.Errors && response.Errors.length !== 0) throw response.Errors;
        return response.Result;
    } catch (err) {
        alert(handleError(err));
    }
};
export const getAcDailyBoxDetailsById = async (
    id: string
): Promise<DailyBoxDetailsResponseModel | undefined> => {
    try {
        const response: ResponseBaseModel<DailyBoxDetailsResponseModel> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionController.getAcDailyBoxDetailsById, {
                params: {acTransactionId: id},
            });
        //if (response.Errors && response.Errors.length !== 0) throw response.Errors;
        return response.Result;
    } catch (err) {
        alert(handleError(err));
    }
};
//#endregion
//#region post
export const saveBeginBalanceAcTransaction = async (
    request: AcBeginBalanceTransactionDetailRegisterModel
): Promise<ResponseBaseModel<AcBeginBalanceTransactionDetailRegisterModel>> => {
    let apiResponse: ResponseBaseModel<AcBeginBalanceTransactionDetailRegisterModel> = {}
    try {
        let url: string = `/AcTrasnaction/SaveBeginBalanceForAccount`;
        apiResponse = await AlYusrAxiosApiInstance.post(url, request);
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const SaveAcTransaction = async (request: AcTransactionModel): Promise<ResponseBaseModel<AcTransactionModel>> => {
    return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.acTransactionController.saveBeginBalanceAcTransaction, request)
}
export const deleteAcTransaction = async (
    id: number, userId: number
): Promise<AcTransactionDeleteResponseModel> => {
    let apiResponse: AcTransactionDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        let url: string = `${AlYuserApiUrls.acTransactionController.deleteAcTransaction}?id=${id}&userId=${userId}`;
        apiResponse = await AlYusrAxiosApiInstance.post(url);
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
}
export const saveCurrentVoucher = async (
    voucher: VoucherPayloadModel<AcTransactionRequestDetailModel>
) => {
    try {
        return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.acTransactionController.saveCurrentVoucher, voucher);
    } catch (err) {
        alert(handleError(err));
    }
};
export const saveCurrentDailyBox = async (voucher: DailyBoxPayloadModel) => {
    try {
        return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.acTransactionController.saveCurrentDailyBox, voucher);
    } catch (err) {
        alert(handleError(err));
    }
};
export const deleteBeginBalanceAcTransaction = async (id: number): Promise<ResponseBaseModel<GenericDeleteResponseModel>> => {
    let apiResponse: ResponseBaseModel<GenericDeleteResponseModel> = {};
    try {
        let url: string = `/DeleteAcTransactionDetail?id=${id}&userId=${getUserId()}`;
        apiResponse = await AlYusrAxiosApiInstance.post(url);
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
//#endregion



