import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    AuthenticateUserPermissionRequestModel,
    AuthenticateUserRequestModel,
    AuthenticateUserResponseModel,
    DashboardSummaryResponseModel,
    MenuModel,
    PermissionModel,
    PosUserPermissionResponseModel,
    POSUserSettingModel,
    POSUserSettingResponseModel,
    PrivilegeModel,
    ResponseBaseModel, UserAlertResponseModel, UserBranchPermissionsModel,
    UserDeleteResponseModel,
    UserPermissionModel,
    UserRegistrationResponseModel,
    UserSettingModel,
    UserSettingResponseModel,
    UserTransactionSettingModel,
    UserTransactionSettingResponseModel,
    UserTransactionsSettingResponseModel,
} from "../models";
import {getUserId, handleError, SecureLocalStorageGet, SecureLocalStorageSet,} from "../utils";
import {SystemConfiguration} from "../configuration";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getUserBranchPermissions = async (userId:number) => {
    const result: ResponseBaseModel<UserBranchPermissionsModel[]> =
        await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.userController.getUserBranchPermissions}?userId=${userId}`);
    return result !== null && result !== undefined &&result.Result !== null &&
    result.Result !== undefined &&
    result.Result.length !== 0? result.Result : [];
};
export const getUserDashboardSummary = async (): Promise<DashboardSummaryResponseModel> => {
    const apiResponse: ResponseBaseModel<DashboardSummaryResponseModel> = await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.userController.getUserDashboardSummary}?userId=${getUserId()}`);
    return apiResponse !== null && apiResponse.Result !== null && apiResponse.Result !== undefined ? apiResponse.Result : {
        NetSales: null,
        CountSales: null,
        ProfitSales: null,
        NetReturnSales: null,
        CountReturnSales: null,
        NetPurchases: null,
        CountPurchases: null,
        NetReturnPurchases: null,
        CountReturnPurchases: null
    };
}
export const getUserAlert = async (): Promise<UserAlertResponseModel[]> => {
    const apiResponse: ResponseBaseModel<UserAlertResponseModel[]> = await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.userController.getUserAlert}?userId=${getUserId()}`);
    return apiResponse !== null && apiResponse.Result !== null && apiResponse.Result !== undefined && apiResponse.Result.length !== 0 ? apiResponse.Result : [];
}
export const authenticateUserLogin = async (
    request: AuthenticateUserRequestModel
): Promise<AuthenticateUserResponseModel> => {
    let apiResponse: AuthenticateUserResponseModel = {
        isAuthenticated: false,
        isLoading: false,
    };
    try {
        let url: string = `${AlYuserApiUrls.userController.validateLogin}?userName=${request.userName}&password=${request.password}`;
        apiResponse = await AlYusrAxiosApiInstance.get(url);
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const getUsers = async (): Promise<UserRegistrationResponseModel[]> => {
    try {
        const result: ResponseBaseModel<UserRegistrationResponseModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.userController.getUsersList);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(handleError(err));
    }
    return [];
};
export const getUserInformation = async (
    id: number
): Promise<UserRegistrationResponseModel | null> => {
    try {
        const result: ResponseBaseModel<UserRegistrationResponseModel> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.userController.getUserById}?userId=${id}`);
        return result !== null &&
        result !== undefined &&
        result.Result !== null &&
        result.Result !== undefined
            ? result.Result
            : null;
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const getUserPermission = async (
    id: number,
    isArabic: boolean
): Promise<PermissionModel[]> => {
    try {
        const result: ResponseBaseModel<UserPermissionModel> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.userController.getUserPermission}?userID=${id}&lang=${isArabic ? 1 : 2}`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(handleError(err));
    }
    return [];
};
export const getUserSetting = async (
    id: number
): Promise<UserSettingModel | null> => {
    try {
        const result: ResponseBaseModel<UserSettingResponseModel> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.userController.getUserSettings}?userID=${id}`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        return null;
    }
};
export const getUserPrivileges = async (
    id: number
): Promise<PrivilegeModel[]> => {
    const data = await getUserPermission(id, true);
    if (data !== null && data !== undefined && data.length !== 0) {
        return data.map((rawData) => ({
            id: rawData.IDForm,
            url: rawData.WebUrl?.toLowerCase(),
            EnableSave: rawData.EnableSave,
            EnableDelete: rawData.EnableDelete,
            EnableUpdate: rawData.EnableUpdate,
            EnableSearch: rawData.EnableSearch,
            ColumnsSettingId: rawData.ColumnsSettingId,
            view:
                rawData.EnableSave ||
                rawData.EnableDelete ||
                rawData.EnableUpdate ||
                rawData.EnableSearch,
        }));
    }
    return [];
};
export const getUserMenu = async (userId: number): Promise<MenuModel[]> => {
    try {
        const result: ResponseBaseModel<MenuModel[]> = await AlYusrAxiosApiInstance.get(
            `${AlYuserApiUrls.userController.loadMenuByUserId}?userId=${userId}`
        );
        // @ts-ignore
        let response: MenuModel[] = [];
        if (result !== null && result !== undefined) {
            result.Result?.map((row) => {
                response.push(mapMenuItem(row));
            });
        }
        return response;
        // return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(handleError(err));
    }
    return [];
};
export const getTransactionSettings = async (
    id: number,
    transactionTypeId: number
): Promise<UserTransactionSettingModel | null> => {
    try {
        let url: string = `${AlYuserApiUrls.userController.getTransactionSettings}?userID=${id}&transactionTypeId=${transactionTypeId}`;
        const result: ResponseBaseModel<UserTransactionSettingResponseModel> =
            await AlYusrAxiosApiInstance.get(url);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        return null;
    }
};
export const getSignedUserPermissions = async (): Promise<
    PermissionModel[] | null
> => {
    try {
        const userId = getUserId();
        if (!userId) return null;
        const response: ResponseBaseModel<PermissionModel[]> =
            await AlYusrAxiosApiInstance.get(
                `${AlYuserApiUrls.userController.getUserPermission}?userID=${userId}`
            );
        if (!response.Result) return null;
        return response.Result;
    } catch (error) {
        return null;
    }
};
export const getPointOfSaleSetting = async (
    id: number,
    isCached: boolean = false,
): Promise<POSUserSettingModel> => {
    const cacheKey: string = SystemConfiguration.cacheKey.userPosSettings;
    const initialValue: POSUserSettingModel = {
        AllowClickNew: false,
        AllowDeleteItems: false,
        AllowReturnWithoutBill: false,
        AllowReturn: false,
        Ask_MoneyBox_Station: false,
        HasPermToDiscount: false,
        HasPermToEditPrice: false,
        CreatedBy: 0,
        CreationDate: new Date(),
        Currency_ID: 0,
        Customer_ID: 0,
        DefaultDiscountType: 0,
        DefaultNoteType: 0,
        DefaultRatioDiscount: 0,
        Emp_ID: 0,
        EnableChangeCalcType: false,
        EnableCloseDay: false,
        EnablePrintSaleReportAndPrintCloseDay: false,
        EnablePrintSavedTransaction: false,
        ID: 0,
        ModificationDate: new Date(),
        ModifiedBy: 0,
        PortName: "",
        PrintInvoiceInA4: false,
        ShowItemForSecondScreenCustomerDisplay: false,
        Store_ID: 0,
        UseCustomerDisplay: false,
        UseItemImageAsBackGround: false,
        User_ID: undefined,
        VerifyOnUpdate: false,
        WelcomeMessage: "",
        rowState: 0
    };
    let cachedResponse: POSUserSettingModel | null = null;
    try {
        if (isCached) {
            const cachedResponse =
                getLocalStorageCachedData<POSUserSettingModel>(cacheKey);
            if (
                cachedResponse !== null &&
                cachedResponse !== undefined
            ) {
                return cachedResponse;
            }
        }
        if (!isCached || cachedResponse === null) {
            let url: string = `${AlYuserApiUrls.userController.getUserPosSettings}?userID=${id}`;
            let apiResponse: ResponseBaseModel<POSUserSettingModel> = await AlYusrAxiosApiInstance.get(url);
            if (
                apiResponse !== null &&
                apiResponse !== undefined &&
                apiResponse.Result !== null &&
                apiResponse.Result !== undefined
            ) {
                SecureLocalStorageSet(cacheKey, JSON.stringify(apiResponse.Result));
                return apiResponse.Result;
            }
        }
    } catch (err) {
        return initialValue;
    }
    return initialValue;
};
export const getUserTransactionsSettings = async (
    transactionTypeId: number[],
    isCached: boolean,
    cacheKey: string
): Promise<UserTransactionsSettingResponseModel[]> => {
    let apiResponse: ResponseBaseModel<UserTransactionsSettingResponseModel[]> = {};

    try {
        if (isCached) {
            const cachedResponse =
                getLocalStorageCachedData<UserTransactionsSettingResponseModel[]>(cacheKey);
                
                if (
                    cachedResponse !== null &&
                    cachedResponse !== undefined &&
                    cachedResponse.length !== 0
                ) {
                    apiResponse.Result = cachedResponse;
                }
            }
            if (
                apiResponse.Result === null ||
                apiResponse.Result === undefined ||
                apiResponse.Result.length === 0
            ) {
                let url: string = `${AlYuserApiUrls.userController.getUserTransactionsSettings}?userID=${getUserId()}`;
            transactionTypeId.forEach((row) => {
                url = `${url}&transactionTypeIds=${row}`;
            });
            apiResponse = await AlYusrAxiosApiInstance.get(url);
            if (
                apiResponse !== null &&
                apiResponse !== undefined &&
                apiResponse.Result !== null &&
                apiResponse.Result !== undefined &&
                apiResponse.Result.length !== 0
            ) {
                SecureLocalStorageSet(cacheKey, JSON.stringify(apiResponse.Result));
            }
        }

        return apiResponse.Result || [];
    } catch (err) {
        return [];
    }
};
export const getUserPOSPermission = async (request: AuthenticateUserPermissionRequestModel)
    : Promise<PosUserPermissionResponseModel | null> => {
    try {
        const url = `${AlYuserApiUrls.userController.getUserPOSPermission}?username=${request.userName}&password=${request.password}&transactionType_ID=${Number(request.transactionType)}`;
        const result: ResponseBaseModel<PosUserPermissionResponseModel> = await AlYusrAxiosApiInstance.get(url);
        // @ts-ignore
        return result?.Result ?? null;
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
//#endregion
//#region post
export const savePointOfSaleSettings = async (
    request: POSUserSettingModel
): Promise<ResponseBaseModel<POSUserSettingResponseModel>> => {
    let apiResponse: ResponseBaseModel<POSUserSettingResponseModel> = {};
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.userController.saveUserPosSettings, request);
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const deleteUser = async (id: number): Promise<UserDeleteResponseModel> => {
    let apiResponse: UserDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.userController.deleteUser}?id=${id}`);
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const registerUser = async (
    request: UserRegistrationResponseModel
): Promise<UserRegistrationResponseModel> => {
    let apiResponse: UserRegistrationResponseModel = {
        User_Name: "",
        Name_En: "",
        Name: "",
        Password: "",
        IsAdmin: false,
        JWT: undefined,
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Errors: [],
        rowState: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.userController.saveUser, {...request});
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const saveUserPermissions = async (
    request: PermissionModel[]
): Promise<PermissionModel[]> => {
    let apiResponse: PermissionModel[] = [];
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.userController.saveUserPermissions, request);
        return apiResponse;
    } catch (err) {
        alert(handleError(err));
    }
    return apiResponse;
};
export const saveUserSettings = async (
    request: UserSettingModel
): Promise<ResponseBaseModel<UserSettingResponseModel> | null> => {
    try {
        return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.userController.saveUserSettings, request);
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const saveTransactionSettings = async (
    request: UserTransactionSettingModel
): Promise<ResponseBaseModel<UserTransactionSettingResponseModel> | null> => {
    try {
        return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.userController.saveTransactionSettings, request);
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
//#endregion
//#region private
const mapMenuItem = (request: MenuModel): MenuModel => {
    let item: MenuModel = {
        ID: request.ID,
        ArabicName: request.ArabicName,
        ActionColumnsStyle:request.ActionColumnsStyle,
        Name: request.Name,
        WebUrl: request.WebUrl,
        ChildBusinessObject: [],
    };
    if (
        request.ChildBusinessObject !== null &&
        request.ChildBusinessObject.length !== 0
    ) {
        request.ChildBusinessObject.map((row) => {
            item.ChildBusinessObject.push(mapMenuItem(row));
        });
    }
    return item;
};

function getLocalStorageCachedData<T>(cacheKey: string): T | null {
    let response: T;
    const data = SecureLocalStorageGet(cacheKey);
    if (data != null) {
        response = JSON.parse(data as string) as T;
        return response;
    } else {
        return null;
    }
}

//#endregion
