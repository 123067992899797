export enum ErrorDisplayTypeEnum{
    None=0,
    Default=1,
    Flat=2,
    ModelPopup=3,
    Toast=4,
    Toastify=5,
}
export enum TableActionColumnsStyleEnum{
    Button=1,
    Dropdown=2,
    DropdownIcon=3,
    DropDownText=4,
}
export enum LoadingObjectsEnum {
    FORM = "FORM",
    LIST = "LIST",
    MODAL = "MODAL",
}
export enum PosTransactionsOptionsModifyTypeEnum {
    None = 0,
    Discount = 1,
    DiscountPercentage = 2,
    Notes = 3,
    CalculationType = 4,
}
export enum InputDatePickerTimeDisplayModeEnum {
    None = 0,
    TimeSelect = 1,
    TimeInput = 2,
}
export enum PaymentTypeEnum {
    None = 0,
    Cash = 1,
    Credit = 2,
    Delay = 3,
    Multiple = 4,
    Others = 5,
    InstallmentPlan = 6,
    OnlinePayment = 7,
}
export enum PageModeEnum {
    Add = "X9HDZ5Sx6PgtT915aWpZ",
    Edit = "svsdQIDcxgqj6jZovYHh",
    View = "WdsaCA1t89Y8n0s6SKQF",
    Mode1 = "TVMHVhNPedUnPWFoxM55",
    Mode2 = "uzgV0zkpPhjgFlchwye7",
    Mode3 = "jqJPmOmEagMu6LwfW1LU",
    Mode4 = "9K0kjY8Foe0pP6JPPB2Z",
    Mode5 = "2yDHktjkifjv0suwRnFj",
    Mode6 = "sLRmg3PDsVoGPHxG3fou",
    Mode7 = "6Tj8ZT0pF85oyWalylsv",
    Mode8 = "4rPxha0mbNkf6JoFqGhh",
    Mode9 = "gvITU6gNYQ5IybAOZulL",
    Mode10 = "jvnq34BTMHBGwzHMmswW",
}
export enum CalcTypeEnum {
    None = 0,
    CalcType = 1,
    ApplicationDelivery = 2,
}
export enum AccountAcBeginBalanceTypeEnum {
    None = 0,
    CreditAccount = 1,
    DebitAccount = 2,
}
export enum PrintTypeEnum {
    None = 0,
    Invoice = 1,
    AcTransaction = 2,
    CloseDay = 3,
    JournalVoucher = 4,
    AcDailyBox = 5,
    UserAlertReport = 6,
    MoveItemsBetweenStores=7,
}
export enum CloseDayTypeEnum {
  None = 0,
  CloseDay = 1,
}
export enum ChangeItemValueTypeEnum {
    None = 0,
    ChangePrice = 1,
    ChangePriceInCurrency = 2,
    ChangePriceWithTax = 3,
    ChangePriceWithTaxInCurrency = 4,
    ChangeDiscount = 5,
    ChangeDiscountInCurrency = 6,
    All = 99,
}
export enum TransactionDiscountLevelTypeEnum {
    None = 0,
    TransactionLevel = 1,
    ItemLevel = 2,
}
export enum CustomerComponentDisplayModeEnum {
    defaultMode = 0,
    registerTransactionMode = 1,
}
export enum PriceTypeEnum {
    None = -1,
    DefaultPrice = 0,
    PriceSale = 1,
    PriceCost = 2,
    PricePurchase = 3,
    WholeSalePrice = 4,
    AdditionalPrice = 5,
}
export enum ValidateTransactionModeEnum {
    None = 0,
    Modify = 1,
    Delete = 2,
    Return = 3,
}
export enum AccrualSubtractRuleTypeEnum {
    Accrual = 1,
    Subtract = 2,
}
export enum DailyTransactionTypeEnum {
    None = 0,
    SalesInvoice = 1,
    PurchasesInvoice = 2,
    SalesRefund = 3,
    PurchasesRefund = 4,
    Order = 5,
    IncrementFromInventoryCheck = 6,
    DecreaseFromInventoryCheck = 7,
    POSSales = 8,
    POSSalesRefund = 9,
    MoveToStore = 10,
    ReceiveFromStore = 11,
    POSRestaurant = 12,
    ReturnPOSRestaurant = 13,
    OutForProduction = 14,
    ProductionTransaction = 15,
    ExtractAndDistribute = 16,
    ShowPrice = 17,
    Reserve_Restaurant_Bill = 18,
    Cancel_Reserve_Restaurant_Bill = 19,
    Consign_In = 20,
    Consign_Out = 21,
    Shipment = 22,
    CarRegistration = 23,
    Notice_Receive = 24,
    Examination_Inspection_Report = 25,
    Received_Note = 26,
}
export enum PermissionTypeEnum {
    None = 0,
    Save = 1,
    Update = 2,
    Delete = 3,
    Search = 4,
    Tablet = 5,
    All,
}
export enum PageEnum {
    none = 0,
    Users = 1,
    UsersPermission = 2,
    SalesInvoice = 3,
    PurchaseBill = 4,
    ReturnSalesBill = 5,
    ReturnPurchasesBill = 6,
    Customers = 7,
    Suppliers = 8,
    Items = 9,
    Units = 10,
    Stores = 11,
    Categories = 12,
    CustomersPayments = 13,
    SuppliersPayments = 14,
    RefundCustomersPayment = 15,
    RefundSuppliersPayment = 16,
    ItemsReachedToLimitOrder = 17,
    ReportItemsData = 18,
    ReportFollowItemsBalance = 19,
    CompanySettings = 20,
    CopyItemToStore = 21,
    Cities = 22,
    OrderStatusSummery = 23,
    BanKIncome = 24,
    ReportCustomersData = 25,
    ReportSuppliersData = 26,
    ReportCustomersPayments = 27,
    ReportSuppliersPayment = 28,
    ReportReturnCustomersPayment = 29,
    ReportReturnSupplierPayment = 30,
    Countries = 31,
    InventoryCheck = 32,
    ReportSales = 33,
    ReportPurchases = 34,
    ReportReturnSales = 35,
    ReportReturnPurchases = 36,
    ReportAllItemsTransaction = 37,
    ReportInventoryCheck = 38,
    Employees = 39,
    CalcType = 40,
    CostCenters = 41,
    Requests = 42,
    InvoiceTypes = 43,
    ReportOrder = 45,
    ReportReceiveConsign = 46,
    ReportDeliverConsign = 47,
    BankOutCome = 48,
    RuleExport = 50,
    RuleReceive = 51,
    ReportAdditionAndSubtractsOfEmp = 52,
    ReportInAndOutMoney = 53,
    Dictionary = 54,
    moneyIn = 55,
    moneyOut = 56,
    AdditionRules = 57,
    RuleSubtract = 58,
    employeeDue = 59,
    addSubtract = 60,
    POS = 61,
    ReportPOS = 63,
    ReportReturnPOS = 64,
    ReportAllPOS = 65,
    ReportInMoney = 66,
    ReportOutMoney = 67,
    MoveItemToStore = 68,
    Branches = 69,
    ReportMoveItemToStore = 70,
    ReportItemWithQuantity = 71,
    frmReportItemsBalanceWithValue = 73,
    frmReportACTransactionLog = 74,
    POSStation = 75,
    ReportStationOfPOS = 76,
    BarcodeCreation = 77,
    frmReportPosTransaction = 78,
    Currency = 79,
    AllInAndOutMoneyInSystem = 80,
    ReportAllCustomersBalance = 81,
    ReportAllSuppliersBalance = 83,
    ReportFollowCustomersInTransaction = 84,
    ReportFollowSuppliersInTransaction = 85,
    ReportProfitAndLose = 86,
    Tables = 87,
    AdditionalRecommendOfItemsInRestaurant = 88,
    ReportSummeryItemsTransaction = 89,
    POSRestaurant = 90,
    ReportPOSRestaurant = 91,
    ReportReturnPOSRestaurant = 92,
    ReportExpiryDateOfItems = 93,
    ReportAllInAndOutDayMoney = 94,
    AccountsTree = 95,
    ReportAccountOfCustomer = 96,
    ReportAccountOfSupplier = 97,
    SimpleJournalVoucher = 98,
    ReportSimpleJournalVoucher = 99,
    ReportPaymentAccounts = 100,
    JournalVoucher = 101,
    ReportComplexJournalVoucher = 102,
    ACBeginBalance = 103,
    IncomeStatement = 104,
    TrialBalance = 105,
    BalanceSheet = 106,
    ReportAccountsTransaction = 107,
    DailyBox = 108,
    ReportMoneyTransactionByStore = 109,
    ReportProfitOfSales = 110,
    Production = 111,
    ReportProductionOfItems = 112,
    FinancialYear = 113,
    ManageExpiryDateOfItems = 114,
    ExtractAndDistribute = 115,
    ReportExtractAndDistribute = 116,
    BalanceVoucher = 117,
    ReportBalanceVoucher = 118,
    SettingColumnsInGrid = 119,
    ReportOfStagnantItems = 120,
    ReportItemsDifferentInCost = 121,
    ReportHistoryLogin = 122,
    Department = 123,
    Grade = 124,
    Nationalities = 125,
    Religions = 126,
    SocialStatus = 127,
    EmployeeStatus = 128,
    AttendanceSchedule = 129,
    JobPositions = 130,
    OffersSalePrices = 131,
    TableReservation = 132,
    ReportModifyUsersOnItems = 133,
    POSReturnManagement = 134,
    POSRestaurantReturnManagement = 135,
    ReportCustomerDebits = 136,
    ShowPrice = 137,
    ReportEndorsementVAT = 138,
    ReportSupplierDebits = 139,
    ReportSalesAndPurchaseGraph = 140,
    ReportProfitOfSalesGraph = 141,
    ReportVoucherRelease = 142,
    ReportHistoryOfCustomerPoint = 143,
    ReportAllCustomerTransaction = 144,
    ReportProductionItemsData = 145,
    ReportCustomerDueBill = 146,
    Devices = 147,
    ConsignOut = 148,
    ReportAllSuppliersTransactions = 149,
    ConsignIn = 150,
    InstallmentPlan = 151,
    ConfigurationSettings = 152,
    ReportInstallmentPlan = 153,
    Shipment = 154,
    ReportShipmentLogStatus = 155,
    ReportShipmentDealer = 156,
    ReportEmployeeSales = 157,
    CarsType = 158,
    CarsColors = 159,
    CreateVoucher = 160,
    PaymentType = 161,
    POSManagement = 162,
    POSRestaurantManagement = 163,
    CarService = 164,
    CarRegistration = 165,
    ApplicationDelivery = 166,
    ReportBestAndLeastSellingItems = 167,
    ReportProfitOfContracting = 168,
    ImportItems = 169,
    POSRestaurantReservationManagement = 170,
    ReportItemDifferentInVatCalculation = 171,
    PrintingSettings = 172,
    PurchaseViaPOS = 174,
    MortgageItems = 176,
    LinkAccounts = 178,
    ManageInvoices = 180,
    NoticeReceive = 182,
    ExaminationAndInspectionReport = 183,
    ReceivedNote = 184,
    AddTaxPercentageForItems = 195,
    AdditionCosts = 196,
    ReportEmployeeCommissionFromSales = 197,
    HolidConfiguration = 198,
    ReportOfTobaccoTax = 199,
    ReportCarReceiving = 200,
    frmReportTransactionLog = 203,
    defaultPage = 10000,
    homePage = 10001,
    notFoundPage = 10002,
    unAuthorizedPage = 10003,
    forbiddenPage = 10004,
    dashboardPage = 10005,
    logoutRedirectPage = 10006,
    currentBranchPage = 10007,
}
export enum DisplayLabelNameEnum {
    none = 0,
    value = 1180,
    arabicName = 1228,
    englishName = 1229,
    isDefault = 1191,
    itemName = 1195,
    name = 1207,
}
export enum LayoutEnum {
    none = 0,
    defaultAuthenticatedLayout = 1,
    authenticatedWithoutMenuLayout = 2,
}
export enum ActionTypeEnum {
  None = 0,
  Add = 1,
  Update = 2,
  Delete = 3,
  GrantPermissions = 4,
  AddSuccess = 5,
  AddFailed = 6,
  Clear = 7,
  AddSubObjectSuccess = 8,
  AddSubObjectFailed = 9,
  AddSubObjectReset = 10,
  ModifySubObjectSuccess = 11,
  ModifySubObjectFailed = 12,
  ModifySubObjectReset = 13,
  DeleteSubObjectSuccess = 14,
  DeleteSubObjectFailed = 15,
  DeleteSubObjectReset = 16,
  AddSubObject = 17,
  ModifySubObject = 18,
  DeleteSubObject = 19,
  Success = 20,
  Failed = 21,
  DeleteSubObjectComplete = 22,
  DeleteSuccess = 23,
  DeleteOperationStart = 24,
  Print = 25,
  UserSetting = 26,
  RaiseError = 27,
  TransactionSetting = 28,
  POSSetting = 29,
  ModifyStart = 30,
  ModifySuccess = 31,
  ModifyFailed = 32,
  ModifyReset = 33,
  DeleteStart = 34,
  DeleteFailed = 36,
  DeleteReset = 37,
  Save = 38,
  SaveSuccess = 39,
  SaveFailed = 40,
  AddPartialCustomerStart = 41,
  AddPartialCustomerSuccess = 42,
  AddPartialCustomerFailed = 43,
  ClearError = 44,
  PrintPdf = 45,
  Search = 46,
  CloseModelPopup = 47,
  BulkSave = 48,
  BulkUpdate = 49,
  OpenModal = 51,
  AddPaymentValuesSuccess = 50,
  AddPaymentValuesClear = 50,
  TransactionRefundIInitialization = 51,
  TransactionRefund = 52,
  Ignore = 53,
  AddItem = 54,
  ResetObject = 55,
  AddBeneficiary = 56,
  AddBeneficiaryResponse = 57,
  SelectEmployeeRequest = 58,
  SelectEmployeeResponse = 58,
  SelectCustomerRequest = 59,
  SelectCustomerResponse = 60,
  SelectItemResponse = 61,
  UpdatePosOptions = 62,
  UploadFile = 63,
  SaveTransactionInstallmentsPlan = 64,
  GetUserPOSPermission = 65,
  DeleteTransactionItem = 66,
  EditTransactionItemPrice = 67,
  EditTransactionItemDiscount = 68,
  RefundWithoutBill = 69,
  Close = 70,
  ShowCommission = 71,
  EnableLoader = 72,
  DisableLoader=73,
  ChangeFromStoreId =74,
  ChangeToStoreId =75,
  SelectReservedTransaction=76,
  ResetReference=76,
  Refresh=77,
  Modify=78,
  ReserveTable=79,
  CancelReservation = 80,
  UpdateUserPermissions=81,
  CancelUpdateUserPermissions=82,
  CloseDay=83,
  CompanySettingDetails=84,
  Generic = 999,
}
export enum PosUserPermissionActionTypeEnum {
    None = 0,
    RefundAction = 1,
    DiscountAction = 2,
    DiscountPercentageAction = 3,
    PrintAction = 4,
    NewAction = 5,
    DeleteItemAction = 6,
    EditItemPriceAction = 7,
    EditItemDiscountAction = 8,
}
export enum RowStateEnum {
    None = 0,
    Add = 1,
    Update = 2,
    Delete = 3,
    // Refund=4,
}
export enum UserRoleEnum {
    None = 0,
}

export enum LookupFormTypeEnum {
    None = 0,
    Tables = 1,
    Cities = 2,
    AdditionAndRecommendationRestaurant = 3,
    CostCenter = 9,
    Cars_Type = 10,
    Cars_Color = 11,
    Cars_Service = 12,
    Devices = 50,
    OrderStatus = 51,
    Country = 53,
    SocialStatus = 100,
    Religions = 101,
    Nationalities = 102,
    Grade = 103,
    Department = 104,
    Employee_Status = 105,
    PaymentType = 161,
    Mortgage_Items = 176,
    AdditionCosts = 196,
}
export enum BeneficiaryTypeEnum {
    None = 0,
    Customer = 1,
    Supplier = 2,
    Employee = 3,
}
export enum LookupTypeEnum {
    None = 0,
    Users = 1,
    Stores = 2,
    CategoryType = 3,
    Customers = 4,
    Supplier = 5,
    PaymentType = 6,
    CostCenter = 7,
    Employee = 8,
    TransactionType = 9,
    Cities = 10,
    Branches = 11,
    AC_Accounts = 12,
    Units = 13,
    Departments = 14,
    JobPosition = 15,
    CalcType = 16,
    Table = 17,
    PriceType = 18,
    Modifiers = 19,
    Currency = 20,
    Device_Status = 50,
    OrderStatus = 51,
    Stage = 52,
    Country = 53,
    DelayPaymentType = 55,
    /// 54 Account Type (Balance , Income , Trading , Operation  الحساب  ميزانية – قائمة دخل – تشغيل  -متاجرة)
    AccountType = 54,
    CarPaymentType = 56,
    Estehkak = 57,
    Estektaa = 58,
    DefaultDiscountType = 59,
    DefaultNote = 61,
    DiscountType = 59,
    NotesTypes = 61,
    ReportTypes = 62,
    DaysOfWeek = 63,
    /// 57 Old Account Type (Asoul , Khsoum , Income , Out come أصول خصوم ايرادات مصروفات)
    OldAccountType = 75,
    ruleReceiveMoney = 111,
    Cars_Type = 158,
    Cars_Color = 159,
    ApplicationDelivery = 160,
    Cars_Service = 164,
    ItemsOnTable = -170,
    Mortgage_Items = 176,
    ruleExpenseMoney = 222,
    AllAccounts = 901,
    CustomerAccounts = 902,
    SupplierAccounts = 903,
    AllTreasuryInAccount = 904,
    AllTreasuryOutAccount = 905,
    CostCenters = 906,
    AllCategories = 907,
    Items = 908,
    ItemsStoreTransfer  =909,
    ActionLogTypes = 910,
    AcTransactionTypes=911,
    CustomerRefundAccounts = 1001,
    SupplierRefundAccounts = 1002,
    AllMainParentRestaurant = 1003,
    AllMainAccountType = 1004,
    UserBranchPermissions  =1005,
    LogType=1006,
    ItemsPerCategory=9908,
}

export enum LogTypeEnum {
    ADD=1,
    MODIFY=2,
    DELETE=3
}
export enum RedirectTypeEnum {
    None = 0,
    Internal = 1,
    External = 2,
    DirectExternalRedirect = 3,
}
export enum TransactionStatusEnum {
    None = 0,
    Success = 1,
    Failed = 2,
}
export enum DialogStatusEnum {
    None = 0,
    Success = 1,
    Failed = 2,
}
export enum DocumentStatusEnum {
    Add = 1,
    Remove = 2,
    View = 3,
}
export enum TransactionModeEnum {
    View = 0,
    Add = 1,
    Update = 2,
    Cancel = 3,
    Close = 4,
    Rejected = 7,
    Edit = 8,
}
export enum UploadFileTypeEnum {
    None = 0,
    request = 1,
    Measure = 2,
}
export enum SearchItemsTypeEnum {
    None = "0",
    ItemName = "1",
    ItemCode = "2",
    ItemBarCode = "3",
}
export enum SearchDailyTransactionTypeEnum {
    None = 0,
    itemName = 1,
    id = 2,
    code = 3,
    serial = 4,
}
export enum AcTransactionTypeEnum {
    MoneyOut = 1,
    MoneyIn = 2,
    SupplierPayments = 3,
    CustomerPayment = 4,
    RefundSupplierPayments = 5,
    RefundCustomerPayments = 6,
    DailyBox = 13,
}
export enum SupplierCustomerTypeEnum {
    Customer = 1,
    Supplier = 2,
}
export enum RuleExpRecipeAccountsEnum {
    Expense = 1,
    Receive = 2,
}
export enum SearchCustomersTypeEnum {
    None = "0",
    CustomerName = "1",
    CustomerCode = "2",
}
export enum ComponentActionTypeEnum {
    None = 0,
    Add = 1,
    Edit = 2,
    Clear = 3,
    AddCustomer = 4,
    Reset = 5,
    Refund = 7,
}
export enum InvoiceTypeEnum {
    REGULAR = 0,
    TAKEAWAY = 1,
    DINE_IN = 2,
    DELIVERY = 3,
}
