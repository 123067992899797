import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    CompanySettingModel,
    ElectronicBillSettingModel,
    ElectronicBillSettingResponseModel,
    LoyaltySettingModel,
    ResponseBaseModel
} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {handleError} from "../utils";
//#region get
export const getCompanySetting = async (): Promise<CompanySettingModel | null> => {
    try {
        const result: ResponseBaseModel<CompanySettingModel> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.companySettingController.getCompanySetting);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : null;
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const getActiveVouchersSettings = async (): Promise<LoyaltySettingModel | null> => {
    try {
        const result: ResponseBaseModel<LoyaltySettingModel> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.companySettingController.getActiveVouchersSettings);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : null;
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const getEgyptInvoiceSettings = async (): Promise<ElectronicBillSettingModel | null> => {
    try {
        let url: string = `${AlYuserApiUrls.companySettingController.getEgyptInvoiceSettings}?companyId=2`;
        const result: ResponseBaseModel<ElectronicBillSettingResponseModel> =
            await AlYusrAxiosApiInstance.get(url);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : null;
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
//#endregion
//#region post
export const saveCompanySetting = async (
    request: CompanySettingModel
): Promise<ResponseBaseModel<CompanySettingModel> | null> => {
    try {
        // @ts-ignore
        return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.companySettingController.saveCompanySetting, request);
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
export const saveVoucherSettings = async (
    request: LoyaltySettingModel
): Promise<ResponseBaseModel<LoyaltySettingModel> | null> => {
    try {
        // @ts-ignore
        return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.companySettingController.saveVoucherSettings, request);
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};

export const saveEgyptInvoiceSettings = async (
    request: ElectronicBillSettingModel
): Promise<ResponseBaseModel<ElectronicBillSettingModel> | null> => {
    try {
        // @ts-ignore
        return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.companySettingController.saveEgyptInvoiceSettings, request);
    } catch (err) {
        alert(handleError(err));
    }
    return null;
};
//#endregion