import React, { FC } from "react";
import { Form } from "react-bootstrap";
import {
  InputDatePickerTimeDisplayModeEnum,
  LookupItemModel,
  LookupTypeEnum,
  PageEnum,
  ReportUserAcTransactionLogsParamsModel,
  SearchPanelPropsModel,
} from "../../../models";
import { getLabelName } from "../../../utils";
import {
  ButtonBox,
  CheckBox,
  InputDatePicker,
  PrivilegesChecker,
  SelectBox,
  TextBox,
} from "../..";
import {useLookups} from "../../../hooks";

export const ReportUserAcTransactionsLogsSearchPanel: FC<
  SearchPanelPropsModel<ReportUserAcTransactionLogsParamsModel>
> = ({ setLoading, searchParams, setSearchParams, handelSearch }) => {
  //#region state
    const {getLookupItems} = useLookups(setLoading, [
        LookupTypeEnum.Users,
        LookupTypeEnum.ActionLogTypes,
        LookupTypeEnum.AcTransactionTypes,
    ]);
    //#endregion
    //#region functions
    const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
  //#endregion
  //#region html
  return (
    <>
      <Form>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
          <SelectBox
            labelName="Transaction Type"
            isSingleSelect={true}
            selectedValues={
              searchParams.acTransactionTypeId
                ? [searchParams.acTransactionTypeId.toString()]
                : null
            }
            source={getLookupItems(LookupTypeEnum.AcTransactionTypes, true)}
            onStatusChange={(e: LookupItemModel) => {
              const _id= e?.value ? parseInt(e.value) : undefined;
                setSearchParams({
                    ...searchParams,
                    acTransactionTypeId: _id,
                });
            }}
          />
          <SelectBox
            labelName="Log Type"
            isSingleSelect={true}
            selectedValues={
              searchParams.logType ? [searchParams.logType.toString()] : null
            }
            source={getLookupItems(LookupTypeEnum.ActionLogTypes, true)}
            onStatusChange={(e: LookupItemModel) => {
              const _id= e?.value ? parseInt(e.value) : undefined;
              setSearchParams({
                ...searchParams,
                logType: _id,
              });
            }}
          />
          <SelectBox
              labelName="User"
              isSingleSelect={true}
              selectedValues={
                searchParams.userId ? [searchParams.userId.toString()] :null
              }
              source={getLookupItems(LookupTypeEnum.Users, true)}
              onStatusChange={(e: LookupItemModel) => {
                const _id= e?.value ? parseInt(e.value) : undefined;
                setSearchParams({
                  ...searchParams,
                  userId: _id,
                });
              }}
          />
          <InputDatePicker
              isClearable={true}
            className="form-control"
            selectedDate={searchParams.fromDateFormatted? new Date(searchParams.fromDateFormatted):undefined}
            timeMode={InputDatePickerTimeDisplayModeEnum.None}
            InputLabel={"From Date"}
            endDate={searchParams.fromDateFormatted? new Date(searchParams.fromDateFormatted):undefined}
            onChange={(date) => {
              setSearchParams({
                  ...searchParams,
                  fromDate: date ? formatter.format(date!) : null,
                  fromDateFormatted: date
              });
            }}
          />
          <InputDatePicker
            isClearable={true}
            className="form-control"
            selectedDate={searchParams.toDateFormatted? new Date(searchParams.toDateFormatted):undefined}
            timeMode={InputDatePickerTimeDisplayModeEnum.None}
            InputLabel={"To Date"}
            startDate={searchParams.toDateFormatted? new Date(searchParams.toDateFormatted):undefined}
            onChange={(date) => {
              setSearchParams({
                ...searchParams,
                  toDate: date ? formatter.format(date!) : null,
                  toDateFormatted: date
              });
            }}
          />

          <TextBox
            inputValue={searchParams.acTransactionHeadersId}
            inputName="acTransactionHeadersId"
            type={"text"}
            onChange={(e: any) =>
              setSearchParams({
                ...searchParams,
                acTransactionHeadersId: e.target.value,
              })
            }
            labelName={getLabelName("Code")}
            isMandatory={false}
          />
          <CheckBox
            onChange={(e: any) => {
              setSearchParams({ ...searchParams,isSummaryReport: e.target.checked });
            }}
            id="ISCustomerAndSupplier"
            checked={searchParams.isSummaryReport || false}
            inputName="isSummaryReport"
            labelName={getLabelName("isSummaryReport")}
          />
        </div>
        <PrivilegesChecker formId={PageEnum.ReportSales} action="EnableSearch">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
            <ButtonBox
              iconType="magnify"
              className="btn-gradient-primary mx-3 btn-fw"
              variant="outline-primary"
              onClick={handelSearch}
            >
              {getLabelName("Search")}
            </ButtonBox>
          </div>
        </PrivilegesChecker>
      </Form>
    </>
  );
  //#endregion
};
