import React, {FC, useEffect, useState} from "react";
import {
    ActionButtonsModel,
    ActionTypeEnum,
    AcTransactionModel,
    AcTransactionTypeEnum,
    LookupItemModel,
    LookupTypeEnum,
    PrintTypeEnum,
    RequestActionModel,
    ResponseBaseModel,
    ToastModel,
    ValidationErrorModel,
} from "../../models";
import {
    AcTransactionForm,
    AcTransactionList,
    ConfirmModelDialogBox,
    ErrorValidationBox,
    GenericModelPopupPdfReportViewer,
    LoadingBox,
    ModelDialogBox,
    PdfViewer,
    ToastBox,
} from "..";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";
import {
    getLabelName,
    getUserId,
    scrollToDocumentTop,
} from "../../utils";
import {Accordion} from "react-bootstrap";
import {
    getFromAccountIdBasedOnTransactionType,
    getToAccountIdBasedOnTransactionType,
} from "./businessLogic/acTransactionBl";
import {
    deleteAcTransaction,
    selectAcTransaction,
} from "../../serviceBroker/acTransactionsApiServiceBroker";

export const AcTransactionComponent: FC<{
    acTransactionType: AcTransactionTypeEnum;
    formId: number;
}> = ({acTransactionType, formId}) => {
    //#region variables
    const acTransactionInitValue: AcTransactionModel = {
        ID: 0,
        FromAccount_ID: getFromAccountIdBasedOnTransactionType(acTransactionType),
        ToAccount_ID: getToAccountIdBasedOnTransactionType(acTransactionType),
        CostCenter_ID: 0,
        Currency_ID: 0,
        Station_ID: 1,
        Code: "",
        Note: "",
        Value: 0,
        Date: new Date(),
        AcTransactionType: acTransactionType,
        CreatedBy: getUserId(),
        ModifiedBy: 0,
        CreationDate: new Date(),
        ModificationDate: new Date(),
        rowState: 1,
        ValueCurrency: 1,
        isNewRequest: true,
        balance: 0,
    };
    const deleteActions: ActionButtonsModel[] = [
        {
            text: getLabelName("yes"),
            onClick: async () => {
                setLoading(true);
                await deleteAcTransaction(acTransaction?.ID || 0, getUserId());
                setAcTransaction(acTransactionInitValue);
                setIsRefresh(true);
                setShowDeleteModel(false);
                setLoading(false);
            },
        },
        {
            text: getLabelName("No"),
            onClick: () => {
                setShowDeleteModel(false);
            },
        },
    ];
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [toastModel, setToastModel] = useState<ToastModel>({show: false,});
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showPrintModel, setShowPrintModel] = useState({
        show: false,
        content: "",
    });
    const [acTransaction, setAcTransaction] = useState<AcTransactionModel>(
        acTransactionInitValue
    );
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [accountLookups, setAccountLookups] = useState<LookupItemModel[]>([]);
    const [currencyLookUps, setCurrencyLookUps] = useState<LookupItemModel[]>([]);
    const [coastCenterLookUps, setCoastCenterLookUps] = useState<LookupItemModel[]>(
        []
    );
    const [userLookUps, setUserLookUps] = useState<LookupItemModel[]>([]);
    const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
    const [printTransactionId, setPrintTransactionId] = useState(0);
    const [fetchLookupCompleted, setFetchLookupCompleted] = useState(false);
    //#endregion
    //#region use-Effect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            setFetchLookupCompleted(false);
            await getAllLookups();
            setFetchLookupCompleted(true);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region functions
    const getAllLookups = async () => {
        let lookupAccountTransactionType:LookupTypeEnum=LookupTypeEnum.None;
        switch (+acTransactionType) {
            case AcTransactionTypeEnum.MoneyOut:
                lookupAccountTransactionType= LookupTypeEnum.AllTreasuryOutAccount;
                break;
            case AcTransactionTypeEnum.MoneyIn:
                lookupAccountTransactionType= LookupTypeEnum.AllTreasuryInAccount;
                break;
            case AcTransactionTypeEnum.SupplierPayments:
                lookupAccountTransactionType= LookupTypeEnum.SupplierAccounts;
                break;
            case AcTransactionTypeEnum.RefundSupplierPayments:
                lookupAccountTransactionType= LookupTypeEnum.SupplierRefundAccounts;
                break;
            case AcTransactionTypeEnum.CustomerPayment:
                lookupAccountTransactionType= LookupTypeEnum.CustomerAccounts;
                break;
            case AcTransactionTypeEnum.RefundCustomerPayments:
                lookupAccountTransactionType= LookupTypeEnum.CustomerRefundAccounts;
                break;
        }
        const [users, currencies, coastCenters,accounts] = await Promise.all([
            getLookupByType(LookupTypeEnum.Users, true, false),
            getLookupByType(LookupTypeEnum.Currency,true,false),
            getLookupByType(LookupTypeEnum.CostCenters,true,false),
            getLookupByType(lookupAccountTransactionType,true,false),
            ]);
        setAccountLookups(accounts);
        setUserLookUps(users);
        setCurrencyLookUps(currencies);
        setCoastCenterLookUps(coastCenters);
    };
    const handleAction = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.AddSuccess:
            case ActionTypeEnum.DeleteSuccess:
            case ActionTypeEnum.SaveSuccess:
            case ActionTypeEnum.Success:
                scrollToDocumentTop();
                setValidationErrors([]);
                setToastModel({...toastModel, show: true});
                setAcTransaction(acTransactionInitValue);
                setIsRefresh(true);
                //setComponentActionType(ComponentActionTypeEnum.Reset);
                break;
            case ActionTypeEnum.Add:
                setAcTransaction(acTransactionInitValue);
                break;
            case ActionTypeEnum.DeleteStart:
                setAcTransaction(request.request);
                setShowDeleteModel(true);
                break;
            case ActionTypeEnum.DeleteFailed:
                setValidationErrors(request.request);
                setShowDeleteModel(false);
                scrollToDocumentTop();
                break;
            case ActionTypeEnum.DeleteReset:
                setValidationErrors([]);
                setAcTransaction(acTransactionInitValue);
                setShowDeleteModel(false);
                break;
            case ActionTypeEnum.ClearError:
                setValidationErrors([]);
                break;
            case ActionTypeEnum.Clear:
                scrollToDocumentTop();
                setAcTransaction(acTransactionInitValue);
                setValidationErrors([]);
                break;
            case ActionTypeEnum.RaiseError:
                scrollToDocumentTop();
                setValidationErrors(request.request);
                break;
            case ActionTypeEnum.Failed:
                setValidationErrors([request.request.Errors]);
                break;
            case ActionTypeEnum.Update:
                setLoading(true);
                scrollToDocumentTop();
                let response = await selectAcTransaction(request.id!);
                response = handleModifyRequest(response);
                setAcTransaction(response.Result || acTransactionInitValue);
                setLoading(false);
                break;
            case ActionTypeEnum.Delete:
                setLoading(true);
                setAcTransaction(request.request);
                setShowDeleteModel(true);
                setLoading(false);
                break;
                case ActionTypeEnum.PrintPdf:
                    setPrintTransactionId(request.id || 0);
                    setShowPrintoutPdfModel(true);
                    break;
        }
    };
    const handleModifyRequest = (
        request: ResponseBaseModel<AcTransactionModel>
    ): ResponseBaseModel<AcTransactionModel> => {
        if (
            request !== null &&
            request !== undefined &&
            request.Result !== null &&
            request.Result !== undefined
        ) {
            request.Result.isNewRequest = false;
            request.Result.Value =
                request.Result.Value * request.Result.ValueCurrency;
        }
        return request;
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            {showPrintoutPdfModel && (
                <GenericModelPopupPdfReportViewer
                    keys={[{key: "transactionId", value: printTransactionId}]}
                    type={PrintTypeEnum.AcTransaction}
                    onCloseEvent={() => {
                        setShowPrintoutPdfModel(false);
                    }}
                />
            )}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            {/* ac transaction form*/}
            {fetchLookupCompleted && (
                <AcTransactionForm
                    acTransactionType={acTransactionType}
                    request={acTransaction}
                    setLoading={setLoading}
                    accountList={accountLookups}
                    currencyList={currencyLookUps}
                    coastCenterList={coastCenterLookUps}
                    onActionEvent={async (o: RequestActionModel) => {
                        await handleAction(o);
                    }}
                    formId={formId}
                />
            )}
            {/* ac transaction list*/}
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body>
                        <AcTransactionList
                            acTransactionType={acTransactionType}
                            accountLookups={accountLookups}
                            userLookUps={userLookUps}
                            setIsRefresh={setIsRefresh}
                            isRefresh={isRefresh}
                            onActionEvent={async (o: RequestActionModel) => {
                                await handleAction(o);
                            }}
                            formId={formId}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ConfirmModelDialogBox
                isModelVisible={showDeleteModel}
                onCloseEvent={() => {
                    setShowDeleteModel(false);
                }}
                actions={deleteActions}
            >
                <>{getLabelName("Are You Sure You Want Delete ")}</>
            </ConfirmModelDialogBox>
            <ModelDialogBox
                isModelVisible={showPrintModel.show}
                isCloseButtonVisible={true}
                onCloseEvent={() => {
                    setShowPrintModel({show: false, content: ""});
                }}
            >
                <PdfViewer content={showPrintModel.content}/>
            </ModelDialogBox>
        </>
    );
    //#endregion
};
