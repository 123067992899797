import { FC, useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import {
  AddRestaurant,
  ConfirmModelDialogBox,
  LoadingBox,
  RestaurantList,
  ToastBox,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  PageEnum,
  RequestActionModel,
  RestaurantResponseModel,
  RowStateEnum,
  ToastModel,
} from "../../models";
import {
  deleteRestaurant,
  getRestaurant,
} from "../../serviceBroker/resturantApiServiceBroker";
import {
  getLabelName,
  getPageNameByPageId,
  isArabicCurrentLanguage,
} from "../../utils";

export const RestaurantPage: FC<{ formId: number }> = ({
  formId = PageEnum.Tables,
}) => {
  //#region variables
  const initialValues: RestaurantResponseModel = {
    Code: "",
    Notes: "",
    Parent_ID: null,
    IsParent: false,
    AllParent: null,
    IsDefault: false,
    ShowInPOS: false,
    Name_En: "",
    PrinterName: "",
    BackBolor: 0,
    FontName: "",
    FontSize: 0,
    FontColor: 0,
    HeightButton: 0,
    WidthButton: 0,
    ImagePath: "",
    Active: false,
    IsPrinted: false,
    RemarksOfChamges: "",
    PrintDate: "",
    UserPrinted: "",
    UserIdPrinted: 0,
    ReservedDate: "",
    UserNameReserved: "",
    UserIdReserved: 0,
    Row_Status: 0,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Name: "",
    CreationDate: "",
    ModificationDate: null,
    VerifyOnUpdate: false,
    rowState: 1,
    Errors: [],
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState<RestaurantResponseModel[]>([]);
  const [object, setObject] = useState<RestaurantResponseModel>(initialValues);
  const [deletedObject, setDeletedObject] =
    useState<RestaurantResponseModel | null>(null);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [pageName, setPageName] = useState<string | null>(null);
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllObjects();
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    if (formId !== 0 && formId !== null && formId !== undefined) {
      setPageName(getPageNameByPageId(formId));
    }
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region function
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("Ok"),
      onClick: async () => {
        await handleAction({
          id: object?.ID || 0,
          request: object,
          action: ActionTypeEnum.DeleteOperationStart,
        });
      },
    },
    {
      text: getLabelName("Cancel"),
      onClick: () => {
        setDeletedObject(null);
        setShowDeleteModel(false);
      },
    },
  ];
  const getAllObjects = async () => {
    setLoading(true);
    const result = await getRestaurant();
    setObjects(result || []);
    setLoading(false);
  };
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        setObject(initialValues);
        await getAllObjects();
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        setLoading(false);
        break;
      case ActionTypeEnum.Clear:
        setLoading(true);
        setObject(initialValues);
        await getAllObjects();
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        setLoading(true);
        window.scrollTo(0, 0);
        const updateObject = objects.filter((p) => p.ID === request.id)[0];
        updateObject.rowState = Number(RowStateEnum.Update);
        setObject(updateObject);
        setLoading(false);
        break;
      case ActionTypeEnum.Delete:
        setLoading(true);
        const deleteObject = objects.filter((p) => p.ID === request.id)[0];
        setDeletedObject(deleteObject);
        setShowDeleteModel(true);
        setLoading(false);
        break;
      case ActionTypeEnum.DeleteOperationStart:
        setLoading(true);
        setShowDeleteModel(false);
        console.log("request", request);
        const result = await deleteRestaurant(Number(deletedObject!.ID));
        setDeletedObject(null);
        setObject(initialValues);
        setLoading(false);
        await getAllObjects();
        await handleAction({
          id: 0,
          action: result.Result.Result
            ? ActionTypeEnum.Success
            : ActionTypeEnum.Failed,
        });
        setLoading(false);
        break;
      case ActionTypeEnum.DeleteSuccess:
      case ActionTypeEnum.Success:
        setToastModel({ ...toastModel, show: true, variant: "success" });
        break;
      case ActionTypeEnum.Failed:
        setToastModel({
          ...toastModel,
          body: request.request,
          variant: "danger",
          show: true,
        });
        setObject(request.requestBeforeError);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <div className="row row-cols-1 row-cols-xxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-4 row-cols-sm-1 g-sm-2 g-md-4 align-items-start"></div>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <AddRestaurant
                      onActionEvent={async (o: RequestActionModel) => {
                        await handleAction(o);
                      }}
                      request={object}
                      formId={formId}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <Card className="card-custom">
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                <ConfirmModelDialogBox
                  isModelVisible={showDeleteModel}
                  onCloseEvent={() => {
                    setShowDeleteModel(false);
                    setDeletedObject(null);
                  }}
                  actions={deleteActions}
                >
                  <div className="alert alert-warning">
                    {getLabelName("Are You Sure You Want Delete ")}
                  </div>
                </ConfirmModelDialogBox>
                {objects && (
                  <RestaurantList
                    request={objects}
                    onActionEvent={async (o: RequestActionModel) => {
                      await handleAction(o);
                    }}
                    formId={formId}
                  />
                )}
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
