import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {VoucherApiResponseModel} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {handleError} from "../utils";
//#region get
export const getVoucherById = async (
    code: string
): Promise<VoucherApiResponseModel> => {
    try {
        let url: string = `${AlYuserApiUrls.voucherController.getVoucherByCode}?voucherCode=${code}`;
        // @ts-ignore
        return await AlYusrAxiosApiInstance.get(url);
    } catch (err) {
        alert(handleError(err));
    }
    // @ts-ignore
    return;
};
//#endregion