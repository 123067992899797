export const handleError = (error: any): Error => {
    const stackTrace = new Error().stack || "";
    const stackLines = stackTrace.split("\n");
    const callerLine = stackLines[2] || ""; // Line where the function is called
    // Extract file name and function name using RegEx
    const functionName = callerLine.match(/at (\S+)/)?.[1] || "Unknown Function";
    const errorMessage = `
        Error in 
        Function: ${functionName}
        Message: ${error?.message || "An unexpected error occurred"}
    `;

    console.error(errorMessage, error); // Log to the console
    return new Error(errorMessage); // Return a formatted error
};