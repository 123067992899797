import React, { FC, useEffect, useRef, useState } from "react";
import { Alert, Modal, Toast, ToastContainer } from "react-bootstrap";
import { ErrorDisplayTypeEnum, ValidationErrorModel } from "../../../models";
import {  isArabicCurrentLanguage } from "../../../utils";
import {toast, ToastOptions} from "react-toastify";

// Define the Direction type
type Direction = "left-to-right" | "right-to-left" | "top-to-bottom" | "bottom-to-top";
const defaultDelayDuration:number=10000;
//#region main Component
export const ErrorValidationBox: FC<{
    errors?: ValidationErrorModel[];
    variant?: string | null;
    displayType?: ErrorDisplayTypeEnum;
}> = ({ errors, variant = "danger", displayType = ErrorDisplayTypeEnum.Flat }) => {
    //#region state
    const errorBoxRef = useRef<HTMLDivElement | null>(null);
    //#endregion
    //#region useEffect
    useEffect(() => {
        if (errors && errors.length > 0 && errorBoxRef.current) {
            errorBoxRef.current.scrollIntoView({behavior: "smooth", block: "center"});
        }
    }, [errors]);
    //#endregion
    //#region html
    if (errors !== null && errors !== undefined && errors?.length !== 0) {
        let isArabic: boolean = isArabicCurrentLanguage();
        const errorMessages = (
            <>
                {errors.map((error, index) => (
                    <div key={`error_key${index}`}>
                        {isArabic ? error.MessageAr : error.MessageEn}
                        <br/>
                    </div>
                ))}
            </>
        );
        if (displayType === ErrorDisplayTypeEnum.ModelPopup) {
            return <ModalError errorMessages={errorMessages} ref={errorBoxRef}/>;
        } else if (displayType === ErrorDisplayTypeEnum.Toast) {
            return <ToastError errorMessages={errorMessages} variant={variant} ref={errorBoxRef}/>;
        } else if (displayType === ErrorDisplayTypeEnum.Toastify) {
            return <ToastifyError errorMessages={errorMessages} variant={variant} ref={errorBoxRef}/>;
        } else {
            return (
                <div ref={errorBoxRef}>
                    <FlatError errorMessages={errorMessages} variant={variant}/>
                </div>
            );
        }
    }
    return null;
    //#endregion
};
//#endregion
//#region child component
//#region ModalError Component
const ModalError = React.forwardRef<HTMLDivElement, { errorMessages: JSX.Element }>(
    ({ errorMessages }, ref) => {
        const [isModalVisible, setModalVisible] = useState(true);
        useEffect(() => {
            if (errorMessages) {
                setModalVisible(true);
            }
        }, [errorMessages]);
        return (
            <div ref={ref}>
                <Modal
                    show={isModalVisible}
                    onHide={() => setModalVisible(false)}
                    centered
                    className="custom-modal"
                >
                    <Modal.Header closeButton />
                    <Modal.Body>{errorMessages}</Modal.Body>
                </Modal>
            </div>
        );
    }
);
//#endregion
//#region ToastError Component
const ToastError = React.forwardRef<HTMLDivElement, {
    errorMessages: JSX.Element;
    variant: string | null;
    direction?: Direction;
}>(({ errorMessages, variant, direction = "top-to-bottom" }, ref) => {
    const getFadeInClass = (direction: Direction): string => {
        switch (direction) {
            case "left-to-right":
                return "fade-in-left";
            case "right-to-left":
                return "fade-in-right";
            case "top-to-bottom":
                return "fade-in-top";
            case "bottom-to-top":
                return "fade-in-bottom";
            default:
                return "fade-in";
        }
    };

    const handleClose = () => {
        setAnimationClass("fade-out");
        setTimeout(() => setToastVisible(false), 500);
    };

    const [isToastVisible, setToastVisible] = useState(true);
    const [animationClass, setAnimationClass] = useState(getFadeInClass(direction));

    useEffect(() => {
        if (errorMessages) {
            setAnimationClass(getFadeInClass(direction));
            setToastVisible(true);
        }
    }, [errorMessages]);

    return (
        <div ref={ref}>
            <ToastContainer className="p-3">
                {isToastVisible && (
                    <Toast
                        onClose={handleClose}
                        show={true}
                        delay={defaultDelayDuration}
                        autohide
                        bg={variant || "warning"}
                        className={animationClass}
                    >
                        <Toast.Body>
                            <i className="mdi mdi-check-circle"></i> {errorMessages}
                        </Toast.Body>
                    </Toast>
                )}
            </ToastContainer>
        </div>
    );
});
//#endregion
//#region ToastifyError Component
const ToastifyError = React.forwardRef<HTMLDivElement, {
    errorMessages: JSX.Element;
    variant: string | null;
    direction?: Direction;
}>(({ errorMessages, variant, direction = "top-to-bottom" }, ref) => {
    const getPosition = (direction: Direction): ToastOptions["position"] => {
        switch (direction) {
            case "left-to-right":
                return "bottom-left";
            case "right-to-left":
                return "bottom-right";
            case "top-to-bottom":
                return "top-center";
            case "bottom-to-top":
                return "bottom-center";
            default:
                return "top-center";
        }
    };

    const customColors: Record<string, string> = {
        primary: "rgba(0, 123, 255, 0.8)", // Blue with transparency
        secondary: "rgba(108, 117, 125, 0.8)", // Grey
        light: "rgba(248, 249, 250, 0.8)", // Light grey
        dark: "rgba(52, 58, 64, 0.8)", // Dark grey
        danger: "rgba(220, 53, 69, 0.8)", // Red with 80% opacity
        error: "rgba(220, 53, 69, 0.8)", // Red with 80% opacity
    };

    const showToast = () => {
        toast.dismiss();
            const mappedVariant = variant === "danger" ? "error" : variant;
            toast(errorMessages, {
                position: getPosition(direction),
                type: ["success", "error", "warning", "info"].includes(variant!)
                    ? (mappedVariant as ToastOptions["type"])
                    : undefined,
                autoClose: defaultDelayDuration,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: mappedVariant === "light" ? "light" : "colored", // Light theme for "light" variant
                style: {
                    backgroundColor: customColors[mappedVariant!] || "#007bff", // Fallback to primary if variant is undefined,
                    color: mappedVariant === "light" ? "#000" : "#fff",
                },
                progressStyle: {
                    backgroundColor: "#ffffff", // Set the progress bar color explicitly
                },
            });
        }
    useEffect(() => {
        if (errorMessages) {
            showToast();
        }
    },  [errorMessages, variant, direction]);

    return <div ref={ref}><ToastContainer /></div>;
});
//#endregion
//#region  FlatError Component
const FlatError = React.forwardRef<HTMLDivElement, {
    errorMessages: JSX.Element;
    variant: string | null;
}>(({ errorMessages, variant }, ref) => {
    return (
        <div ref={ref}>
            <Alert key={variant} variant={variant || "danger"}>
                {errorMessages}
            </Alert>
        </div>
    );
});
//#endregion
