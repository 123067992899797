import {FC, useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {
    ErrorValidationBox,
    LoadingBox,
    ConfirmModelDialogBox,
    ToastBox,
    AddDueSubtractRule,
    DueSubtractRulesList,
} from "../../components";
import {
    ToastModel,
    ActionTypeEnum,
    RowStateEnum,
    ActionButtonsModel,
    RequestActionModel,
    AccrualSubtractItemModel,
    ValidationErrorModel,
    AccrualSubtractRuleTypeEnum, PageEnum,
} from "../../models";
import {
    deleteEstihkakSubtractRule,
    GetAccrualSubtractRuleById,
    GetAccrualSubtractRules,
} from "../../serviceBroker/employeeEstihkakSubtracApiServiceBroker";
import {
    getPageNameByPageId,
    getLabelName,
    isArabicCurrentLanguage,
} from "../../utils";

export const EmployeeDueAndSubtractRules: FC<{ formId: number }> = ({formId = PageEnum.AdditionRules}) => {
    //#region variables
    const accrualSubtractRuleType: AccrualSubtractRuleTypeEnum = formId === Number(PageEnum.AdditionRules) ? AccrualSubtractRuleTypeEnum.Accrual : AccrualSubtractRuleTypeEnum.Subtract;
    const initialValues: AccrualSubtractItemModel = {
        Name_En: "",
        Name: "",
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Errors: [],
        rowState: Number(RowStateEnum.Add),
        CreationDate: new Date(),
        ModificationDate: new Date(),
        Notes: "",
        Row_State: Number(RowStateEnum.Add),
        TypeRule_ID: Number(accrualSubtractRuleType),
        VerifyOnUpdate: false,
    };
    const deleteAccrualSubtractRuleActions: ActionButtonsModel[] = [
        {
            text: getLabelName("yes"),
            onClick: async () => {
                await handleDeleteAccrualSubtractRule();
            },
        },
        {
            text: getLabelName("no"),
            onClick: () => {
                setAccrualSubtractRule(initialValues);
                setShowDeleteAccrualSubtractRuleModel(false);
            },
        },
    ];
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [validationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [accrualSubtractRules, setAccrualSubtractRules] = useState<
        AccrualSubtractItemModel[]
    >([]);
    const [accrualSubtractRule, setAccrualSubtractRule] =
        useState<AccrualSubtractItemModel>(initialValues);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const [pageName, setPageName] = useState<string | null>(null);
    const [
        showDeleteAccrualSubtractRuleModel,
        setShowDeleteAccrualSubtractRuleModel,
    ] = useState(false);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllAccrualSubtractRules();
        };
        fillData().then(() => {
        });
    }, []);
    useEffect(() => {
        if (formId !== 0 && formId !== null && formId !== undefined) {
            setPageName(getPageNameByPageId(formId));
        }
    }, [isArabicCurrentLanguage()]);
    //#endregion
    //#region function
    const handleAccrualSubtractRuleAction = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.AddSuccess:
                setLoading(true);
                setAccrualSubtractRule(initialValues);
                await getAllAccrualSubtractRules();
                setToastModel({
                    ...toastModel,
                    variant: "success",
                    show: true,
                });
                setLoading(false);
                break;
            case ActionTypeEnum.Clear:
                setLoading(true);
                setAccrualSubtractRule(initialValues);
                await getAllAccrualSubtractRules();
                setLoading(false);
                break;
            case ActionTypeEnum.Update:
                window.scrollTo(0, 0);
                const AccrualSubtractRuleObject = await GetAccrualSubtractRuleById(
                    request.id
                );
                // @ts-ignore
                AccrualSubtractRuleObject.rowState = Number(RowStateEnum.Update);
                setAccrualSubtractRule(AccrualSubtractRuleObject);
                break;
            case ActionTypeEnum.Delete:
                const accrualSubtractRuleObject = await GetAccrualSubtractRuleById(
                    request.id
                );
                setAccrualSubtractRule(accrualSubtractRuleObject);
                setShowDeleteAccrualSubtractRuleModel(true);
                break;
            case ActionTypeEnum.Failed:
                setToastModel({
                    ...toastModel,
                    body: request.request,
                    variant: "danger",
                    show: true,
                });
                break;
        }
    };
    const getAllAccrualSubtractRules = async () => {
        setLoading(true);
        const accrualSubtractRuleList = await GetAccrualSubtractRules(
            Number(accrualSubtractRuleType)
        );
        setAccrualSubtractRules(accrualSubtractRuleList);
        setLoading(false);
    };
    const handleDeleteAccrualSubtractRule = async () => {
        setShowDeleteAccrualSubtractRuleModel(false);
        const deleteAccrualSubtractRuleResponse = await deleteEstihkakSubtractRule(
            accrualSubtractRule !== null ? accrualSubtractRule.ID : 0
        );
        const isSuccess: boolean = !(deleteAccrualSubtractRuleResponse.Result.Errors != null &&
            deleteAccrualSubtractRuleResponse.Result.Errors.length !== 0);
        if (isSuccess) {
            await getAllAccrualSubtractRules();
        }
        setToastModel({...toastModel, show: true, variant: isSuccess ? "success" : "danger"});
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false})
                    }}
                />
            )}
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{pageName}</Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            <Card.Body>
                                <AddDueSubtractRule
                                    request={accrualSubtractRule}
                                    onActionEvent={async (o: RequestActionModel) => {
                                        await handleAccrualSubtractRuleAction(o);
                                    }}
                                    formId={formId}
                                />
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body>
                        <Card className="card-custom">
                            {/* <Card.Header></Card.Header> */}
                            <Card.Body>
                                {/* delete AccrualSubtractRule  */}
                                <ConfirmModelDialogBox
                                    isModelVisible={showDeleteAccrualSubtractRuleModel}
                                    onCloseEvent={() => {
                                        setShowDeleteAccrualSubtractRuleModel(false);
                                    }}
                                    actions={deleteAccrualSubtractRuleActions}
                                >
                                </ConfirmModelDialogBox>
                                {/* AccrualSubtractRule list */}
                                {accrualSubtractRules && (
                                    //&& accrualSubtractRules.length !== 0
                                    <DueSubtractRulesList
                                        request={accrualSubtractRules}
                                        onActionEvent={async (o: RequestActionModel) => {
                                            await handleAccrualSubtractRuleAction(o);
                                        }}
                                        onCompleteEvent={getAllAccrualSubtractRules}
                                        formId={formId}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
}