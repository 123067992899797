import { FC, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  EmployeeListItemModel,
  EmployeeResponseModel,
  PageEnum,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {
  deleteEmployee,
  getAllEmployees,
  getEmployeeInformation,
} from "../../serviceBroker/employeesApiServiceBroker";
import {
  ConfirmModelDialogBox,
  EmployeeForm,
  EmployeeList,
  ErrorValidationBox,
  LoadingBox,
  ModelDialogBox,
  ToastBox,
} from "../../components";
import { getLabelName } from "../../utils";
import { CommissionsList } from "../../components/employees/commissionsList";

export const EmployeePage: FC = () => {
  //#region variables
  const initialValues: EmployeeResponseModel = {
    ID: 0,
    NameEn: "",
    Name: "",
    Salary: 0,
    Mobile: "",
    Percentage_Of_Sales: 0,
    Job: "",
    BirthDay: new Date(),
    Address: "",
    Mail: "",
    IsActive: false,
    IsDefault: false,
    Errors: [],
    rowState: Number(RowStateEnum.Add),
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors] = useState<ValidationErrorModel[]>([]);
  const [Employees, setEmployees] = useState<EmployeeListItemModel[]>([]);
  const [Employee, setEmployee] =
    useState<EmployeeResponseModel>(initialValues);
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  const [showDeleteUserModel, setShowDeleteUserModel] = useState(false);
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  //#endregion
  //#region function
  const resetCurrentEmployee = () => {
    setEmployee(initialValues);
  };
  const deleteUserActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeleteEmployee();
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        resetCurrentEmployee();
        setShowDeleteUserModel(false);
      },
    },
  ];
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllEmployeeFun();
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const handleEmployeeAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        setEmployee(initialValues);
        await getAllEmployeeFun();
        setToastModel({ ...toastModel, show: true });
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        window.scrollTo(0, 0);
        setLoading(true);
        let updateUserObject = await getEmployeeInformation(request.id);
        setLoading(false);
        // @ts-ignore
        updateUserObject.rowState = Number(RowStateEnum.Update);
        setEmployee(updateUserObject);
        break;
      case ActionTypeEnum.Delete:
        let userObject = await getEmployeeInformation(request.id);
        setEmployee(userObject);
        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.ShowCommission:
        setLoading(true);
        setEmployee((prev) => ({ ...prev, ID: request.id as number }));
        setLoading(false);
        setShowCommissionModal(true);
        break;
      case ActionTypeEnum.Search:
        setEmployees((prev) =>
          prev.map((employee) => ({
            ...employee,
            shown:
              employee.Name.includes(request.request) ||
              employee.Mobile.includes(request.request),
          }))
        );
        break;
      case ActionTypeEnum.Clear:
        setEmployee(initialValues);
        break;
    }
  };
  const closeCommissionModal = () => {
    resetCurrentEmployee();
    setShowCommissionModal(false);
  };
  const getAllEmployeeFun = async () => {
    setLoading(true);
    const employeesList = await getAllEmployees();
    if (employeesList.Result !== undefined) {
      setEmployees(
        employeesList.Result.map((emp) => ({ ...emp, shown: true }))
      );
    }
    setLoading(false);
  };
  const handleDeleteEmployee = async () => {
    setShowDeleteUserModel(false);
    const deleteUserResponse = await deleteEmployee(
      Employee !== undefined ? Employee.ID : 0
    );
    const isSuccess: boolean = !(
      deleteUserResponse.Result.Errors != null &&
      deleteUserResponse.Result.Errors.length !== 0
    );
    if (isSuccess) {
      await getAllEmployeeFun();
    }
    setToastModel({
      ...toastModel,
      show: true,
      variant: isSuccess ? "success" : "danger",
    });
  };
  const handleSuccessFeedBack = () => {
    setToastModel({
      ...toastModel,
      show: true,
      variant: "success",
    });
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName("employee Information")}
          </Accordion.Header>
          <Accordion.Body style={{ overflow: "visible" }}>
            <Card>
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                <EmployeeForm
                  request={Employee}
                  onActionEvent={async (o: RequestActionModel) => {
                    await handleEmployeeAction(o);
                  }}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous data")}</Accordion.Header>

          <Accordion.Body>
            <Card className="card-custom">
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                {/* delete user  */}
                <ConfirmModelDialogBox
                  isModelVisible={showDeleteUserModel}
                  onCloseEvent={() => {
                    setShowDeleteUserModel(false);
                    resetCurrentEmployee();
                  }}
                  actions={deleteUserActions}
                ></ConfirmModelDialogBox>
                <ModelDialogBox
                  isModelVisible={showCommissionModal}
                  isCloseButtonVisible={false}
                  title={getLabelName("Define Profit Percentage Per Category")}
                  size="xl"
                  onCloseEvent={closeCommissionModal}
                  isEscapeCloseEnabled={true}
                  isXCloseButtonVisible={true}
                >
                  <CommissionsList
                    employeeID={Employee.ID}
                    onCloseHandler={closeCommissionModal}
                    onSuccessHandler={handleSuccessFeedBack}
                  />
                </ModelDialogBox>
                {/* user list */}
                {Employees && Employees.length !== 0 && (
                  <EmployeeList
                    request={Employees}
                    isModifyAble={true}
                    isDeleteAble={true}
                    isPermissionAble={false}
                    onActionEvent={async (o: RequestActionModel) => {
                      await handleEmployeeAction(o);
                    }}
                    onCompleteEvent={getAllEmployeeFun}
                    formId={PageEnum.Employees}
                  />
                )}
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
