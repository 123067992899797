import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import {
  TextBox,
  InputDatePicker,
  SelectBox,
  RadioCheckBox,
  LoadingBox,
  GenericModelPopupPdfReportViewer,
  ErrorValidationBox,
  ToastBox,
  ConfirmModelDialogBox,
  GridTable,
  ButtonBox,
} from "..";
import {
  getLabelName,
  getUserId, handleError,
  isArabicCurrentLanguage,
  sumType,
} from "../../utils";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import {
  AccountAcBeginBalanceTypeEnum,
  ActionButtonsModel,
  ActionTypeEnum,
  AcTransactionDetailModel,
  HasFormIdModel,
  InputDatePickerTimeDisplayModeEnum,
  PrintTypeEnum,
  RequestActionModel,
  RowStateEnum,
  SelectItemModel,
  ToastModel,
  TransactionRowDataModel,
  ValidationErrorModel,
  VoucherTransactionFormModel,
} from "../../models";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import setCurrentTransactionColumns from "./columns/currentTransactionColumns";
import {
  deleteAcTransaction,
  getAcTransactionFullDetailById,
  saveCurrentVoucher,
} from "../../serviceBroker/acTransactionsApiServiceBroker";
import { PrivilegesChecker } from "..";
import {
  useAccountsByType,
  useCostCenters,
  useMainAccountTypes,
  useStoredCurrencies,
  useStoredEmployees,
  useTransactions,
} from "../../hooks";
const initialValues: VoucherTransactionFormModel = {
  id: "",
  code: "",
  date: new Date(),
  type: null,
  cmbxCostCenter: null,
  employee: null,
  currency: null,
  amount: 0,
  toAccount: null,
  valueType: AccountAcBeginBalanceTypeEnum.CreditAccount,
  note: "",
  rowState: RowStateEnum.Add,
};
const selectionOptionSchema = yup
  .object()
  .shape({ label: yup.string(), value: yup.string() });
const validationSchema = yup.object({
  code: yup.string(),
  date: yup.date().required("required"),
  type: selectionOptionSchema.required("required").nullable(),
  employee: selectionOptionSchema.required("required").nullable(),
  cmbxCostCenter: selectionOptionSchema.required("required").nullable(),
  toAccount: selectionOptionSchema.required("required").nullable(),
  amount: yup
    .number()
    .moreThan(0, "Amount must be greater than 0")
    .required("required"),
  currency: selectionOptionSchema.required("required").nullable(),
  valueType: yup.string().required("required"),
  note: yup.string(),
});

interface AcComplexTransactionProps extends HasFormIdModel {}

export const AcComplexTransaction: React.FC<AcComplexTransactionProps> = ({
  formId,
}) => {
  //#region custom hooks states
  const {
    searchedTransactions,
    areTransactionsLoading,
    setPageNumber,
    setPageSize,
    currentPage,
    currentPageSize,
    totalRows,
    fetchAccountTransactions,
  } = useTransactions();
  const { accountTypes, areAccountTypesLoading } = useMainAccountTypes();
  const { employeesLoading, storedEmployeeList } = useStoredEmployees();
  const { currenciesLoading, storedCurrencies } = useStoredCurrencies();
  const { accounts, areAccountsLoading, setCurrentTypeId } =
    useAccountsByType();
  const { costCenters, areCostCentersLoading } = useCostCenters();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });
  //#endregion custom hooks states
  //#region states
  const [currentJournalVoucher, setCurrentJournalVoucher] = useState<
    TransactionRowDataModel[]
  >([]);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  //#region print states
  const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
  const [printTransactionId, setPrintTransactionId] = useState(0);
  //#endregion print states
  //#region voucher states
  const [currentVoucherID, setCurrentVoucherID] = useState<number | null>(null);
  const [deletedVoucherID, setDeletedVoucherID] = useState<number | null>(null);
  const [isCurrentVoucherLoading, setIsCurrentVoucherLoading] =
    useState<boolean>(false);
  //#endregion voucher states
  //#region feedback states
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
    body: "process completed successfully",
    variant: "success",
  });
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  //#endregion feedback states
  //#region state variables
  //#region Refs to clear value of select box
  const isArabic = isArabicCurrentLanguage();
  const costCenter = useRef<any>();
  const type = useRef<any>();
  const toAccount = useRef<any>();
  const employee = useRef<any>();
  const currency = useRef<any>();
  //#endregion Refs to clear value of select box
  //#endregion state driven variables
  //#region values
  const totalCredit = sumType(
    AccountAcBeginBalanceTypeEnum.CreditAccount,
    currentJournalVoucher
  );
  const totalDebit = sumType(
    AccountAcBeginBalanceTypeEnum.DebitAccount,
    currentJournalVoucher
  );
  //#endregion values
  //#region flags
  const isModifyingVoucher = currentVoucherID !== null;
  const isDataLoading =
    areTransactionsLoading ||
    areAccountTypesLoading ||
    employeesLoading ||
    currenciesLoading ||
    isCurrentVoucherLoading ||
    areCostCentersLoading;
  //#endregion flags
  //#endregion state driven variables
  //#region effects
  useEffect(() => {
    resetVoucherValues();
  }, [storedCurrencies, costCenters, storedEmployeeList, accountTypes]);

  useEffect(() => {
    if (totalCredit === totalDebit) setValidationErrors([]);
  }, [totalCredit, totalDebit]);

  useEffect(() => {
    if (currentVoucherID === null) return;
    setIsCurrentVoucherLoading(true);
    getAcTransactionFullDetailById(`${currentVoucherID}`).then(
      (voucherDetails) => {
        setIsCurrentVoucherLoading(false);
        setCurrentJournalVoucher(
          (
            voucherDetails?.Ac_TransactionDetails as AcTransactionDetailModel[]
          ).map(
            ({
              ID,
              Type: valueType,
              Account_ID,
              Value,
              CreationDate,
              AccountNameEn,
              AccountType,
              rowState,
            }) => ({
              amount: Value,
              date: new Date(CreationDate),
              id: "" + ID,
              toAccount: {
                label: getLabelName(AccountNameEn),
                value: "" + Account_ID,
              },
              note: "",
              valueType: valueType,
              type: { label: "", value: "" + AccountType },
              rowState,
              isSaved: true,
            })
          )
        );
        formik.setFieldValue(
          "date",
          new Date(voucherDetails?.CreationDate as string)
        );
        formik.setFieldValue(
          "cmbxCostCenter",
          costCenters.find(
            ({ value }) => value === "" + voucherDetails?.CostCenter_ID
          ) || null
        );
        formik.setFieldValue(
          "employee",
          storedEmployeeList.find(
            ({ value }) => value === "" + voucherDetails?.Employee_Id
          ) || null
        );
        formik.setFieldValue(
          "currency",
          storedCurrencies.find(
            ({ value }) => value === "" + voucherDetails?.Currency_ID
          ) || null
        );
        formik.setFieldValue("code", voucherDetails?.Code || "0");
      }
    );
  }, [currentVoucherID]);
  //#endregion effects
  //#region handlers
  const handleVoucherSelect = async (id: number) => {
    if (id === currentVoucherID) return;
    setCurrentVoucherID(id);
    formik.setErrors({});
  };
  const handleTransactionModification = (id: string, value: number) => {
    setCurrentJournalVoucher((prev) =>
      prev.map((transaction) => {
        if (transaction.id === id)
          return {
            ...transaction,
            amount: value,
            rowState: transaction.isSaved
              ? RowStateEnum.Update
              : RowStateEnum.Add,
          };
        return transaction;
      })
    );
  };

  const resetVoucherValues = () => {
    if (storedCurrencies.length !== 0)
      formik.setFieldValue("currency", {
        value: storedCurrencies[1].value,
        label: getLabelName(storedCurrencies[1].name),
      });
    if (costCenters.length !== 0)
      formik.setFieldValue("cmbxCostCenter", {
        value: costCenters[0].value,
        label: getLabelName(costCenters[0].name),
      });
    if (storedEmployeeList.length !== 0)
      formik.setFieldValue("employee", {
        value: storedEmployeeList[1].value,
        label: getLabelName(storedEmployeeList[1].name),
      });
    if (accountTypes.length !== 0) {
      formik.setFieldValue("type", {
        value: accountTypes[0].value,
        label: getLabelName(accountTypes[0].name),
      });
      setCurrentTypeId(
        accountTypes[0].value === null ? null : +accountTypes[0].value
      );
    }
  };

  const deleteActions: (deletedId: number) => ActionButtonsModel[] = (
    deletedID
  ) => {
    return [
      {
        text: getLabelName("yes"),
        onClick: async () => {
          await handleVoucherDeletion(deletedID);
          setShowDeleteModel(false);
          setToastModel((prev) => ({ ...prev, show: true }));
          setDeletedVoucherID(null);
        },
      },
      {
        text: getLabelName("No"),
        onClick: () => {
          setShowDeleteModel(false);
          setDeletedVoucherID(null);
        },
      },
    ];
  };

  const handleDeletion = (deletedItemID: string) => {
    setCurrentJournalVoucher((prev) =>
      prev
        .filter(({ id, isSaved }) => {
          return !(!isSaved && id === deletedItemID);
        })
        .map((transaction) => {
          if (deletedItemID === transaction.id)
            return { ...transaction, rowState: RowStateEnum.Delete };
          return transaction;
        })
    );
  };

  const handleVoucherDeletion = async (id: number) => {
    try {
      setIsCurrentVoucherLoading(true);
      await deleteAcTransaction(id, getUserId());
      await fetchAccountTransactions();
      if (id === currentVoucherID) resetFormData();
      setIsCurrentVoucherLoading(false);
    } catch (err) {
      alert(handleError("item cannot be deleted"));
    }
  };
  const handleVoucherPrint = (id: number) => {
    if (id === 0) return;
    setPrintTransactionId(id);
    setShowPrintoutPdfModel(true);
  };
  //#endregion handlers
  //#region helpers
  const resetFormData = () => {
    setCurrentJournalVoucher([]);
    setCurrentTypeId(null);
    setCurrentVoucherID(null);
    formik.resetForm();
    resetVoucherValues();
  };
  const resetTransactionData = () => {
    setCurrentTypeId(null);
    if (type.current) type.current.clearValue();
    if (type.current) toAccount.current.clearValue();
    formik.setErrors({});
    formik.setFieldValue("amount", "");
    formik.setFieldValue("toAccount", null);
    formik.setFieldValue("note", "");
    formik.setFieldValue("valueType", "1");
  };
  const isRemainingTransactionAvailable = (
    transactions: TransactionRowDataModel[]
  ) => {
    return transactions.some(
      ({ rowState }) => rowState !== RowStateEnum.Delete
    );
  };

  const onActionEvent = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.Update:
        await handleVoucherSelect(request.id || request.request.ID);
        break;
      case ActionTypeEnum.Delete:
        setShowDeleteModel(true);
        setDeletedVoucherID(request.id || request.request.ID);
        break;
      case ActionTypeEnum.Print:
        handleVoucherPrint(request.id || request.request.ID);
        break;
    }
  };
  //#endregion helpers
  //#region html
  return (
    <>
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <ConfirmModelDialogBox
        isModelVisible={showDeleteModel}
        onCloseEvent={() => {
          setShowDeleteModel(false);
          setDeletedVoucherID(null);
        }}
        actions={deleteActions(deletedVoucherID!)}
      >
        <>{getLabelName("Are You Sure You Want Delete ")}</>
      </ConfirmModelDialogBox>
      {showPrintoutPdfModel && (
        <GenericModelPopupPdfReportViewer
          keys={[{ key: "transactionId", value: printTransactionId }]}
          type={PrintTypeEnum.AcTransaction}
          onCloseEvent={() => {
            setShowPrintoutPdfModel(false);
          }}
        />
      )}

      {isDataLoading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName("Complex Jurnal voutcher")}
          </Accordion.Header>
          <Accordion.Body>
            <form
              onSubmit={async (e: React.FormEvent) => {
                e.preventDefault();
                const errors = await formik.validateForm();
                formik.setErrors(errors);
                if (Object.keys(errors).length !== 0) return;
                setCurrentJournalVoucher((prevState) => [
                  ...prevState,
                  {
                    amount: formik.values.amount,
                    note: formik.values.note,
                    toAccount: formik.values.toAccount,
                    valueType: +formik.values.valueType,
                    date: formik.values.date,
                    type: formik.values.type,
                    id: uuidv4(),
                    rowState: RowStateEnum.Add,
                    isSaved: false,
                  },
                ]);
                resetTransactionData();
              }}
            >
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <TextBox
                  inputValue={formik.values.code}
                  inputName="code"
                  type={"text"}
                  onChange={formik.handleChange}
                  labelName={getLabelName("code")}
                />
                <InputDatePicker
                  id="datePicker"
                  timeMode={InputDatePickerTimeDisplayModeEnum.None}
                  selectedDate={new Date(formik.values.date.toString())}
                  className="form-control"
                  InputLabel="date"
                  name="date"
                  onChange={(date: Date) => {
                    formik.values.date = date;
                    formik.setFieldValue("date", date);
                  }}
                  maxDate={new Date()}
                />
                <SelectBox
                  labelName={getLabelName("cmbxCostCenter")}
                  inputName="cmbxCostCenter"
                  multiselectRef={costCenter}
                  selectedValues={
                    formik.values.cmbxCostCenter === null
                      ? null
                      : [formik.values.cmbxCostCenter.value]
                  }
                  source={costCenters}
                  onStatusChange={(selectedValue: SelectItemModel) =>
                    formik.setFieldValue("cmbxCostCenter", selectedValue)
                  }
                  isSingleSelect={true}
                  errorText={t(formik.errors.cmbxCostCenter as string)}
                />
              </div>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <SelectBox
                  labelName={getLabelName("employee")}
                  inputName="employee"
                  multiselectRef={employee}
                  selectedValues={
                    formik.values.employee === null
                      ? null
                      : [formik.values.employee.value]
                  }
                  source={storedEmployeeList}
                  onStatusChange={(selectedValue: SelectItemModel) =>
                    formik.setFieldValue("employee", selectedValue)
                  }
                  isSingleSelect={true}
                  errorText={t(formik.errors.employee as string)}
                />
                <SelectBox
                  labelName={getLabelName("currency")}
                  inputName="currency"
                  multiselectRef={currency}
                  selectedValues={
                    formik.values.currency === null
                      ? null
                      : [formik.values.currency.value]
                  }
                  source={storedCurrencies}
                  onStatusChange={(selectedValue: SelectItemModel) =>
                    formik.setFieldValue("currency", selectedValue)
                  }
                  isSingleSelect={true}
                  errorText={t(formik.errors.currency as string)}
                />
              </div>
              <div className="row row-cols-12 mb-3">
                <TextBox
                  type={"textarea"}
                  labelName={"Note"}
                  inputName="note"
                  inputValue={formik.values.note}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <SelectBox
                  labelName={getLabelName("type")}
                  inputName="type"
                  multiselectRef={type}
                  selectedValues={
                    formik.values.type === null
                      ? null
                      : [formik.values.type.value]
                  }
                  source={accountTypes}
                  onStatusChange={(selectedValue: SelectItemModel) => {
                    if (selectedValue === null) return setCurrentTypeId(null);
                    if (selectedValue.value !== formik.values.type?.value) {
                      formik.setFieldValue("type", selectedValue);
                      setCurrentTypeId(+selectedValue.value);
                      // formik.setFieldValue("toAccount", null);
                      // toAccount.current.clearValue();
                    }
                  }}
                  isSingleSelect={true}
                  errorText={t(formik.errors.type as string)}
                />

                {formik.values.type && (
                  <SelectBox
                    labelName={getLabelName("toAccount")}
                    inputName="toAccount"
                    multiselectRef={toAccount}
                    selectedValues={
                      formik.values.toAccount === null
                        ? null
                        : ["" + formik.values.toAccount.value]
                    }
                    source={accounts}
                    onStatusChange={(selectedValue: SelectItemModel) => {
                      formik.setFieldValue("toAccount", selectedValue);
                    }}
                    isSingleSelect={true}
                    errorText={t(formik.errors.toAccount as string)}
                    isDataLoading={areAccountsLoading}
                  />
                )}
              </div>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <TextBox
                  labelName={getLabelName("Money")}
                  type={"number"}
                  inputName="amount"
                  inputValue={formik.values.amount}
                  onChange={formik.handleChange}
                  errorText={t(formik.errors.amount as string)}
                />
                <RadioCheckBox
                  items={[
                    {
                      text: getLabelName("Credit"),
                      value: "" + AccountAcBeginBalanceTypeEnum.CreditAccount,
                      name: "valueType",
                    },
                    {
                      text: getLabelName("Debit"),
                      value: "" + AccountAcBeginBalanceTypeEnum.DebitAccount,
                      name: "valueType",
                    },
                  ]}
                  onChange={formik.handleChange}
                  type={"radio"}
                  selectedValues={["" + formik.values.valueType]}
                />
              </div>
              <div className="row mt-3 ">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end ">
                  <Button
                    type="submit"
                    className="btn-gradient-primary mx-3 btn-fw"
                  >
                    {getLabelName("add")}
                  </Button>{" "}
                  <ButtonBox
                    variant="danger"
                    type="button"
                    iconType="receipt"
                    role="span"
                    className="btn btn-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      resetFormData();
                    }}
                  >
                    {getLabelName("New")}
                  </ButtonBox>
                </div>
              </div>
              <div className="row row-cols-1 mt-5">
                <GridTable
                  columns={setCurrentTransactionColumns({
                    handleDeletion: handleDeletion,
                    handleAmountChange: handleTransactionModification,
                  })}
                  data={currentJournalVoucher.filter(
                    ({ rowState }) => rowState !== RowStateEnum.Delete
                  )}
                  totalRows={totalRows}
                  currentPage={currentPage}
                  pageSize={currentPageSize}
                  onCurrentPageChange={() => {}}
                  onPageSizeChange={() => {}}
                  paginationType="none"
                />
                {isRemainingTransactionAvailable(currentJournalVoucher) && (
                  <div
                    className={`row row-cols-1 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mt-1 ${
                      totalCredit !== totalDebit ? "text-danger" : ""
                    }`}
                    style={{ marginRight: 0 }}
                  >
                    <div>
                      <span>
                        {getLabelName("total credit")}: {totalCredit}
                      </span>
                    </div>
                    <div>
                      <span>
                        {getLabelName("total debit")}: {totalDebit}
                      </span>
                    </div>
                  </div>
                )}
                {isRemainingTransactionAvailable(currentJournalVoucher) && (
                  <PrivilegesChecker
                    formId={formId}
                    action={currentVoucherID ? "EnableUpdate" : "EnableSave"}
                  >
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end ">
                      <ButtonBox
                        iconType="content-save"
                        type="button"
                        onClick={async () => {
                          if (totalDebit !== totalCredit)
                            return setValidationErrors((prev) => [
                              ...prev,
                              {
                                MessageAr:
                                  "إجمالي مبلغ الدائن لا يساوي إجمالي المدين",
                                MessageEn: "Total Credit Not Equal Total Debit",
                              },
                            ]);

                          const rowState = isModifyingVoucher
                            ? RowStateEnum.Update
                            : RowStateEnum.Add;
                          const voucher = {
                            Date: formik.values.date.toISOString(),
                            Code: formik.values.code,
                            CostCenter_ID: formik.values.cmbxCostCenter
                              ?.value as string,
                            Currency_ID: formik.values.currency
                              ?.value as string,
                            User_ID: getUserId(),
                            Employee_Id: formik.values.employee
                              ?.value as string,
                            Ac_TransactionDetails: currentJournalVoucher.map(
                              ({
                                amount,
                                date,
                                toAccount,
                                valueType,
                                note,
                                id,
                                rowState,
                              }) => {
                                return {
                                  Note: note,
                                  Type: valueType,
                                  Value: +amount,
                                  Account_ID: toAccount?.value,
                                  // Temporary createdBy ID
                                  CreatedBy: getUserId(),
                                  rowState,
                                  ID: id,
                                  CreationDate: date.toISOString(),
                                  TransactionHeaders_ID:
                                    isModifyingVoucher &&
                                    currentVoucherID !== null
                                      ? currentVoucherID
                                      : undefined,
                                };
                              }
                            ),
                            CreatedBy: getUserId(),
                            rowState,
                            ID:
                              isModifyingVoucher && currentVoucherID !== null
                                ? currentVoucherID
                                : undefined,
                          };
                          setIsCurrentVoucherLoading(true);
                          await saveCurrentVoucher(voucher);
                          await fetchAccountTransactions();
                          setIsCurrentVoucherLoading(false);
                          setToastModel((prev) => ({ ...prev, show: true }));
                          resetFormData();
                        }}
                        className="btn-gradient-primary mx-3 btn-fw"
                      >
                        {getLabelName(currentVoucherID ? "Update" : "Save")}
                      </ButtonBox>
                    </div>
                  </PrivilegesChecker>
                )}
              </div>
            </form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="1" className="mt-5">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            {/* <PrivilegesChecker FormId={FormId} action="EnableSearch">
              <div className="mb-4">
                <AcComplexTransactionSearch onActionEvent={handleAction} />
              </div>
            </PrivilegesChecker> */}
            <div className="row row-cols-1">
              <GridTable
                columnsProps={{
                  actions: [
                    ActionTypeEnum.Update,
                    ActionTypeEnum.Delete,
                    ActionTypeEnum.Print,
                  ],

                  isArabic,
                  onActionEvent,
                }}
                formId={formId}
                data={searchedTransactions}
                totalRows={totalRows}
                currentPage={currentPage}
                pageSize={currentPageSize}
                onCurrentPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
                paginationType="server"
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion html
};
