import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {
    AcBeginBalanceSelectModel,
    AccountResponseResultModel,
    LookupItemModel,
    ResponseBaseModel,
    ruleExpenseMoneyApiResponseModel,
    ruleExpenseMoneySearchModel,
    RuleReceiveMoneyAccountsModel,
    SearchAccountsRequestPayloadModel,
    SearchRuleExpenseMoneyRequestModel
} from "../models";
import {AlYuserApiUrls} from "../configuration";
import {handleError} from "../utils";
//#region get
export const searchRuleReceiveMoneyAccounts = async (request?: SearchRuleExpenseMoneyRequestModel): Promise<ResponseBaseModel<SearchRuleExpenseMoneyRequestModel[]>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.searchRuleReceiveMoneyAccounts, {params: AxiosSearchParams(request, 'searchItem')});
};
export const selectAcTransaction = async (id: number): Promise<ResponseBaseModel<ruleExpenseMoneyApiResponseModel>> => {
    const url: string = `${AlYuserApiUrls.accountController.getAccountInfoById}?accountId=${id}`;
    return await AlYusrAxiosApiInstance.get(url);
}
export const searchRuleExpenseMoneyAccounts = async (request?: SearchRuleExpenseMoneyRequestModel): Promise<ResponseBaseModel<SearchRuleExpenseMoneyRequestModel[]>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.searchRuleExpenseMoneyAccounts, {params: AxiosSearchParams(request, 'searchItem')});
};
export const getAccountsByMainAccountType = async (Id: any): Promise<LookupItemModel[]> => {
    try {
        let url: string = `${AlYuserApiUrls.accountController.getAccountsByMainAccountType}?accountType=${Id}`;
        const result: ResponseBaseModel<AcBeginBalanceSelectModel[]> =
            await AlYusrAxiosApiInstance.get(url);
        // @ts-ignore
        let response: LookupItemModel[] = []
        result.Result?.forEach((item) => {
            response.push({
                name: item.EnglishName,
                nameAr: item.ArabicName,
                value: item.ID,
                otherValue: item.AccountNatureType
            });
        });
        return response
    } catch (err) {
        alert(handleError(err));
    }
    return [];
};
export const getAllMainAccountType = async (): Promise<LookupItemModel[]> => {
    try {
        const result: ResponseBaseModel<AcBeginBalanceSelectModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.getAllMainAccountType);
        // @ts-ignore
        let response: LookupItemModel[] = []
        result.Result?.forEach((item) => {
            response.push({
                name: item.EnglishName,
                nameAr: item.ArabicName,
                value: item.ID
            });
        });
        return response
    } catch (err) {
        alert(handleError(err));
    }
    return [];
};
export const getCurrentAccountBalance = async (
    accountId: number,
    currencyId?: number | null
): Promise<number> => {
    try {
        if (accountId != 0) {
            let url: string = `${AlYuserApiUrls.accountController.getCurrentAccountBalance}?accountId=${accountId}`;
            url =
                currencyId !== null && currencyId !== undefined && currencyId !== 0
                    ? `${url}&scurrencyid=${currencyId}`
                    : url;
            const result: ResponseBaseModel<number> = await AlYusrAxiosApiInstance.get(
                url
            );
            // @ts-ignore
            return result !== null && result !== undefined ? result.Result : 0;
        }
    } catch (err) {
        alert(handleError(err));
    }
    return 0;
};
export const getAllBoxAccounts = async (): Promise<LookupItemModel[]> => {
    const result: ResponseBaseModel<RuleReceiveMoneyAccountsModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.getAllBoxAccounts);
    if (result.Errors?.length !== 0) throw "Error while fetching box accounts";
    return (result.Result as RuleReceiveMoneyAccountsModel[]).map(
        ({EnglishName, ID, ArabicName}) => ({
            name: EnglishName,
            value: "" + ID,
            nameAr: ArabicName,
        })
    );
};
export const getAllAccounts = async () => {
    const result: ResponseBaseModel<AccountResponseResultModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.getAllAccounts);
    if (result.Errors?.length !== 0) throw "Error while fetching accounts";
    return result.Result;
};
export const getAccountInfoById = async (accountId: number): Promise<ResponseBaseModel<AccountResponseResultModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.getAccountInfoById, {
        params: {accountId}
    });
};
export const searchAccounts = async (
    params: SearchAccountsRequestPayloadModel
): Promise<ResponseBaseModel<AccountResponseResultModel[]> | null> => {
    const response: ResponseBaseModel<AccountResponseResultModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.searchAccounts, {
            params: {...params},
        });
    if (response.Errors && response.Errors.length !== 0) throw response.Errors;
    return response || null;
};
//#endregion
//#region post
export const saveRuleExpenseMoney = async (request: ruleExpenseMoneySearchModel): Promise<ResponseBaseModel<ruleExpenseMoneySearchModel>> => {
    return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.accountController.saveRuleExpenseMoney, request)
}
export const saveRuleReceiveMoney = async (request: ruleExpenseMoneySearchModel): Promise<ResponseBaseModel<ruleExpenseMoneySearchModel>> => {
    return await AlYusrAxiosApiInstance.post(AlYuserApiUrls.accountController.saveRuleReceiveMoney, request)
}

export const saveAccount: (
    accountInfo: AccountResponseResultModel
) => Promise<ResponseBaseModel<AccountResponseResultModel>> = async (accountInfo) => {
    return await AlYusrAxiosApiInstance.post<
        ResponseBaseModel<AccountResponseResultModel>
    >(AlYuserApiUrls.accountController.saveAccount, accountInfo);
};
export const deleteAccount = async (
    id: number
): Promise<ResponseBaseModel<AccountResponseResultModel>> => {
    let apiResponse: ResponseBaseModel<AccountResponseResultModel>;
    let url: string = `${AlYuserApiUrls.accountController.deleteAccount}?id=${id}`;
    apiResponse = await AlYusrAxiosApiInstance.post(url);
    if (apiResponse.Errors?.length !== 0) throw apiResponse.Errors;
    return apiResponse;
};
//#endregion