import _ from "lodash";
import {Dispatch, SetStateAction} from "react";
import {
  ActionTypeEnum,
  ChangeItemValueTypeEnum,
  CompanySettingModel,
  CustomerResponseModel,
  DailyTransactionTypeEnum,
  ItemDiscountValuesModel,
  ItemTotalValuesModel,
  LookupItemKeyValueModel,
  LookupItemModel,
  LookupTypeEnum,
  RequestActionModel,
  RowStateEnum,
  StateGenericRequestModel,
  SupplierCustomerTypeEnum,
  TransactionDetailResponseModel,
  TransactionDiscountLevelTypeEnum,
  TransactionItemResponseModel,
  TransactionResponseModel,
  TransactionTotalValuesModel,
  UserTransactionsSettingResponseModel,
  ValidateTransactionModeEnum,
  ValidationErrorModel,
} from "../../../../models";
import {
  generateGuid,
  getLabelName,
  getOriginalValueInCurrency,
  getUserId,
  getUserStationId,
  getValueInCurrency,
  roundNumber,
  SecureLocalStorageGet,
  updateStateDynamically,
} from "../../../../utils";
import {SystemConfiguration} from "../../../../configuration";
import {getPointOfSaleSetting, getUserTransactionsSettings} from "../../../../serviceBroker/userApiServiceBroker";
import {validateTransaction} from "../../../../serviceBroker/transactionApiServiceBroker";
import {getCurrentAccountBalance} from "../../../../serviceBroker/accountApiServiceBroker";
import {getLookupByType} from "../../../../serviceBroker/lookupApiServiceBroker";
import {
  calculateItemDiscount,
  calculateItemPrice,
  calculateItemTax,
  calculateItemUnitPriceOrPriceWithTax,
  getItemInStoreId,
  getItemSalesPrice,
} from "./commonBl";
//#region variables
export const companySettingsInitialValues: CompanySettingModel = {
  Advert: undefined,
  ApplyTax: false,
  ArabicLanguage: "",
  AttachmentFolder: undefined,
  BacckGroundImage: "",
  BackColor: 0,
  BackGroundImageName: "",
  CalcDiscountWithVAT: false,
  CheckBalanceOfItemOnAdd: false,
  CompanyAddress: "",
  CompanySetting_UniqueId: "",
  Company_Address: "",
  CreatedBy: undefined,
  CreationDate: "",
  CurrencyShortCut: "",
  Currency_ID: 0,
  DateType: false,
  DaysOfDeleteBackupFiles: 0,
  DecimalPlace: 0,
  DefaultCurrency_ID: 0,
  DefaultTaxPercentage: 0,
  EnglishLanguage: "",
  Errors: [],
  FormatDate: "",
  FormatDecimal: "",
  Header: undefined,
  HideItemFromCloseDay: false,
  ID: 0,
  IncludeVatOnEditPrice: false,
  IsCodeTransactionSeparated: false,
  IsEnableToUploadEinvoice: false,
  IsFloorSecondeCurrency: false,
  IsSentEmailReportAfterCloseDayOnly: false,
  IsTrialVersion: false,
  IsUploadShipmentTrackToCloud: false,
  IsUploadTransactionToCloud: false,
  Is_Company_Authorized_Tobacco: false,
  LogoImage: "",
  LogoImage64: "",
  LogoImageName: "",
  Mail: "",
  MaxQuantity: 0,
  MobileReceiver: "",
  ModificationDate: "",
  ModifiedBy: undefined,
  Name: "",
  Password: "",
  PasswordSMS: "",
  PathBackUp: "",
  Phone: "",
  PrintItemArabicEngInBill: false,
  PrintItemPrescriptionOfTransaction: false,
  Printer_Tablet_List: "",
  RecivedMail: "",
  SendMailOfSalesOnClose: false,
  SendSMS: false,
  SenderName: "",
  ServiceUI: "",
  SetCostPriceZeroOnSalePriceZero: false,
  SetScaleOnPrice: false,
  SetScaleWeightonKG: false,
  ShowItemPriceWithoutTax: false,
  SoftwareName: undefined,
  SystemSettings: "",
  TaxNumber: "",
  TermsAndCondations: "",
  TextLogo: "",
  Token: undefined,
  TransferTaxToDiscount: false,
  UseOldModelForSearch: false,
  UserNameSMS: "",
  ValueOfCurrency: 0,
  ValueOfPoint: 0,
  VerifyOnUpdate: false,
  WaterMarkImage: "",
  rowState: 0,
  voucherSettings: undefined,
};
export const userTransactionsSettingInitialValues: UserTransactionsSettingResponseModel =
  {
    DeafultNote: "",
    DefaultCalcTypeID: 0,
    DefaultPaymentTypeID: 0,
    DirectPrintBillAfterSave: false,
    EnableButonAdd: false,
    EnableDiscount: false,
    EnablePrint: false,
    EnableShangPrice: false,
    ID: 0,
    Name: null,
    NumberOfCopy: 0,
    PeriodAllowForReturn: 0,
    PrinterName: "",
    PrinterPrepairingSalesInvoice: "",
    RemarksVisable: "",
    ShowButtonPrintPrepairingOrder: false,
    ShowCalcType: false,
    ShowCinfirm: false,
    ShowCostCenter: false,
    ShowCurrency: false,
    ShowCustomerCar: false,
    ShowDone: false,
    ShowEmployeeResponsibile: false,
    ShowNotefy: false,
    ShowPaymentType: false,
    ShowPrice: false,
    ShowPrintStickerButton: false,
    ShowRefrence: false,
    ShowSaveAndPrintButton: false,
    ShowTransPortCost: false,
    TransactionType_ID: 0,
    User_ID: 0,
    VerifyOnUpdate: false,
    rowState: 0,
  };
export const transactionItemInitialValues: TransactionItemResponseModel = {
  Categ_ID: null,
  ItemInstore_ID: null,
  ItemUnit_ID: null,

  ItemDiscountRatio: 0,
  rowKey: "",

  TransactionHeader_ID: 0,
  TransDetail_IDParent: 0,
  ItemName: "",
  ItemName_En: "",
  ItemCode: "",
  Item_Modifiers_Json: "",
  Modifiers: undefined,
  Quantity: 1,
  defaultQuantity: 1,

  Unit_Price: 0,
  UnitPriceWithTaxCalculated: 0,
  Tax: 0,
  TotalTax: 0,
  ItemDiscount: 0,
  Total: 0,

  Unit_PriceInCurrencyCalculated: 0,
  UnitPriceWithTaxInCurrency: 0,
  TaxInCurrency: 0,
  TotalTaxInCurrency: 0,
  ItemDiscountInCurrency: 0,
  TotalInCurrency: 0,

  ValueOfCurrency: 1,
  // get  Unit_PriceComputed(): number {
  //   return  this.Unit_Price*10
  // },
  PriceCost: 0,
  Factor: 0,
  Transaction_Type: 0,

  MaxQty: 0,
  TaxPercentge: 0,

  IsGatherItem: false,
  UseExpiryDate: false,
  WithoutBalance: false,
  IsFromGatherItem: false,
  NotesDetails: "",
  Unit: "",
  UnitName: "",
  IsPrinted: false,
  ExpiryDate: "",
  ID: 0,
  CreatedBy: 0,
  ModifiedBy: 0,
  rowState: Number(RowStateEnum.Add),
  UnitBalance: 0,
  barCode: "",
  changeValueType: ChangeItemValueTypeEnum.None,
  latestModifiedDate: new Date(),
};
export const transactionDetailInitialValue: TransactionDetailResponseModel = {
  Code: "",
  TransactionType: 0,
  CalcType_ID: null,
  InvoiceType: null,
  PaymentType_ID: null,
  Currency_ID: null,
  ValueCurrency: 1,
  Customer_ID: null,
  User_Update: 0,
  User_Create: getUserId(),
  Emp_ID: 0,
  Station_ID: getUserStationId(),
  Table_ID: 0,
  TempHeaderID: 0,
  Refrence: 0,
  IsPrinted: false,
  Date: new Date(),

  discountRatio: 0,
  Discount: 0,
  TotalMony: 0,
  TotalTax: 0,
  TotalNet: 0,
  DiscountInCurrency: 0,
  TotalMonyInCurrency: 0,
  TotalTaxInCurrency: 0,
  TotalNetInCurrency: 0,

  MoneyAdd: 0,
  CashMoney: 0,
  CardMoney: 0,
  DelayMoney: 0,
  OthersMoney: 0,
  Tobacco_Value: 0,

  balance: 0,
  //isReInitializeTransactionValues:false,
  TransactionDetaillist: [],

  ID: 0,
  CreatedBy: getUserId(),
  ModifiedBy: 0,
  rowState: Number(RowStateEnum.Add),

  //discountLevelType:TransactionDiscountLevelTypeEnum.ItemLevel,
  //isTransactionDiscountEnabled:false,
  //isItemDiscountEnabled:true,
};
export const customerInitialValue: CustomerResponseModel = {
  Code: 0,
  IDNumber: "",
  City_ID: 0,
  Address: "",
  Mobile: "",
  Phone: "",
  Mail: "",
  Notes: "",
  Cust_Type: Number(SupplierCustomerTypeEnum.Customer),
  Beg_AC_Bal: 0,
  AreaLand: "",
  User_ID: 0,
  Account_ID: 0,
  ISCustomerAndSupplier: false,
  PaymentTypeID: 0,
  Currency_ID: 0,
  ValueCurrency: 0,
  BalanceOfPoint: 0,
  IsDefault: false,
  MaxDebit: 0,
  Customer_UniqueID: 0,
  Name_En: "",
  TaxNumber: "",
  IsCopyToServer: true,
  Device_ID: 0,
  IsSponsor: false,
  Sponsor_Id: 0,
  IsActive: true,
  ProfitPercentage: 0,
  CustomerType: 0,

  address: {
    CountryCode: "",
    FlatNumber: "",
    Governate: "",
    CreatedBy: 0,
    CreationDate: new Date(),
    ModificationDate: new Date(),
    CustomerId: 0,
    Address_GUID: "",
    ID: 0,
    ModifiedBy: 0,
    Name: "",
    RegionCity: "",
    Remarks: "",
    Row_State: 1,
    Street: "",
    VerifyOnUpdate: false,
    buildingNumber: "",
    rowState: 1,
  },
  IsCreateAccountOnSaveNewOne: false,
  IsSkipSaveAccount: false,
  ID: 0,
  CreatedBy: 0,
  ModifiedBy: 0,
  Name: "",
  ModificationDate: new Date(),
  VerifyOnUpdate: false,
  rowState: 1,
};
//#endregion
//#region user transactions settings functions
export const initializeTransactionValuesAndUserTransactionsConfiguration =
  async (
    setUserSettingStateList: Dispatch<
      SetStateAction<UserTransactionsSettingResponseModel[]>
    >,
    setUserSettingStata: Dispatch<
      SetStateAction<UserTransactionsSettingResponseModel>
    >,
    setTransactionDetail: Dispatch<
      SetStateAction<TransactionDetailResponseModel>
    >,
    transactionDetail: TransactionDetailResponseModel,
    lookupList: LookupItemKeyValueModel[],
    transactionType: number,
    isSetState: boolean
    // cacheKey: string,
  ) => {
    const settingsList = await getUserTransactionsSettings(
      [
        DailyTransactionTypeEnum.SalesInvoice,
        DailyTransactionTypeEnum.PurchasesInvoice,
        DailyTransactionTypeEnum.SalesRefund,
        DailyTransactionTypeEnum.PurchasesRefund,
        DailyTransactionTypeEnum.Order,
        DailyTransactionTypeEnum.ShowPrice,
        DailyTransactionTypeEnum.POSSales,
        DailyTransactionTypeEnum.POSSalesRefund,
        DailyTransactionTypeEnum.POSRestaurant,
        DailyTransactionTypeEnum.Reserve_Restaurant_Bill,
        DailyTransactionTypeEnum.ReturnPOSRestaurant,
      ],
      true,
      SystemConfiguration.cacheKey.settingsList
    );
    const currentTransactionSetting = settingsList.filter(
      (p) => p.TransactionType_ID === transactionType
    )[0];
    const posSettingResult = await getPointOfSaleSetting(getUserId(),true); // Assuming this returns the response with customer_id

    if (isSetState) {
      setUserSettingStateList(settingsList);
      setUserSettingStata(currentTransactionSetting);
    }
    const customerId: number | null =posSettingResult.Customer_ID?? getLookupItemFirstDefaultValue(
      "customers",
      lookupList
    );
    const paymentTypeId: number | null = getLookupItemFirstDefaultValue(
      "payments",
      lookupList
    );
    const currencyId: number | null = getLookupItemFirstDefaultValue(
      "currencies",
      lookupList
    );
    const employeeId: number | null = getLookupItemFirstDefaultValue(
      "employees",
      lookupList
    );
    const calcTypeId: number | null = getLookupItemFirstDefaultValue(
      "calcTypes",
      lookupList
    );
    const costCenterId: number | null = getLookupItemFirstDefaultValue(
      "costCenters",
      lookupList
    );
    const currencyValueEntity: number = getCurrencyValueEntity(
      currencyId || 0,
      getLookupItemListFromDictionary("currencies", lookupList) || []
    );
    const customersList: LookupItemModel[] | null =
      getLookupItemListFromDictionary("customers", lookupList);
    const balance = await getAccountBalance(
      customerId !== null
        ? customerId
        : transactionDetail.Customer_ID !== null &&
          transactionDetail.Customer_ID !== undefined
        ? transactionDetail.Customer_ID
        : 0,
      currencyId !== null
        ? currencyId
        : transactionDetail.Currency_ID !== null &&
          transactionDetail.Currency_ID !== undefined
        ? transactionDetail.Currency_ID
        : null,
      customersList || []
    );
    setTransactionDetail({
      ...transactionDetail,
      Customer_ID:
        customerId !== null ? customerId : transactionDetail.Customer_ID,
      Currency_ID:
        currencyId !== null ? currencyId : transactionDetail.Currency_ID,
      CostCenter_ID:
        costCenterId !== null ? costCenterId : transactionDetail.CostCenter_ID,
      Emp_ID: employeeId !== null ? employeeId : transactionDetail.Emp_ID,
      ValueCurrency: currencyValueEntity,
      PaymentType_ID:
        currentTransactionSetting.DefaultPaymentTypeID !== 0
          ? currentTransactionSetting.DefaultPaymentTypeID
          : paymentTypeId !== null
          ? paymentTypeId
          : transactionDetail.PaymentType_ID,
      CalcType_ID:
        currentTransactionSetting.DefaultCalcTypeID !== 0
          ? currentTransactionSetting.DefaultCalcTypeID
          : calcTypeId !== null
          ? calcTypeId
          : transactionDetail.CalcType_ID,
      balance: balance,
      //isReInitializeTransactionValues:false,
    });
    //setUserSettingStata({...currentTransactionSetting});
    // if (transactionSettingButtonRef.current) {
    //   transactionSettingButtonRef.current.click();
    // }
  };
//#endregion
//#region companySetting functions
export const getCompanySettings = (): CompanySettingModel => {
  try {
    const companySettingObj = SecureLocalStorageGet(
      SystemConfiguration.keys.companySettings
    );
    return companySettingObj !== null && companySettingObj !== undefined
      ? (JSON.parse(
          SecureLocalStorageGet(
            SystemConfiguration.keys.companySettings
          ) as string
        ) as CompanySettingModel)
      : companySettingsInitialValues;
  } catch (err) {
    return companySettingsInitialValues;
  }
};
//#endregion
//#region lookup functions
export const handleSearchAndFilterItems = async (
  setItemsState: Dispatch<SetStateAction<LookupItemModel[]>>,
  setItemsPerCategoryState: Dispatch<SetStateAction<LookupItemModel[]>>,
  itemList: LookupItemModel[],
  itemPerCategoryList: LookupItemModel[],
  value: string,
  isArabic: boolean
) => {
  let resultList: LookupItemModel[];
  let isFetchedFromDb;
  ({ resultList, isFetchedFromDb } = await handleSearchItemsOrFetchFromDb(
    itemList,
    value,
    isArabic,
    false
  ));
  if (isFetchedFromDb) {
    if (
      resultList !== null &&
      resultList !== undefined &&
      resultList.length !== 0
    ) {
      const concatenatedItemsList: LookupItemModel[] =
        itemList.concat(resultList);
      const concatenatedItemsPerCategoryList: LookupItemModel[] =
        itemPerCategoryList.concat(resultList);
      setItemsState(concatenatedItemsList);
      setItemsPerCategoryState(concatenatedItemsPerCategoryList);
    }
  }
};
export const handleSearchAndFilterItemsUsingBarCode = async (
  setItemsState: Dispatch<SetStateAction<LookupItemModel[]>>,
  setItemsPerCategoryState: Dispatch<SetStateAction<LookupItemModel[]>>,
  setTransactionItemObject: Dispatch<
    SetStateAction<TransactionItemResponseModel>
  >,
  itemList: LookupItemModel[],
  itemPerCategoryList: LookupItemModel[],
  calcTypeList: LookupItemModel[],
  transactionDetailObject: TransactionDetailResponseModel,
  transactionItemObject: TransactionItemResponseModel,
  userTransactionSetting: UserTransactionsSettingResponseModel,
  transactionType: DailyTransactionTypeEnum,
  value: string,
  isArabic: boolean
) => {
  let resultList: LookupItemModel[];
  let concatenatedItemsList: LookupItemModel[] = [];
  let concatenatedItemsPerCategoryList: LookupItemModel[] = [];
  let isFetchedFromDb;
  ({ resultList, isFetchedFromDb } = await handleSearchItemsOrFetchFromDb(
    itemList,
    value,
    isArabic,
    true
  ));
  if (isFetchedFromDb) {
    if (
      resultList !== null &&
      resultList !== undefined &&
      resultList.length !== 0
    ) {
      concatenatedItemsList = itemList.concat(resultList);
      concatenatedItemsPerCategoryList = itemPerCategoryList.concat(resultList);
      setItemsState(concatenatedItemsList);
      setItemsPerCategoryState(concatenatedItemsPerCategoryList);
    }
  }
  if (
    resultList !== null &&
    resultList !== undefined &&
    resultList.length !== 0
  ) {
    const transactionItemObj = handleChangeNewRecordItemCalculation(
      {
        ...transactionItemObject,
        ItemUnit_ID: Number(resultList[0].otherValue.itemUnitId),
      },
      transactionDetailObject,
      userTransactionSetting,
      transactionType,
      concatenatedItemsList,
      calcTypeList
    );
    transactionItemObj.barCode = value;
    await setTransactionItemObject(transactionItemObj);
  } else {
    transactionItemObject.barCode = value;
    await setTransactionItemObject(transactionItemObject);
  }
};
export const getLookUp = async (
  state: Dispatch<SetStateAction<any>>[],
  lookup: LookupTypeEnum,
  isCached: boolean = true,
  addEmptySelect: boolean = false
): Promise<LookupItemModel[]> => {
  const lookupList = await getLookupByType(lookup, isCached, addEmptySelect);
  state.forEach((setStateRow) => {
    setStateRow(lookupList);
  });
  return lookupList;
};
export const getLookUpWithStateUpdateForResultOfSingleRow = async (
  state: Dispatch<SetStateAction<any>>[],
  lookup: LookupTypeEnum,
  updateState: StateGenericRequestModel[],
  isCached: boolean = true,
  addEmptySelect: boolean = false
): Promise<LookupItemModel[]> => {
  const lookupList = await getLookupByType(lookup, isCached, addEmptySelect);
  state.forEach((setStateRow) => {
    setStateRow(lookupList);
  });
  if (
    lookupList.length === 1 &&
    updateState !== null &&
    updateState !== undefined &&
    updateState.length !== 0
  ) {
    const value: any = lookupList[0].value;
    updateState.forEach((setStaterow) => {
      if (
        setStaterow.filed !== null &&
        setStaterow.filed !== undefined &&
        setStaterow.filed !== ""
      ) {
        updateStateDynamically(
          setStaterow.state,
          setStaterow.value,
          setStaterow.filed,
          value
        );
      }
    });
  }
  return lookupList;
};
//#endregion
//#region calculation functions
export const handleChangeNewRecordItemCalculation = (
  request: TransactionItemResponseModel,
  transactionRequest: TransactionDetailResponseModel,
  userTransactionSetting: UserTransactionsSettingResponseModel,
  transactionType: DailyTransactionTypeEnum,
  itemList: LookupItemModel[],
  calcTypeList: LookupItemModel[]
): TransactionItemResponseModel => {
  const itemInStoreId: number | null = getItemInStoreId(
    Number(request.ItemUnit_ID),
    itemList
  );
  const itemSalePrice: number =
    itemInStoreId !== null
      ? getItemSalesPrice(
          Number(request.ItemUnit_ID || 0),
          transactionType,
          transactionRequest.CalcType_ID !== null &&
            transactionRequest.CalcType_ID !== undefined
            ? transactionRequest.CalcType_ID
            : null,
          itemList,
          calcTypeList,
          userTransactionSetting
        )
      : 0;
  const itemPriceCalculatedValues = handleCalculateNewItemValuesChange(
    itemList,
    Number(request.ItemUnit_ID || 0),
    itemSalePrice,
    transactionRequest.ValueCurrency,
    true,
    false
  );
  const discountCalculatedValues = handleCalculateNewItemDiscountValuesChange(
    request.ItemDiscount,
    transactionRequest.ValueCurrency,
    false
  );
  const itemTaxPercentage =
    itemInStoreId !== null
      ? getItemTaxPercentageValue(Number(request.ItemUnit_ID || 0), itemList)
      : 0;
  request.ItemInstore_ID = itemInStoreId;
  request.TaxPercentge = itemTaxPercentage;
  request.TaxPercentge = itemTaxPercentage;
  request.Unit_Price = itemPriceCalculatedValues.UnitPrice;
  request.UnitPriceWithTaxCalculated =
    itemPriceCalculatedValues.UnitPriceWithTax;
  request.Unit_PriceInCurrencyCalculated =
    itemPriceCalculatedValues.UnitPriceInCurrency;
  request.UnitPriceWithTaxInCurrency =
    itemPriceCalculatedValues.UnitPriceWithTaxInCurrency;
  request.ItemDiscount = discountCalculatedValues.ItemDiscount;
  request.ItemDiscountInCurrency =
    discountCalculatedValues.ItemDiscountInCurrency;
  return request;
};
export const handleTransactionTotalValues = (
  request: TransactionDetailResponseModel
): TransactionDetailResponseModel => {
  let totalValuesResponse: TransactionTotalValuesModel = {
    totalMoney: 0,
    totalTax: 0,
    totalDiscount: 0,
    totalNet: 0,
    totalMoneyInCurrency: 0,
    totalTaxInCurrency: 0,
    totalDiscountInCurrency: 0,
    totalNetInCurrency: 0,
    totalDiscountPercentage: 0,
  };
  let transportCost: number = 0;
  let moneyAdd: number = 0;
  let tobaccoValue: number = 0;
  let totalAdditionalCostValue: number = 0;
  if (
    request !== null &&
    request !== undefined &&
    request.TransactionDetaillist !== null &&
    request.TransactionDetaillist !== undefined &&
    request.TransactionDetaillist.length !== 0
  ) {
    request.TransactionDetaillist.filter(
      (p) => p.rowState !== RowStateEnum.Delete
    ).forEach((row) => {
      const rowMoney: number = row.Unit_Price * row.Quantity;
      const rowTax = row.Tax * row.Quantity;
      totalValuesResponse.totalMoney = roundNumber(
        totalValuesResponse.totalMoney + rowMoney
      );
      totalValuesResponse.totalTax = roundNumber(
        totalValuesResponse.totalTax + rowTax
      );
      totalValuesResponse.totalDiscount = roundNumber(
        totalValuesResponse.totalDiscount + row.ItemDiscount
      );

      totalValuesResponse.totalMoneyInCurrency =
        totalValuesResponse.totalMoneyInCurrency +
        getValueInCurrency(rowMoney, row.ValueOfCurrency);
      totalValuesResponse.totalTaxInCurrency =
        totalValuesResponse.totalTaxInCurrency +
        getValueInCurrency(rowTax, row.ValueOfCurrency);
      totalValuesResponse.totalDiscountInCurrency =
        totalValuesResponse.totalDiscountInCurrency +
        getValueInCurrency(row.ItemDiscount, row.ValueOfCurrency);
    });
    totalValuesResponse.totalDiscountPercentage =
      totalValuesResponse.totalDiscount === 0
        ? 0
        : roundNumber(
            (totalValuesResponse.totalDiscount /
              totalValuesResponse.totalMoney) *
              100
          );

    totalValuesResponse.totalNet = roundNumber(
      totalValuesResponse.totalMoney -
        totalValuesResponse.totalDiscount +
        transportCost +
        moneyAdd +
        totalValuesResponse.totalTax +
        tobaccoValue +
        totalAdditionalCostValue
    );
    totalValuesResponse.totalNetInCurrency = roundNumber(
      totalValuesResponse.totalMoneyInCurrency -
        totalValuesResponse.totalDiscountInCurrency +
        transportCost +
        moneyAdd +
        totalValuesResponse.totalTaxInCurrency +
        tobaccoValue +
        totalAdditionalCostValue
    );
  }
  request.TotalMony = roundNumber(totalValuesResponse.totalMoney);
  request.TotalMonyInCurrency = roundNumber(
    totalValuesResponse.totalMoneyInCurrency
  );
  request.TotalNet = roundNumber(totalValuesResponse.totalNet);
  request.TotalNetInCurrency = roundNumber(
    totalValuesResponse.totalNetInCurrency
  );
  request.TotalTax = roundNumber(totalValuesResponse.totalTax);
  request.TotalTaxInCurrency = roundNumber(
    totalValuesResponse.totalTaxInCurrency
  );
  request.Discount = roundNumber(totalValuesResponse.totalDiscount);
  request.DiscountInCurrency = roundNumber(
    totalValuesResponse.totalDiscountInCurrency
  );
  request.discountRatio = roundNumber(
    totalValuesResponse.totalDiscountPercentage
  );
  return request;
};
export const handleTransactionTotalValuesByTransactionItems = (
  request: TransactionItemResponseModel[]
): TransactionTotalValuesModel => {
  let response: TransactionTotalValuesModel = {
    totalMoney: 0,
    totalTax: 0,
    totalDiscount: 0,
    totalNet: 0,
    totalMoneyInCurrency: 0,
    totalTaxInCurrency: 0,
    totalDiscountInCurrency: 0,
    totalNetInCurrency: 0,
    totalDiscountPercentage: 0,
  };
  let transportCost: number = 0;
  let moneyAdd: number = 0;
  let tobaccoValue: number = 0;
  let totalAdditionalCostValue: number = 0;
  if (request !== null && request !== undefined && request.length !== 0) {
    request
      .filter((p) => p.rowState !== RowStateEnum.Delete)
      .forEach((row) => {
        const rowMoney: number = row.Unit_Price * row.Quantity;
        const rowTax = row.Tax * row.Quantity;
        response.totalMoney = roundNumber(response.totalMoney + rowMoney);
        response.totalTax = roundNumber(response.totalTax + rowTax);
        response.totalDiscount = roundNumber(
          response.totalDiscount + row.ItemDiscount
        );

        response.totalMoneyInCurrency =
          response.totalMoneyInCurrency +
          getValueInCurrency(rowMoney, row.ValueOfCurrency);
        response.totalTaxInCurrency =
          response.totalTaxInCurrency +
          getValueInCurrency(rowTax, row.ValueOfCurrency);
        response.totalDiscountInCurrency =
          response.totalDiscountInCurrency +
          getValueInCurrency(row.ItemDiscount, row.ValueOfCurrency);
      });

    response.totalDiscountPercentage =
      response.totalDiscount === 0
        ? 0
        : roundNumber((response.totalDiscount / response.totalMoney) * 100);

    response.totalNet = roundNumber(
      response.totalMoney -
        response.totalDiscount +
        transportCost +
        moneyAdd +
        response.totalTax +
        tobaccoValue +
        totalAdditionalCostValue
    );
    response.totalNetInCurrency = roundNumber(
      response.totalMoneyInCurrency -
        response.totalDiscountInCurrency +
        transportCost +
        moneyAdd +
        response.totalTaxInCurrency +
        tobaccoValue +
        totalAdditionalCostValue
    );
  }
  return response;
};
export const updateTransactionItemsCurrencyValueAndReCalculateAllValues = (
  request: TransactionItemResponseModel[],
  currencyValue: number
): TransactionItemResponseModel[] => {
  let result: TransactionItemResponseModel[] = [];
  if (request !== null && request !== undefined && request.length !== 0) {
    result = _.map(request, (row) => {
      if (row.rowState !== RowStateEnum.Delete) {
        row.ValueOfCurrency = currencyValue;
        row.changeValueType = ChangeItemValueTypeEnum.ChangePrice;
        const record = handleCalculateItemRecordValuesUpdate(row);
        return { ...record, changeValueType: ChangeItemValueTypeEnum.None };
      } else {
        return row;
      }
    });
  }
  return result;
};
export const handleCalculateNewItemValuesChange = (
  itemList: LookupItemModel[],
  itemId: number,
  value: number,
  currencyValueEntity: number,
  isTaxCalculation: boolean,
  isCalculateInCurrencyValue: boolean
): ItemTotalValuesModel => {
  let response: ItemTotalValuesModel = {
    UnitPrice: 0,
    UnitPriceInCurrency: 0,
    UnitPriceWithTax: 0,
    UnitPriceWithTaxInCurrency: 0,
  };
  if (itemId !== 0) {
    const itemTaxPercentage: number = getItemTaxPercentage(itemId, itemList);
    const calculatedValue: number = calculateItemUnitPriceOrPriceWithTax(
      value,
      itemTaxPercentage,
      isTaxCalculation
    );
    if (isCalculateInCurrencyValue) {
      response.UnitPriceInCurrency = isTaxCalculation ? value : calculatedValue;
      response.UnitPriceWithTaxInCurrency = isTaxCalculation
        ? calculatedValue
        : value;
      response.UnitPrice = getOriginalValueInCurrency(
        response.UnitPriceInCurrency,
        currencyValueEntity
      );
      response.UnitPriceWithTax = getOriginalValueInCurrency(
        response.UnitPriceWithTaxInCurrency,
        currencyValueEntity
      );
    } else {
      response.UnitPrice = isTaxCalculation ? value : calculatedValue;
      response.UnitPriceWithTax = isTaxCalculation ? calculatedValue : value;
      response.UnitPriceInCurrency = getValueInCurrency(
        response.UnitPrice,
        currencyValueEntity
      );
      response.UnitPriceWithTaxInCurrency = getValueInCurrency(
        response.UnitPriceWithTax,
        currencyValueEntity
      );
    }
  }
  return response;
};
export const handleCalculateItemRecordValuesUpdate = (
  request: TransactionItemResponseModel
): TransactionItemResponseModel => {
  switch (request.changeValueType) {
    case ChangeItemValueTypeEnum.ChangePrice:
      request.UnitPriceWithTaxCalculated = calculateItemUnitPriceOrPriceWithTax(
        request.Unit_Price,
        request.TaxPercentge,
        true
      );
      break;
    case ChangeItemValueTypeEnum.ChangePriceInCurrency:
      request.UnitPriceWithTaxInCurrency = calculateItemUnitPriceOrPriceWithTax(
        request.Unit_PriceInCurrencyCalculated,
        request.TaxPercentge,
        true
      );
      break;
    case ChangeItemValueTypeEnum.ChangePriceWithTax:
      request.Unit_Price = calculateItemUnitPriceOrPriceWithTax(
        request.UnitPriceWithTaxCalculated,
        request.TaxPercentge,
        false
      );
      break;
    case ChangeItemValueTypeEnum.ChangePriceWithTaxInCurrency:
      request.Unit_PriceInCurrencyCalculated =
        calculateItemUnitPriceOrPriceWithTax(
          request.UnitPriceWithTaxInCurrency,
          request.TaxPercentge,
          false
        );
      break;
    case ChangeItemValueTypeEnum.ChangeDiscount:
      request.ItemDiscountInCurrency = getValueInCurrency(
        request.ItemDiscount,
        request.ValueOfCurrency
      );
      break;
    case ChangeItemValueTypeEnum.ChangeDiscountInCurrency:
      request.ItemDiscount = getOriginalValueInCurrency(
        request.ItemDiscountInCurrency,
        request.ValueOfCurrency
      );
      break;
    case ChangeItemValueTypeEnum.All:
      break;
  }
  switch (request.changeValueType) {
    case ChangeItemValueTypeEnum.ChangePrice:
    case ChangeItemValueTypeEnum.ChangePriceWithTax:
      request.ItemDiscountInCurrency = getValueInCurrency(
        request.ItemDiscount,
        request.ValueOfCurrency
      );
      request.Unit_PriceInCurrencyCalculated = getValueInCurrency(
        request.Unit_Price,
        request.ValueOfCurrency
      );
      request.UnitPriceWithTaxInCurrency = getValueInCurrency(
        request.UnitPriceWithTaxCalculated,
        request.ValueOfCurrency
      );
      break;
    case ChangeItemValueTypeEnum.ChangePriceInCurrency:
    case ChangeItemValueTypeEnum.ChangePriceWithTaxInCurrency:
      request.ItemDiscount = getOriginalValueInCurrency(
        request.ItemDiscountInCurrency,
        request.ValueOfCurrency
      );
      request.Unit_Price = getOriginalValueInCurrency(
        request.Unit_PriceInCurrencyCalculated,
        request.ValueOfCurrency
      );
      request.UnitPriceWithTaxCalculated = getOriginalValueInCurrency(
        request.UnitPriceWithTaxInCurrency,
        request.ValueOfCurrency
      );
      break;
  }

  request.Tax = calculateItemTax(request);
  request.TotalTax = request.Tax * request.Quantity;
  request.Total = calculateItemPrice(request);
  request.TaxInCurrency = getValueInCurrency(
    request.Tax,
    request.ValueOfCurrency
  );
  request.TotalTaxInCurrency = getValueInCurrency(
    request.TotalTax,
    request.ValueOfCurrency
  );
  request.TotalInCurrency = getValueInCurrency(
    request.Total,
    request.ValueOfCurrency
  );
  return { ...request };
};
export const handleCalculateNewItemDiscountValuesChange = (
  value: number,
  currencyValueEntity: number,
  isCalculateInCurrencyValue: boolean
): ItemDiscountValuesModel => {
  let response: ItemDiscountValuesModel = {
    ItemDiscount: 0,
    ItemDiscountInCurrency: 0,
  };
  response.ItemDiscount = isCalculateInCurrencyValue
    ? getOriginalValueInCurrency(value, currencyValueEntity)
    : value;
  response.ItemDiscountInCurrency = isCalculateInCurrencyValue
    ? value
    : getValueInCurrency(value, currencyValueEntity);
  return response;
};
export const handleChangeTransactionDiscountRatio = async (
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel
  // value:number
) => {
  let obj = stateValue.TransactionDetaillist;
  obj = _.map(obj, (row) => {
    row.ItemDiscountRatio = stateValue.discountRatio;

    if (stateValue.discountRatio !== 0) {
      const calculatedDiscount = calculateItemDiscount(row);
      row.ItemDiscount = calculatedDiscount.ItemDiscount;
      row.ItemDiscountInCurrency = calculatedDiscount.ItemDiscountInCurrency;
    } else {
      row.ItemDiscount = 0;
      row.ItemDiscountInCurrency = 0;
    }
    row.Total = calculateItemPrice(row);
    row.TotalInCurrency = getValueInCurrency(row.Total, row.ValueOfCurrency);
    return row;
  });
  const stateObject = handleTransactionTotalValues({
    ...stateValue,
    TransactionDetaillist: obj,
  });
  setState({
    ...stateObject,
    //discountRatio: value,
    TransactionDetaillist: obj,
  });
};
export const handleChangeTransactionDiscount = async (
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel
  //value:number
) => {
  let obj = stateValue.TransactionDetaillist;
  const itemDiscountValueInCurrency = roundNumber(
    stateValue.DiscountInCurrency / obj.length
  );
  obj = _.map(obj, (row) => {
    row.ItemDiscountInCurrency = itemDiscountValueInCurrency;
    row.ItemDiscount = getOriginalValueInCurrency(
      itemDiscountValueInCurrency,
      row.ValueOfCurrency
    );
    row.Total = calculateItemPrice(row);
    row.TotalInCurrency = getValueInCurrency(row.Total, row.ValueOfCurrency);
    return row;
  });
  const stateObject = handleTransactionTotalValues({
    ...stateValue,
    TransactionDetaillist: obj,
  });
  setState({
    ...stateObject,
    TransactionDetaillist: obj,
  });
};
export const handleUpdateExistingTransactionValues = (
  request: TransactionItemResponseModel
) => {
  //request.UnitPriceWithTax=calculateItemUnitPriceOrPriceWithTax(request.Unit_Price,request.TaxPercentge,true);
  //request.Tax=calculateItemTax(request);
  //request.TotalTax=request.Tax*request.Quantity;
  //request.Total = calculateItemPrice(request);
  request.Unit_PriceInCurrencyCalculated = getValueInCurrency(
    request.Unit_Price,
    request.ValueOfCurrency
  );
  request.UnitPriceWithTaxInCurrency = getValueInCurrency(
    request.UnitPriceWithTaxCalculated,
    request.ValueOfCurrency
  );
  request.ItemDiscountInCurrency = getValueInCurrency(
    request.ItemDiscount,
    request.ValueOfCurrency
  );
  request.TaxInCurrency = getValueInCurrency(
    request.Tax,
    request.ValueOfCurrency
  );
  request.TotalTaxInCurrency = getValueInCurrency(
    request.TotalTax,
    request.ValueOfCurrency
  );
  request.TotalInCurrency = getValueInCurrency(
    request.Total,
    request.ValueOfCurrency
  );
  request.rowKey = generateGuid();
  return { ...request };
};
export const getItemTaxPercentageValue = (
  id: number,
  itemList: LookupItemModel[]
): number => {
  const item = itemList.filter(
    //@ts-ignore
    (p) => p.value === id.toString()
  )[0];
  return item != null &&
    item.otherValue !== null &&
    item.otherValue !== undefined
    ? item.otherValue.taxPercentage
    : 0;
};
//#endregion
//#region validation functions
export const validateTransactionObject = async (
  row: TransactionResponseModel,
  mode: ValidateTransactionModeEnum,
  onActionEvent: (o: RequestActionModel) => void
): Promise<boolean> => {
  let result = await validateTransaction(
    row.id,
    row.creationDate,
    row.totalTax,
    mode
  );
  if (
    result.Errors !== null &&
    result.Errors !== undefined &&
    result.Errors.length !== 0
  ) {
    //return  true;
    result.Result = false;
    onActionEvent({
      id: 0,
      request: result.Errors,
      action: ActionTypeEnum.RaiseError,
    });
  }
  return result.Result || false;
};
export const validateRefundSubmitRequestAdditionalValidation = async (
  request: TransactionDetailResponseModel
): Promise<ValidationErrorModel[]> => {
  let errors: ValidationErrorModel[] = [];
  request.TransactionDetaillist.forEach((row) => {
    if (row.Quantity > row.MaxQty) {
      errors.push({
        MessageAr: `${getLabelName("max Quantity Exceeded")} ${row.ItemName}`,
        MessageEn: `${getLabelName("max Quantity Exceeded")} ${
          row.ItemName_En
        }`,
      });
    }
  });
  return errors;
};
export const validateSubmitRequest = async (
  request: TransactionDetailResponseModel
): Promise<ValidationErrorModel[]> => {
  let errors: ValidationErrorModel[] = [];
  if (request.Customer_ID === null || request.Customer_ID === undefined) {
    errors.push({
      MessageAr: getLabelName("customerid missing"),
      MessageEn: getLabelName("customerid missing"),
    });
  }
  if (request.Date === null || request.Date === undefined) {
    errors.push({
      MessageAr: getLabelName("date missing"),
      MessageEn: getLabelName("date missing"),
    });
  }
  return errors;
};
//#endregion
//#region post functions
export const addItem = async (
  request: TransactionItemResponseModel,
  data: TransactionDetailResponseModel,
  itemList: LookupItemModel[]
): Promise<TransactionItemResponseModel> => {
  try {
    const index = _.findIndex(data.TransactionDetaillist, {
      ItemUnit_ID: request.ItemUnit_ID,
    });
    if (index !== -1) {
      const newItem = request;
      request = data.TransactionDetaillist[index];
      request.Quantity = Number(request.Quantity) + Number(newItem.Quantity);
      request.Unit_Price = newItem.Unit_Price;
      request.Unit_PriceInCurrencyCalculated =
        newItem.Unit_PriceInCurrencyCalculated;
      request.ItemDiscount =
        newItem.ItemDiscount !== 0
          ? newItem.ItemDiscount
          : request.ItemDiscount;
      request.ItemDiscountInCurrency =
        newItem.ItemDiscountInCurrency !== 0
          ? newItem.ItemDiscountInCurrency
          : request.ItemDiscountInCurrency;
    }
    request.ItemInstore_ID =
      request.ItemInstore_ID === null || request.ItemInstore_ID === undefined
        ? 0
        : request.ItemInstore_ID;

    const item = itemList.filter(
      //@ts-ignore
      (p) => p.value === request.ItemUnit_ID.toString()
    )[0];
    if (item !== null && item !== undefined) {
      request.ValueOfCurrency = data.ValueCurrency;
      request.ItemName = item.nameAr;
      request.ItemName_En = item.name;
      request.ItemCode = item.otherValue.code;
      request.Categ_ID =
        item.otherValue != null && item.otherValue.categoryId !== null
          ? Number(item.otherValue.categoryId)
          : null;
      request.UnitBalance =
        item.otherValue != null && item.otherValue.balance !== null
          ? Number(item.otherValue.balance)
          : null;
      request.TaxPercentge =
        item.otherValue != null && item.otherValue.taxPercentage !== null
          ? Number(item.otherValue.taxPercentage)
          : 0;
      request.CreatedBy = request.ID === 0 ? getUserId() : request.CreatedBy;
      request.ModifiedBy = request.ID !== 0 ? getUserId() : request.ModifiedBy;
      request.Factor = item.otherValue.factor;

      request.ItemDiscountInCurrency = request.ItemDiscountInCurrency || 0;
      request.ItemDiscount = request.ItemDiscount || 0;
      request.ItemDiscountRatio = request.ItemDiscountRatio || 0; //(data.discountRatio || 0);
      const calculatedDiscount: ItemDiscountValuesModel =
        calculateItemDiscount(request);
      request.ItemDiscount = calculatedDiscount.ItemDiscount; // calculateItemDiscount(request);
      request.ItemDiscountInCurrency =
        calculatedDiscount.ItemDiscountInCurrency;

      request.Unit_PriceInCurrencyCalculated = getValueInCurrency(
        request.Unit_Price,
        data.ValueCurrency
      );
      request.UnitPriceWithTaxCalculated = calculateItemUnitPriceOrPriceWithTax(
        request.Unit_Price,
        request.TaxPercentge,
        true
      );
      request.UnitPriceWithTaxInCurrency = getValueInCurrency(
        request.UnitPriceWithTaxCalculated,
        data.ValueCurrency
      );
      request.Tax = calculateItemTax(request);
      request.TaxInCurrency = getValueInCurrency(
        request.Tax,
        data.ValueCurrency
      );
      request.TotalTax = request.Tax * request.Quantity;
      request.TotalTaxInCurrency = getValueInCurrency(
        request.TotalTax,
        data.ValueCurrency
      );
      request.Total = calculateItemPrice(request);
      request.TotalInCurrency = getValueInCurrency(
        request.Total,
        data.ValueCurrency
      );
    }
    request.rowKey = generateGuid();
  } catch (e) {}
  //@ts-ignore
  return request;
};
export const handleDeleteItem = async (
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel,
  deleteItem: TransactionItemResponseModel
) => {
  const itemsList = await deleteItemRow(
    deleteItem,
    stateValue.TransactionDetaillist
  );

  const stateObject = handleTransactionTotalValues({
    ...stateValue,
    TransactionDetaillist: itemsList,
  });

  setState({
    ...stateObject,
    TransactionDetaillist: itemsList,
  });

  // setState({
  //   ...stateValue,
  //   TransactionDetaillist: result,
  // });
};
export const deleteItemRow = async (
  deleteItem: TransactionItemResponseModel,
  data: TransactionItemResponseModel[]
): Promise<TransactionItemResponseModel[]> => {
  if (deleteItem !== null && deleteItem !== undefined) {
    if (deleteItem.ID === 0) {
      data = _.filter(data, (row) => {
        return row.rowKey !== deleteItem.rowKey;
      });
    } else {
      data = _.map(data, (row) => {
        if (row.ID === deleteItem.ID) {
          return { ...row, rowState: Number(RowStateEnum.Delete) };
        } else {
          return row;
        }
      });
    }
  }
  return data;
};
//#endregion
//#region State functions
export const UpdateTransactionDetailDataBasedOnUserSetting = (
  request: TransactionDetailResponseModel,
  setting: UserTransactionsSettingResponseModel,
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>
) => {
  let count: number = 0;
  if (
    (request.PaymentType_ID === null || request.PaymentType_ID === undefined) &&
    setting.ShowPaymentType === false &&
    setting.DefaultPaymentTypeID !== null
  ) {
    request.PaymentType_ID = setting.DefaultPaymentTypeID;
    count++;
  }

  if (
    (request.CalcType_ID === null || request.CalcType_ID === undefined) &&
    setting.ShowCalcType === false &&
    setting.DefaultCalcTypeID !== null
  ) {
    request.CalcType_ID = setting.DefaultCalcTypeID;
    count++;
  }

  if (count > 0) {
    setState(request);
  }
};
// export const updateStateDynamically = async (
//     setState: Dispatch<SetStateAction<any>>,
//     stateValue: any,
//     key?: string | null,
//     value?: any | null,
//     keyValues?: KeyValueState[] | null,
// ) => {
//     if (value !== null && value !== undefined && key !== null && key !== undefined) {
//         setState({
//             ...stateValue,
//             [key]: value,
//         });
//     }
//     if (keyValues !== null && keyValues !== undefined && keyValues.length !== 0) {
//         keyValues.forEach((row) => {
//             stateValue = {...stateValue, [row.key]: row.value}
//         });
//         setState({...stateValue})
//     }
// }
export const updateStateDynamicallyFromLookupList = async (
  list: LookupItemModel[],
  setState: Dispatch<SetStateAction<any>>,
  stateValue: any,
  key?: string | null
) => {
  if (list !== null && list !== undefined && list.length != 0) {
    await updateStateDynamically(
      setState,
      stateValue,
      key,
      list[0].value,
      null
    );
  }
};
//#endregion
//#region customer functions
export const getAccountBalance = async (
  id: number,
  currencyId: number | null,
  list: LookupItemModel[]
): Promise<number> => {
  try {
    if (id !== 0) {
      const accountId: number | null = list.filter(
        (p) => p.value == id.toString()
      )[0].otherValue.accountId;
      return await getCurrentAccountBalance(accountId || 0, currencyId);
    }
  } catch (err) {}
  return 0;
};
//#endregion
//#region items & categories functions
export const fillItemListPerCategory = async (
  categoryId: string | null,
  setItemsState: Dispatch<SetStateAction<LookupItemModel[]>>,
  items: LookupItemModel[]
) => {
  const itemsPerCategoryList: LookupItemModel[] =
    categoryId !== null && categoryId !== undefined && categoryId !== ""
      ? items.filter(
          (p) =>
            p.value !== null && p.otherValue.categoryId === Number(categoryId)
        )
      : items;

  setItemsState(itemsPerCategoryList);
};
export const getItemTaxPercentage = (
  value: number | null,
  itemList: LookupItemModel[]
): number => {
  if (value !== null && value !== undefined && value != 0) {
    const item = itemList.filter(
      //@ts-ignore
      (p) => p.value === value.toString()
    )[0];
    return item !== null &&
      item !== undefined &&
      item.otherValue != null &&
      item.otherValue.taxPercentage !== null
      ? Number(item.otherValue.taxPercentage)
      : 0;
  }
  return 0;
};
//#endregion
//#region user functions
export const UpdateTransactionActionUser = (
  request: TransactionDetailResponseModel
): TransactionDetailResponseModel => {
  request.Station_ID =
    request.Station_ID === 0 ||
    request.Station_ID === null ||
    request.Station_ID === undefined
      ? getUserStationId()
      : request.Station_ID;
  request.CreatedBy =
    request.rowState === Number(RowStateEnum.Add) &&
    (request.CreatedBy === 0 ||
      request.CreatedBy === null ||
      request.CreatedBy === undefined)
      ? getUserId()
      : request.CreatedBy;
  request.User_Create =
    request.rowState === Number(RowStateEnum.Add) &&
    (request.User_Create === 0 ||
      request.User_Create === null ||
      request.User_Create === undefined)
      ? getUserId()
      : request.User_Create;
  request.ModifiedBy =
    request.rowState === Number(RowStateEnum.Update) &&
    (request.ModifiedBy === 0 ||
      request.ModifiedBy === null ||
      request.ModifiedBy === undefined)
      ? getUserId()
      : request.ModifiedBy;
  request.User_Update =
    request.rowState === Number(RowStateEnum.Update) &&
    (request.User_Update === 0 ||
      request.User_Update === null ||
      request.User_Update === undefined)
      ? getUserId()
      : request.User_Update;
  return request;
};
//#endregion
//#region currency functions
export const getCurrencyValueEntity = (
  currencyId: number,
  currencyList: LookupItemModel[]
): number => {
  if (currencyId !== 0 && currencyList !== null && currencyList !== undefined) {
    const currencyObject = currencyList.filter(
      (p) => p.value === currencyId.toString()
    )[0];
    if (
      currencyObject !== null &&
      currencyObject !== undefined &&
      currencyObject.otherValue !== null &&
      currencyObject.otherValue !== undefined &&
      currencyObject.otherValue.ValueEntity !== null &&
      currencyObject.otherValue.ValueEntity !== undefined
    ) {
      return currencyObject.otherValue.ValueEntity || 1;
    }
  }
  return 1;
};
//#endregion
//#region control editable functions
export const handleTransactionDiscountEditable = (
  isSettingDiscountEnabled: boolean,
  discountLevelType: TransactionDiscountLevelTypeEnum
): boolean => {
  return (
    isSettingDiscountEnabled &&
    discountLevelType === TransactionDiscountLevelTypeEnum.TransactionLevel
  );
};
export const handleItemDiscountEditable = (
  isSettingDiscountEnabled: boolean,
  discountLevelType: TransactionDiscountLevelTypeEnum
): boolean => {
  return (
    isSettingDiscountEnabled &&
    discountLevelType === TransactionDiscountLevelTypeEnum.ItemLevel
  );
};
export const isDiscountControlEditable = (
  isSettingDiscountEnabled: boolean,
  isDiscountEnabled: boolean
): boolean => {
  return isSettingDiscountEnabled ? (isDiscountEnabled ? true : false) : false;
};
//#endregion
//#region textBox
export const getTextBoxNumericOrDefaultValue = (e: any): number => {
  return e !== null &&
    e.target !== null &&
    e.target.value !== null &&
    e.target.value !== ""
    ? e.target.value
    : 0;
};
export const getTextBoxValue = (e: any): string | null => {
  return e !== null &&
    e.target !== null &&
    e.target.value !== null &&
    e.target.value !== ""
    ? e.target.value
    : null;
};
//#endregion
//#region transactions refund
export const isRefundTransaction = (
  transactionType: DailyTransactionTypeEnum
): boolean => {
  switch (transactionType) {
    case DailyTransactionTypeEnum.SalesRefund:
    case DailyTransactionTypeEnum.PurchasesRefund:
      return true;
    default:
      return false;
  }
};
export const clearTransactionDataForRefund = (
  request: TransactionDetailResponseModel
): TransactionDetailResponseModel => {
  if (request !== null) {
    request.Code = "";
    request.TransactionType = getRefundTransactionType(request.TransactionType);
    request.User_Create = getUserId();
    request.User_Update = getUserId();
    // request.Refrence = request.ID;
    request.rowState = RowStateEnum.Add;
    //request.ID = 0;
    if (
      request.TransactionDetaillist !== null &&
      request.TransactionDetaillist.length !== 0
    ) {
      request.TransactionDetaillist.forEach((row) => {
        row.rowKey = generateGuid();
        row.rowState = RowStateEnum.None;
        row.ID = 0;
      });
    }
  }
  return request;
};
export const handleRefundTransactionItemSelectStatus = (
  items: TransactionItemResponseModel[],
  rowKey: string | null,
  checked: boolean
): TransactionItemResponseModel[] => {
  if (items !== null && items.length !== 0) {
    return items.map((row) => {
      if (rowKey !== null && row.rowKey === rowKey) {
        return {
          ...row,
          rowState: checked ? RowStateEnum.Add : RowStateEnum.Update,
        };
      } else if (rowKey === null) {
        return {
          ...row,
          rowState: checked ? RowStateEnum.Add : RowStateEnum.Update,
        };
      }
      return row;
    });
  }
  return items;
};
//#endregion
//#region private
const isNumeric = (value: string) => {
  return /^-?\d+\.?\d*$/.test(value);
};
const handleSearchItemsOrFetchFromDb = async (
  itemList: LookupItemModel[],
  value: string,
  isArabic: boolean,
  isBarCode: boolean = false
): Promise<{ resultList: LookupItemModel[]; isFetchedFromDb: boolean }> => {
  let resultList: LookupItemModel[] = [];
  let isFetchedFromDb = false;
  value =
    value === null || value === undefined || value === ""
      ? value.toLowerCase()
      : value;
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value.length < 3
  ) {
    return { resultList, isFetchedFromDb };
  } else if (isBarCode) {
    resultList = itemList.filter(
      (row) => row.value !== null && row.otherValue.barCode.includes(value)
    );
  } else {
    resultList = itemList.filter((row) =>
      isArabic
        ? row.nameAr.includes(value.toLowerCase())
        : row.name.includes(value.toLowerCase())
    );
  }
  if (
    resultList === null ||
    resultList === undefined ||
    resultList.length === 0
  ) {
    resultList = await getLookupByType(LookupTypeEnum.Items, false, false, [
      {
        key: "searchItem.mixFilitrationNameAndCode",
        value: value,
      },
    ]);
    isFetchedFromDb = true;
  }
  return { resultList, isFetchedFromDb };
};
const getLookupItemListFromDictionary = (
  key: string,
  lookupList: LookupItemKeyValueModel[] | null
): LookupItemModel[] | null => {
  const list: LookupItemKeyValueModel | null =
    lookupList !== null && lookupList !== undefined && lookupList.length !== 0
      ? lookupList.filter((p) => p.key.toLowerCase().trim() === key)[0]
      : null;
  return list !== null &&
    list !== undefined &&
    list.value !== null &&
    list.value !== undefined &&
    list.value.length !== 0
    ? list.value
    : null;
};
const getLookupItemFirstDefaultValue = (
  key: string,
  lookupList: LookupItemKeyValueModel[] | null
): number | null => {
  const list: LookupItemKeyValueModel | null =
    lookupList !== null && lookupList !== undefined && lookupList.length !== 0
      ? lookupList.filter(
          (p) => p.key.toLowerCase().trim() === key.toLowerCase().trim()
        )[0]
      : null;
  return list !== null &&
    list !== undefined &&
    list.value !== null &&
    list.value !== undefined &&
    list.value.length !== 0
    ? Number(list.value.filter((p) => p.value !== null)[0].value)
    : null;
};
const getRefundTransactionType = (transactionType: number): number => {
  switch (transactionType) {
    case DailyTransactionTypeEnum.SalesInvoice:
    case DailyTransactionTypeEnum.POSSales:
    case DailyTransactionTypeEnum.POSSalesRefund:
    case DailyTransactionTypeEnum.POSRestaurant:
    case DailyTransactionTypeEnum.Reserve_Restaurant_Bill:
    case DailyTransactionTypeEnum.ReturnPOSRestaurant:
      transactionType = Number(DailyTransactionTypeEnum.SalesRefund);
      break;
    case DailyTransactionTypeEnum.PurchasesInvoice:
      transactionType = Number(DailyTransactionTypeEnum.PurchasesRefund);
      break;
  }

  return transactionType;
};
//#endregion
