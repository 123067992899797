import {useFormik} from "formik";
import * as Yup from "yup";
import {FC, useState, useEffect} from "react";
import {getLabelName} from "../../utils";
import {Col, Form, Row} from "react-bootstrap";
import {getTransactionSettings, saveTransactionSettings} from "../../serviceBroker/userApiServiceBroker";
import {ButtonBox, ErrorValidationBox, TextBox, LoadingBox, SelectBox} from "..";
import {
    ActionTypeEnum,
    LookupItemModel,
    LookupTypeEnum,
    RowStateEnum,
    UserTransactionSettingModel, ValidationErrorModel
} from "../../models";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";

export const TransactionSettings: FC<{
    userId: number;
    onActionEvent?: any | null;
}> = ({     userId,
          onActionEvent = () => {},
      }) => {
    //#region variables
    const initialValues: UserTransactionSettingModel = {
        CreatedBy: 0,
        CreationDate: new Date(),
        DeafultNote: "",
        DefaultCalcTypeID: undefined,
        DefaultPaymentTypeID: undefined,
        DirectPrintBillAfterSave: false,
        EnableButonAdd: false,
        EnableDiscount: false,
        EnableShangPrice: false,
        ID: 0,
        ModificationDate: new Date(),
        ModifiedBy: 0,
        Name: "",
        NumberOfCopy: 0,
        PeriodAllowForReturn: 0,
        RemarksVisable: "",
        ShowButtonPrintPrepairingOrder: false,
        ShowCalcType: false,
        ShowCinfirm: false,
        ShowCurrency: false,
        ShowCustomerCar: false,
        ShowDone: false,
        ShowEmployeeResponsibile: false,
        ShowNotefy: false,
        ShowPaymentType: false,
        ShowPrice: false,
        ShowRefrence: false,
        TransactionType_ID: null,
        User_ID: userId,
        VerifyOnUpdate: false,
        rowState: 0
    }
    //#endregion
    //#region state
    const [calcTypeList, setCalcTypeList] = useState<LookupItemModel[]>([]);
    const [transactionTypeList, setTransactionTypeList] = useState<LookupItemModel[]>([]);
    const [paymentTypeList, setPaymentTypeList] = useState<LookupItemModel[]>([]);
    const [defaultTransactionTypeId,setDefaultTransactionTypeId]=useState(0);
    const [userSetting, setUserSetting] = useState<UserTransactionSettingModel>(initialValues);
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>([]);
    const [validationSchema] = useState(Yup.object({}));
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            const [transactionTypes, calcTypes, paymentTypes] = await Promise.all([
                getLookupByType(LookupTypeEnum.TransactionType),
                getLookupByType(LookupTypeEnum.CalcType),
                getLookupByType(LookupTypeEnum.PaymentType)
            ]);
            setTransactionTypeList(transactionTypes);
            setDefaultTransactionTypeId(Number(transactionTypes[0].value));
            setCalcTypeList(calcTypes);
            setPaymentTypeList(paymentTypes);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, []);
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
           if(defaultTransactionTypeId!=0 && defaultTransactionTypeId!=  userSetting?.TransactionType_ID!) {
                await getUserTransactionSetting(
                     userId,
                     userSetting?.TransactionType_ID??transactionTypeList[0].value
                );
           }
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, [defaultTransactionTypeId]);
    //#endregion
    //#region function
    const getUserTransactionSetting = async (
        userId: any,
        transactionTypeId: any
    ) => {
        const userTransactionSetting = await getTransactionSettings(
            userId,
            transactionTypeId
        );
        const updatedValues = userTransactionSetting ?? initialValues;
        await formik.setValues(updatedValues);
        setUserSetting(updatedValues);
    };
    const handleReset = async () => {
        setValidationErrors([]);
        onActionEvent({
            action: ActionTypeEnum.CloseModelPopup,
        });
    };
    const handleSubmit = async (request: UserTransactionSettingModel) => {
        try {
            console.log('res_x_2',request)
            setLoading(true);
            request.rowState = RowStateEnum.Update;
            // request.TransactionType_ID = transactionTypeId;
            // request.DefaultCalcTypeID = defaultCalcTypeId;
            // request.DefaultPaymentTypeID = paymentTypeId;
            const res = await saveTransactionSettings(request);
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                onActionEvent({
                    id: ActionTypeEnum.TransactionSetting,
                    action: ActionTypeEnum.Failed,
                    request: res.Errors,
                    requestBeforeError: request,
                });
                setLoading(false);
            } else {
                setValidationErrors([]);
                onActionEvent({
                    id: ActionTypeEnum.TransactionSetting,
                    action: ActionTypeEnum.AddSuccess,
                    request: request,
                });
                setLoading(false);
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#en
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        //enableReinitialize: true,
        onSubmit: async (values) => {
            await handleSubmit(values);
            //resetForm();
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form onSubmit={formik.handleSubmit}>
                <div
                    className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
                    <SelectBox
                        id="transactionTypeSelectBox"
                        labelName={getLabelName("Trans Type")}
                        source={transactionTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.TransactionType_ID}
                        onStatusChange={async (e: any) => {
                            const _id = e?.value === null || e?.value === undefined ? null : e.value;
                            setUserSetting({...userSetting, TransactionType_ID: _id});
                            formik.values.TransactionType_ID = _id;
                            if (_id !== null) {
                                await getUserTransactionSetting(
                                    userId,
                                    _id
                                );
                            }
                        }}
                        selectedValues={userSetting.TransactionType_ID !== null &&userSetting.TransactionType_ID!==undefined ? [userSetting.TransactionType_ID.toString()] : null}
                        multiselectRef={undefined}
                        usePortalTarget={false}
                    />
                    <SelectBox
                        id="calcTypeSelectBox"
                        labelName={getLabelName("Calc Type")}
                        source={calcTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.DefaultCalcTypeID}
                        onStatusChange={(e: any) => {
                            const _id=e?.value===null||e?.value===undefined?null:e.value;
                            setUserSetting({...userSetting, DefaultCalcTypeID: _id});
                            formik.values.DefaultCalcTypeID =  _id;
                        }}
                        selectedValues={userSetting.DefaultCalcTypeID !== null  && userSetting.DefaultCalcTypeID!==undefined   ? [userSetting.DefaultCalcTypeID.toString()] : null}
                        multiselectRef={undefined}
                        usePortalTarget={false}
                    />
                    <SelectBox
                        id="paymentTypeSelectBox"
                        labelName={getLabelName("payment Type")}
                        source={paymentTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.DefaultPaymentTypeID}
                        onStatusChange={(e: any) => {
                            const _id=e?.value===null||e?.value===undefined?null:e.value;
                            setUserSetting({...userSetting, DefaultPaymentTypeID: _id});
                            formik.values.DefaultPaymentTypeID =  _id;
                        }}
                        selectedValues={userSetting.DefaultPaymentTypeID !== null  && userSetting.DefaultPaymentTypeID!==undefined    ? [userSetting.DefaultPaymentTypeID .toString()] : null}
                        multiselectRef={undefined}
                        usePortalTarget={false}
                    />
                    <TextBox
                        labelName={getLabelName("Period Allow to Return")}
                        inputName={"PeriodAllowForReturn"}
                        errorText={formik.errors.PeriodAllowForReturn}
                        inputValue={formik.values.PeriodAllowForReturn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                    <TextBox
                        labelName={getLabelName("Num. Copy")}
                        inputName={"NumberOfCopy"}
                        errorText={formik.errors.NumberOfCopy}
                        inputValue={formik.values.NumberOfCopy}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />

                </div>
                <Row className="mt-4">
                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowPaymentType"
                                    checked={formik.values.ShowPaymentType}
                                    name="ShowPaymentType"
                                    label={getLabelName("Payment Type")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowEmployeeResponsibile"
                                    name="ShowEmployeeResponsibile"
                                    checked={formik.values.ShowEmployeeResponsibile}
                                    label={getLabelName("Employee")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowCalcType"
                                    checked={formik.values.ShowCalcType}
                                    name="ShowCalcType"
                                    label={getLabelName("Calc Type")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowCurrency"
                                    name="ShowCurrency"
                                    checked={formik.values.ShowCurrency}
                                    label={getLabelName("Currency")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableShangPrice"
                                    name="EnableShangPrice"
                                    checked={formik.values.EnableShangPrice}
                                    label={getLabelName("Allow Change Price")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableDiscount"
                                    checked={formik.values.EnableDiscount}
                                    name="EnableDiscount"
                                    label={getLabelName("Allow Discount")}
                                />
                            </li>
                        </ul>
                    </Col>

                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="DirectPrintBillAfterSave"
                                    name="DirectPrintBillAfterSave"
                                    checked={formik.values.DirectPrintBillAfterSave}
                                    label={getLabelName("Direct Print After Reserve Bill")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableButonAdd"
                                    name="EnableButonAdd"
                                    checked={formik.values.EnableButonAdd}
                                    label={getLabelName("Enable Button Add")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowPrice"
                                    checked={formik.values.ShowPrice}
                                    name="ShowPrice"
                                    label={getLabelName("Show Price")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowRefrence"
                                    name="ShowRefrence"
                                    checked={formik.values.ShowRefrence}
                                    label={getLabelName("Show Reference")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowButtonPrintPrepairingOrder"
                                    name="ShowButtonPrintPrepairingOrder"
                                    checked={formik.values.ShowButtonPrintPrepairingOrder}
                                    label={getLabelName("Show Print Preparing order")}
                                />
                            </li>
                        </ul>
                    </Col>
                </Row>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                    <ButtonBox type="submit" size="sm" variant="primary" className="mx-3" iconType="content-save">
                        {getLabelName("save")}
                    </ButtonBox>
                    <ButtonBox
                    iconType="close-circle"
                        variant="danger"
                        size="sm"
                        type="button"
                        onClick={handleReset}
                    >
                        {getLabelName("close")}
                    </ButtonBox>
                </div>
            </form>
        </>
    );
    //#endregion
};
