import React, { FC, PropsWithChildren, ReactElement, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  GeneralPrintResponseModel,
  HasFormIdModel,
  ItemReportSearchParamsModel,
  ReportUserAcTransactionLogsParamsModel,
  ReportVatSearchParamsModel,
  ResponseBaseModel,
  SearchPanelPropsModel,
  TreasuryReportSearchParamsModel,
} from "../../../models";
import { getLabelName, isArabicCurrentLanguage } from "../../../utils";
import { LoadingBox, ReportViewer } from "../..";

interface ReportPageProps<T> extends HasFormIdModel {
  InitialState: T;
  SearchApi: (
    request: T
  ) => Promise<ResponseBaseModel<GeneralPrintResponseModel>>;
  searchHeader: string;
  SearchPanel: FC<SearchPanelPropsModel<T>>;
  withDateRange?: boolean;
  reportType?: boolean;
  withUser?: boolean;
}

export const ReportPage = <
  T extends
    | ItemReportSearchParamsModel
    | TreasuryReportSearchParamsModel
    | ReportVatSearchParamsModel
    | ReportUserAcTransactionLogsParamsModel
>(
  props: PropsWithChildren<ReportPageProps<T>>
): ReactElement => {
  //#region state
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState("");
  const [searchParams, setSearchParams] = useState<T>(props.InitialState);
  //#endregion
  //#region functions
  const handelSearch = async () => {
    setLoading(true);
    const response = await props.SearchApi(searchParams);
    if (
      response !== undefined &&
      response.Result !== null &&
      response.Result !== undefined
    ) {
      setContent(response.Result.BillFilePdf);
      if (response.Errors != undefined && response.Errors.length > 0) {
        setErrors(
          isArabicCurrentLanguage()
            ? response.Errors.map((e) => e.MessageAr).join(",")
            : response.Errors.map((e) => e.MessageEn).join(",")
        );
      }
    }
    setLoading(false);
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName(props?.searchHeader)}
          </Accordion.Header>
          <Accordion.Body>
            <props.SearchPanel
              searchHeader={props.searchHeader}
              handelSearch={handelSearch}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setLoading={setLoading}
              withDateRange={props.withDateRange}
              reportType={props.reportType}
              withUser={props.withUser}
              formId={props.formId}
            />{" "}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Report Viewer")}</Accordion.Header>
          <Accordion.Body>
            <ReportViewer content={content} errors={errors} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
