import { FC, useEffect } from "react";
import {toast, ToastContainer, ToastOptions} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLabelName, isArabicCurrentLanguage } from "../../../../utils";
import { ToastVariantType } from "../../../../models";

const defaultDelayDuration:number=3000;
export const ToastBoxV2: FC<{
    isShown: boolean;
    header?: string | null;
    body?: string | null;
    variant?: ToastVariantType | null;
    delayDuration?: number | null;
    onCloseEvent?: any;
}> = ({
          isShown = false,
          header,
          body,
          variant = "success",
          delayDuration = defaultDelayDuration,
          onCloseEvent,
      }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const _variant: ToastVariantType = variant || "success";
    const _delayDuration: number = delayDuration || defaultDelayDuration;
    const customColors: Record<ToastVariantType, string> = {
        primary: "rgba(0, 123, 255, 0.8)", // Blue with 80% opacity
        secondary: "rgba(108, 117, 125, 0.8)", // Grey with 80% opacity
        light: "rgba(248, 249, 250, 0.8)", // Light grey with 80% opacity
        dark: "rgba(52, 58, 64, 0.8)", // Dark grey with 80% opacity
        success: "rgba(40, 167, 69, 0.8)", // Green with 80% opacity
        danger: "rgba(220, 53, 69, 0.8)", // Red with 80% opacity
        warning: "rgba(255, 193, 7, 0.8)", // Yellow with 80% opacity
        info: "rgba(23, 162, 184, 0.8)", // Cyan with 80% opacity
    };
    //#endregion
    //#region functions
    const toastMethodMap: Record<
        ToastVariantType,
        (content: string | JSX.Element, options?: ToastOptions) => void
    > = {
        success: toast.success,
        danger: toast.error,
        warning: toast.warning,
        info: toast.info,
        primary: toast, // Generic toast for unsupported variants
        secondary: toast,
        light: toast,
        dark: toast,
    };
        const showToast = () => {
        const _body: string =
            body && body.trim()
                ? body
                : variant === "success"
                    ? getLabelName("Operation Done Success")
                    : variant === "danger"
                        ? getLabelName("Operation Failed")
                        : "Notification";

        const toastContent = (
            <div>
                {header && <strong>{header}</strong>}
                <p>{_body}</p>
            </div>
        );

        // Dynamically call the toast method for supported variants or use generic toast for others
        const toastMethod = toastMethodMap[_variant!] || toast;
        toastMethod(toastContent, {
            position: isArabic ? "top-right" : "top-left",
            autoClose: _delayDuration,
            onClose: onCloseEvent,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: variant === "light" ? "light" : "colored", // Use light theme for "light" variant
            style: {
                backgroundColor: customColors[variant!] || "#007bff", // Fallback to primary if variant is undefined
                color: variant === "light" ? "#000" : "#fff", // Light text for "dark" backgrounds and vice versa
            },
        });
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        if (isShown) {
            showToast();
        }
    }, [isShown]);  //, header, body, variant, delayDuration, onCloseEvent, isArabic
    //#endregion
    //#region html
    return <ToastContainer/>;
    //#endregion
};
